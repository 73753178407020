import React from 'react';
import styled, { css } from 'styled-components';

import { SearchIcon } from 'assets/icons/SearchIcon';

interface IProps {
  placeholder: string;
  // value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterDown?: (value: string) => void;
}

export const SearchInput = ({ placeholder, onChange, onEnterDown }: IProps) => {
  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const target = e.target as HTMLTextAreaElement;
      onEnterDown ? onEnterDown(target.value) : null;
    }
  };

  return (
    <Container>
      <Search type="search" placeholder={placeholder} onChange={onChange} onKeyPress={onKeyPressHandler} />
      <SearchIcon
        style={{
          color: '#000000',
          position: 'absolute',
          top: '8px',
          left: '16px',
          pointerEvents: 'none',
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Search = styled.input`
  ${({ theme: { baseInputTextColor, baseInputBorderColor, baseInputBgColor, baseInputPlaceholderColor } }) => css`
    width: 100%;
    padding: 8px 16px 8px 56px;
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${baseInputTextColor};
    border: 1px solid ${baseInputBorderColor};
    border-radius: 8px;
    background-color: ${baseInputBgColor};

    &::placeholder {
      color: ${baseInputPlaceholderColor};
    }

    &:focus {
      outline: none;
    }
  `}
`;
