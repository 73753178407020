import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import { Button, PasswordInput, TextInput } from 'atoms';
import { FormSection } from 'molecules';

import { validationSchema as validate } from './validationSchema';

interface IValues {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}
interface IProps {
  username: string;
  authServerError: string;
  onChangeDecorator: () => void;
  onSubmit: (values: IValues) => void;
}

export const UserSettingsForm = ({ onSubmit, username, authServerError, onChangeDecorator }: IProps) => {
  return (
    <Formik
      initialValues={{ currentPassword: '', newPassword: '', repeatPassword: '' }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <FormSection title="Info">
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12} xl={8}>
                <UserNameInput id="userName" name="userName" label="User name" value={username} disabled />
              </Col>
            </Row>
          </FormSection>

          <FormSection title="Password">
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12} xl={8}>
                <CurrentPasswordInput
                  id="currentPassword"
                  name="currentPassword"
                  label="Current Password"
                  value={values.currentPassword}
                  onChange={(e) => {
                    onChangeDecorator();
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.currentPassword && touched.currentPassword ? errors.currentPassword : authServerError || ''
                  }
                />
              </Col>
            </Row>

            <Row gutter={[32, 0]}>
              <Col xs={24} md={12} xl={8}>
                <NewPasswordInput
                  id="newPassword"
                  name="newPassword"
                  label="New Password"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                />
              </Col>
            </Row>

            <Row gutter={[32, 0]}>
              <Col xs={24} md={12} xl={8}>
                <RepeatPasswordInput
                  id="repeatPassword"
                  name="repeatPassword"
                  label="Repeat New Password"
                  value={values.repeatPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.repeatPassword && touched.repeatPassword ? errors.repeatPassword : ''}
                />
              </Col>
            </Row>
          </FormSection>

          <ApplySection>
            <SaveButton
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </SaveButton>
            <Link to="/dashboard">
              <CancelButton type="default">Cancel</CancelButton>
            </Link>
          </ApplySection>
        </Form>
      )}
    </Formik>
  );
};

const Form = styled.form`
  margin-top: 32px;
  background-color: ${({ theme }) => theme.settingsFormBgColor};
`;

const UserNameInput = styled(TextInput)`
  margin-bottom: 8px;
  max-width: 384px;
`;

const CurrentPasswordInput = styled(PasswordInput)`
  margin-bottom: 8px;
  max-width: 384px;
`;

const NewPasswordInput = styled(PasswordInput)`
  margin-bottom: 8px;
  max-width: 384px;
`;

const RepeatPasswordInput = styled(PasswordInput)`
  margin-bottom: 8px;
  max-width: 384px;
`;

const ApplySection = styled.div`
  display: flex;
  padding: 32px 32px 48px 32px;
`;

const SaveButton = styled(Button)`
  margin-right: 16px;
  width: 112px;
`;

const CancelButton = styled(Button)`
  width: 124px;
`;
