import qs from 'querystring';

import { BaseService } from './BaseService';
import { checkTokenInterceptor } from './axiosInterceptors';

checkTokenInterceptor();

interface IConfirmNewPasswordPayload {
  uid: string;
  token: string;
  newPassword: string;
  repeatPassword: string;
}
class AuthAPIService extends BaseService {
  public async refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');

    const data = {
      refresh: refreshToken,
    };

    const {
      data: {
        data: { access: access_token },
      },
    } = await this.post('auth/refresh', qs.stringify(data), { 'Content-Type': 'application/x-www-form-urlencoded' });

    localStorage.setItem('access_token', access_token);

    return access_token;
  }

  public async refreshTokens() {
    const refreshToken = localStorage.getItem('refresh_token');

    const body = {
      refresh: refreshToken,
    };

    const {
      data: {
        data: { access: access_token, refresh: refresh_token },
      },
    } = await this.post('auth/refresh', qs.stringify(body), {
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);

    return access_token;
  }

  public async logIn(email: string, password: string) {
    return this.post('auth/login', { email, password });
  }

  public async resetPassword(email: string) {
    return this.post('auth/reset-password', { email });
  }

  public async confirmNewPassword({ uid, token, newPassword, repeatPassword }: IConfirmNewPasswordPayload) {
    return this.post('auth/reset-password-confirm', {
      new_password: newPassword,
      new_password_confirm: repeatPassword,
      uid,
      token,
    });
  }
}

export const AuthService = new AuthAPIService();
