import { BaseService } from './BaseService';
import { checkTokenInterceptor } from './axiosInterceptors';

checkTokenInterceptor();

class ReceiverAPIService extends BaseService {
  public async getReceiverStatus() {
    const route = ``;

    return this.get(route);
  }

  public async toggleReceiver() {
    const route = ``;

    return this.post(route, null);
  }
}

export const receiverService = new ReceiverAPIService();
