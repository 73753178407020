import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import styled, { css } from 'styled-components';

interface IProps {
  total?: number;
  pageSize?: number;
  current?: number;
  onChange?(page: number, pageSize?: number): void;
}

export const Pagination = ({ total = 0, pageSize = 8, current = 1, onChange }: IProps) => {
  return (
    <PaginationContainer>
      <NextPreviousPart size="small" total={total} pageSize={pageSize} current={current} onChange={onChange} />
      <PageNumbersPart size="small" total={total} pageSize={pageSize} current={current} onChange={onChange} />
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  & {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 32px;
  }
`;

const NextPreviousPart = styled(AntdPagination)`
  ${({
    theme: { paginationBtnBgColor, paginationBtnTextColor, paginationBtnHoverTextColor, paginationBtnHoverBgColor },
  }) => css`
    && {
      display: inline-block;

      & .ant-pagination-next,
      & .ant-pagination-prev {
        .ant-pagination-item-link {
          background-color: ${paginationBtnBgColor};
          color: ${paginationBtnTextColor};
          border-radius: 4px;
          font-size: 16px;
          width: 32px;
          height: 32px;
          margin: 0 8px 0 0;

          &:hover {
            color: ${paginationBtnHoverTextColor};
            background-color: ${paginationBtnHoverBgColor};
          }
        }
      }

      & .ant-pagination-item {
        display: none;
      }
    }
  `}
`;

const PageNumbersPart = styled(AntdPagination)`
  ${({ theme: { paginationBtnActiveTextColor, paginationBtnActiveBgColor } }) => css`
    & {
      display: inline-block;

      & .ant-pagination-item.ant-pagination-item-active {
        background-color: ${paginationBtnActiveBgColor};
        border-radius: 4px;
        border: none;
        font-size: 16px;
        width: 32px;
        height: 32px;
        margin: 0 8px 0 0;

        a {
          color: ${paginationBtnActiveTextColor};
          line-height: 32px;

          &:hover {
            color: #ffffff;
          }
        }
      }

      & .ant-pagination-item {
        transition: none;

        a:hover {
          color: #000000;
        }
      }
    }
    && .ant-pagination-next {
      display: none;
    }
    && .ant-pagination-prev {
      display: none;
    }
  `}
`;
