const baseColors = {
  primaryColor: '#ff7c29',
  primaryAltColor: '#fff2ea',
  secondaryColor: '#363740',
  tertiaryColor: '#8D9091',

  primaryTextColor: '#252733',
  secondaryTextColor: '#ffffff',

  errorTextColor: '#c5292a',

  primaryInputColor: '#efefef',
  secondaryInputColor: '#cccccc',

  primaryDisabledColor: '#efefef',
  secondaryDisabledColor: '#cccccc',
  primaryDisabledTextColor: '#b3b3b3',
};

export const orangeGrayTheme = {
  primaryBtnBgColor: baseColors.primaryColor,
  primaryBtnTextColor: baseColors.secondaryTextColor,
  primaryBtnHoverBgColor: '#ff9049',
  primaryBtnActiveBgColor: '#d96a23',
  primaryBtnDisabledBgColor: '#fdbc92',
  primaryBtnDisabledTextColor: baseColors.secondaryTextColor,

  defaultBtnBgColor: baseColors.primaryAltColor,
  defaultBtnTextColor: baseColors.primaryColor,
  defaultBtnHoverBgColor: '#ffe5d4',
  defaultBtnActiveBgColor: '#ffdec9',
  defaultBtnDisabledBgColor: '#fff9f5',
  defaultBtnDisabledTextColor: baseColors.primaryColor,

  ghostBtnBgColor: '#E1E2E4',
  ghostBtnTextColor: baseColors.primaryTextColor,
  ghostBtnHoverBgColor: '#efeff0',
  ghostBtnActiveBgColor: '#bfc0c2',
  ghostBtnDisabledBgColor: '#eeeff0',
  ghostBtnDisabledTextColor: '#909197',

  baseInputBorderColor: baseColors.primaryInputColor,
  baseInputErrorColor: baseColors.errorTextColor,
  baseInputLabelBgColor: baseColors.secondaryTextColor,
  baseInputLabelTextColor: baseColors.tertiaryColor,
  baseInputHoverColor: baseColors.secondaryInputColor,
  baseInputTextColor: baseColors.primaryTextColor,
  baseInputActiveColor: baseColors.primaryColor,
  baseInputDisabledColor: baseColors.primaryDisabledColor,
  baseInputAdditionalTextColor: baseColors.tertiaryColor,
  baseInputBgColor: '#ffffff',
  baseInputPlaceholderColor: baseColors.tertiaryColor,

  switchBgColor: baseColors.primaryInputColor,
  switchHeadBgColor: baseColors.secondaryInputColor,
  switchActiveBgColor: baseColors.primaryAltColor,
  switchActiveHeadBgColor: baseColors.primaryColor,
  switchBorderColor: baseColors.secondaryInputColor,
  switchActiveBorderColor: baseColors.primaryColor,

  radioGroupItemBorderColor: baseColors.secondaryInputColor,
  radioGroupItemActiveBorderColor: baseColors.primaryColor,
  radioGroupItemActiveBgColor: baseColors.primaryColor,
  radioGroupItemTitleColor: baseColors.primaryTextColor,

  selectDefaultBorderColor: baseColors.primaryInputColor,
  selectHoverBorderColor: baseColors.secondaryInputColor,
  selectTextColor: baseColors.primaryTextColor,
  selectActiveBorderColor: baseColors.primaryColor,
  selectLabelBgColor: baseColors.secondaryTextColor,
  selectLabelTextColor: baseColors.primaryTextColor,
  selectDisabledBorderColor: baseColors.primaryDisabledColor,
  selectDisabledTextColor: baseColors.primaryDisabledTextColor,
  optionHoverTextColor: baseColors.primaryColor,
  optionActiveTextColor: baseColors.primaryColor,
  optionTextColor: baseColors.primaryTextColor,
  optionActiveBgColor: '#FFF2EA',
  optionBgColor: baseColors.secondaryTextColor,

  settingsDropDownButtonBgColor: '#6c6e75',
  filterDropDownBgColor: '#E1E2E4',
  filterDropDownTextColor: baseColors.primaryTextColor,

  formTitleTextColor: baseColors.primaryTextColor,

  formSectionBorder: '#F1F3FA',

  settingsFormBgColor: '#FFFFFF',

  appHeaderBgColor: '#05060b',
  appHeaderTextColor: baseColors.secondaryTextColor,

  appMenuBgColor: baseColors.secondaryColor,
  appMenuTextColor: '#60626e',
  appMenuActiveTextColor: baseColors.primaryColor,

  pageTitleTextColor: baseColors.primaryTextColor,

  userAvatarBgColor: '#05060b',
  userAvatarTextColor: baseColors.secondaryTextColor,

  pageLayoutBgColor: '#f5f6fa',

  loginPageBgColor: '#363740',
  loginFormBgColor: '#3e4049',
  loginHeadingTextColor: '#ffffff',
  loginSubHeadingTextColor: '#7e8799',
  loginGoBackButtonColor: '#ffffff',
  loginInputTextColor: '#ffffff',
  loginInputErrorColor: '#FF4D4E',

  streamItemBgColor: '#ffffff',
  streamItemPreviewBgColor: baseColors.secondaryColor,
  streamItemFooterBgColor: '#ffffff',
  streamItemVideoIconColor: baseColors.secondaryTextColor,
  streamItemNameColor: baseColors.primaryTextColor,

  streamCanvasBgColor: '#2a2b39',
  streamCanvasVideoBlockBgColor: 'rgba(255, 255, 255, 0.16)',
  streamCanvasVideoBlockHeaderBgColor: '#000000',
  streamCanvasVideoBlockHeaderTextColor: baseColors.secondaryTextColor,

  streamCanvasMenuBgColor: baseColors.secondaryColor,
  streamCanvasMenuColor: baseColors.secondaryTextColor,
  streamCanvasSubMenuColor: baseColors.primaryTextColor,
  streamCanvasMenuHoverColor: baseColors.primaryColor,

  uploadBoxTextColor: baseColors.primaryTextColor,
  uploadBoxPreviewAreaBorderColor: '#ffffff',

  statusSuccessTextColor: '#22b02e',
  statusSuccessBgColor: 'rgba(34, 176, 46, 0.24)',
  statusErrorTextColor: '#f44336',
  statusErrorBgColor: 'rgba(244, 67, 54, 0.24)',
  statusWarningTextColor: '#f5cc07',
  statusWarningBgColor: 'rgba(255, 193, 7, 0.24)',

  paginationBtnBgColor: baseColors.primaryAltColor,
  paginationBtnTextColor: baseColors.primaryColor,
  paginationBtnHoverBgColor: baseColors.primaryColor,
  paginationBtnHoverTextColor: '#ffffff',
  paginationBtnActiveBgColor: baseColors.primaryColor,
  paginationBtnActiveTextColor: '#ffffff',
};
