import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ArrowDownIcon, CheckedIcon } from 'assets';
import { TStreamStatus } from 'types';

import { StreamStatus } from '../../../constants/statuses';

interface IProps {
  buttonName: string;
  onFilterButtonBlur?(): void;
  isActiveFilter?: boolean | null;
  statusFilter?: TStreamStatus | null;
  changeIsActiveFilterhandler?(value: boolean | null): void;
  changeStatusFilterhandler?(value: TStreamStatus | null): void;
}

export const FilterDropDown = ({
  buttonName,
  onFilterButtonBlur,
  isActiveFilter,
  statusFilter,
  changeIsActiveFilterhandler,
  changeStatusFilterhandler,
}: IProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const onFilterButtonClick = () => setIsOpened(!isOpened);

  const onBlur = () => {
    setIsOpened(false);

    if (onFilterButtonBlur) {
      onFilterButtonBlur();
    }
  };

  const filterIsActiveChangeHandler = (value: boolean | null) => {
    changeIsActiveFilterhandler?.(value);
    setIsOpened(false);
  };

  const filterStatusChangeHandler = (value: TStreamStatus | null) => {
    changeStatusFilterhandler?.(value);
    setIsOpened(false);
  };

  return (
    <Container tabIndex={0} onBlur={onBlur}>
      <FilterDropDownButtonContainer onClick={onFilterButtonClick}>
        <FilterDropDownText>{buttonName}</FilterDropDownText>
        <ArrowDownIcon rotate={isOpened ? 180 : 0} />
      </FilterDropDownButtonContainer>
      {isOpened && (
        <FilterOptionsContainer>
          <FilterOptionWrapper key={1}>
            <FilterOption onClick={() => filterIsActiveChangeHandler(true)} active={!!isActiveFilter}>
              On
            </FilterOption>
            {!!isActiveFilter && (
              <CheckedIconContainer>
                <CheckedIcon />
              </CheckedIconContainer>
            )}
          </FilterOptionWrapper>

          <FilterOptionWrapper key={2}>
            <FilterOption onClick={() => filterIsActiveChangeHandler(false)} active={isActiveFilter === false}>
              Off
            </FilterOption>
            {isActiveFilter === false && (
              <CheckedIconContainer>
                <CheckedIcon />
              </CheckedIconContainer>
            )}
          </FilterOptionWrapper>

          <FilterOptionWrapper key={3}>
            <FilterOption
              onClick={() => filterStatusChangeHandler(StreamStatus.Ready)}
              active={statusFilter === StreamStatus.Ready}
            >
              Ready
            </FilterOption>
            {statusFilter === StreamStatus.Ready && (
              <CheckedIconContainer>
                <CheckedIcon />
              </CheckedIconContainer>
            )}
          </FilterOptionWrapper>

          <FilterOptionWrapper key={4}>
            <FilterOption
              onClick={() => filterStatusChangeHandler(StreamStatus.NotReady)}
              active={statusFilter === StreamStatus.NotReady}
            >
              Not ready
            </FilterOption>
            {statusFilter === StreamStatus.NotReady && (
              <CheckedIconContainer>
                <CheckedIcon />
              </CheckedIconContainer>
            )}
          </FilterOptionWrapper>
        </FilterOptionsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
   {
    display: inline-block;
    position: relative;
    outline: none;
  }
`;

const FilterDropDownButtonContainer = styled.div`
  width: fit-content;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.filterDropDownTextColor};
  background-color: ${({ theme }) => theme.filterDropDownBgColor};
  border-radius: 8px;
  padding: 0 12px 0 16px;
  cursor: pointer;
  user-select: none;
  outline: none;
`;

const FilterDropDownText = styled.div`
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  margin-right: 11px;
`;

const FilterOptionsContainer = styled.div`
  ${({ theme: { optionBgColor } }) => css`
    position: absolute;
    left: 0;
    top: 48px;
    text-align: left;
    width: 184px;
    background-color: ${optionBgColor};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 8px 0;
    z-index: 10;
  `}
`;

interface IFilterOptionStyles {
  active: boolean;
}

const FilterOptionWrapper = styled.div`
  position: relative;
`;

const FilterOption = styled.div<IFilterOptionStyles>`
  ${({
    theme: { optionActiveTextColor, optionTextColor, optionBgColor, optionHoverTextColor, optionActiveBgColor },
    active,
  }) => css`
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${active ? optionActiveTextColor : optionTextColor};
    padding: 8px 16px;
    background-color: ${optionBgColor};
    cursor: pointer;

    &:hover {
      color: ${optionHoverTextColor};
      background-color: ${optionActiveBgColor};
    }

    &:first-of-type {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  `}
`;

const CheckedIconContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  pointer-events: none;
`;
