import React from 'react';
import styled from 'styled-components';

import { SuccessModal, PageLayout } from 'organisms';

export const PasswordUpdated = () => {
  return (
    <PageLayout isHeaderVisible isMenuVisible={false} isAuthPage>
      <LoginFormContainer>
        <SuccessModal heading="Password successfully updated" linkTo="/dashboard" />
      </LoginFormContainer>
    </PageLayout>
  );
};

const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.loginPageBgColor};
`;
