import axios from 'axios';

import { logOutAction, store } from 'core';

import { AuthService } from './AuthService';

export const checkTokenInterceptor = () => {
  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      if (!error.response) return Promise.reject(error);

      const { status, config } = error.response;

      const errorMessage = '';

      if (status === 401 && errorMessage.includes('Auth is failed')) {
        if (errorMessage.includes('Token is invalid or expired')) {
          store.dispatch(logOutAction());
        } else {
          return AuthService.refreshToken().then((token) => {
            config.headers.Authorization = `Bearer ${token}`;
            return axios.request(config);
          });
        }
      }

      return Promise.reject(error);
    },
  );
};
