import React, { memo, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getIsLoggedIn, getIsAuthLoading } from 'core/selectors';
import { ScreenLoader } from 'organisms';
import { initAppAction } from 'core';

import { Dashboard } from '../Dashboard';
import { Settings } from '../Settings';
import { Login } from '../Login';
import { ForgotPassword } from '../ForgotPassword';
import { CheckEmail } from '../CheckEmail';
import { NewPasswordConfirm } from '../NewPasswordConfirm';
import { PasswordUpdated } from '../PasswordUpdated';
import { ResetPasswordSuccess } from '../ResetPasswordSuccess';
import { CreateEditStream } from '../CreateEditStream';

export const Router = memo(() => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isAuthLoading = useSelector(getIsAuthLoading);

  useEffect(() => {
    dispatch(initAppAction());
  }, [dispatch]);

  const MainRouter = () => {
    return (
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/password-updated" component={PasswordUpdated} />
        <Route exact path="/edit-stream/:id" component={CreateEditStream} />
        <Route exact path="/create-stream" component={CreateEditStream} />
        <Redirect to="/dashboard" />
      </Switch>
    );
  };

  const AuthRouter = () => {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/auth/reset-password-confirm/:uid/:token" component={NewPasswordConfirm} />
        <Route exact path="/check-email" component={CheckEmail} />
        <Route exact path="/reset-password-success" component={ResetPasswordSuccess} />
        <Redirect to="/login" />
      </Switch>
    );
  };

  if (isAuthLoading) return <ScreenLoader />;

  return (
    <BrowserRouter>
      <Route
        render={() => {
          return isLoggedIn ? <MainRouter /> : <AuthRouter />;
        }}
      />
    </BrowserRouter>
  );
});
