import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { Link } from 'react-router-dom';

import { EyeIcon } from 'assets';
import { InputSubText } from 'atoms';

interface IProps {
  type?: 'password' | 'email';
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  isForgotPassLinkVisible?: boolean;
  error?: string;
  serverError?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export const LoginInput = ({
  label,
  id,
  name,
  placeholder,
  type = 'password',
  isForgotPassLinkVisible = true,
  onChange,
  onBlur,
  value,
  error = '',
  serverError = '',
  ...props
}: IProps) => {
  const [openedPassword, setOpenedPassword] = useState(false);

  const onClickIcon = () => setOpenedPassword(!openedPassword);

  return (
    <Container {...props}>
      <LabelRow>
        <Label error={Boolean(error)}>{label}</Label>
        {type === 'password' && isForgotPassLinkVisible && (
          <ForgotPasswordLink to="/forgot-password">Forgot password?</ForgotPasswordLink>
        )}
      </LabelRow>
      {type === 'password' ? (
        <>
          <PasswordInputContainer>
            {/* TODO: Refactor password icon  */}
            <LoginInputComponent
              placeholder={placeholder}
              id={id}
              name={name}
              type={openedPassword ? 'text' : 'password'}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              spellCheck={false}
              error={Boolean(error)}
            />
            <EyeIconContainer onClick={onClickIcon}>
              <EyeIcon style={{ color: openedPassword ? '#ff7c29' : '#7E8799', width: '8px' }} />
            </EyeIconContainer>
          </PasswordInputContainer>
          <InputSubText text={error || serverError} error type="login" alignment={serverError ? 'center' : 'start'} />
        </>
      ) : (
        <>
          <EmailInputContainer>
            <LoginInputComponent
              placeholder={placeholder}
              id={id}
              name={name}
              type="text"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              spellCheck={false}
              error={Boolean(error)}
            />
          </EmailInputContainer>
          <InputSubText text={error || serverError} error type="login" alignment={serverError ? 'center' : 'start'} />
        </>
      )}
    </Container>
  );
};

interface IInputProps {
  error?: boolean;
}

const Container = styled.div`
  font-family: 'Avenir Next', sans-serif;
`;

const LabelRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span<IInputProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme, error }) => (error ? theme.loginInputErrorColor : theme.loginInputTextColor)};
`;

const LoginInputComponent = styled(Input)<IInputProps>`
  ${({
    theme: { loginFormBgColor, loginInputTextColor, loginSubHeadingTextColor, loginInputErrorColor },
    error,
  }) => css`
    &.ant-input {
      background-color: ${loginFormBgColor};
      border: 1px solid ${error ? loginInputErrorColor : loginInputTextColor};
      border-radius: 4px;
      font-family: 'Avenir Next', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: ${loginInputTextColor};
      padding: 16px;

      &::placeholder {
        color: ${loginSubHeadingTextColor};
      }
    }
  `}
`;

const PasswordInputContainer = styled.div`
  position: relative;
  margin-bottom: 4px;
`;

const EmailInputContainer = styled.div`
  margin-bottom: 4px;
`;

const ForgotPasswordLink = styled(Link)`
  ${({ theme: { loginSubHeadingTextColor } }) => css`
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    height: 20px;
    color: ${loginSubHeadingTextColor};

    &:hover {
      color: ${loginSubHeadingTextColor};
    }

    &:focus {
      outline: 0.5px dashed #fff;
    }

    &:active {
      outline: none;
    }
  `}
`;

const EyeIconContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 14px;
  right: 24px;
  cursor: pointer;
`;
