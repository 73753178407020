export const select = [
  {
    id: '1',
    value: '16:9',
    caption: '16:9',
  },
  {
    id: '2',
    value: '4:3',
    caption: '4:3',
  },
];

export const canvasFormats = [
  {
    id: '1',
    value: '16:9',
    caption: '16:9',
  },
  {
    id: '2',
    value: '4:3',
    caption: '4:3',
  },
  {
    id: '3',
    value: '1:1',
    caption: '1:1',
  },
];
