import React from 'react';
import Icon from '@ant-design/icons';

const DashboardSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9336 16.0725L19.36 10.9565L19.5181 10.8312C20.1676 10.317 20.2772 9.37356 19.7629 8.72409C19.6917 8.63417 19.6104 8.5527 19.5206 8.4813L12.9337 3.24258C12.3871 2.80789 11.6129 2.80789 11.0663 3.24258L4.47482 8.48489C3.82645 9.00055 3.71886 9.94419 4.23452 10.5926C4.305 10.6812 4.38527 10.7615 4.47382 10.8321L4.62999 10.9565L11.0659 16.0731C11.6127 16.5078 12.3871 16.5075 12.9336 16.0725Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0564 18.6707L5.33594 14.1229C4.94555 13.8126 4.37748 13.8774 4.06711 14.2678C4.06473 14.2708 4.06236 14.2738 4.06001 14.2769C3.75258 14.6739 3.82518 15.2449 4.22216 15.5523C4.2236 15.5534 4.22504 15.5545 4.22649 15.5557L11.0873 20.8031C11.6251 21.2145 12.3718 21.2145 12.9096 20.8033L19.7678 15.5598C20.1693 15.2528 20.246 14.6784 19.939 14.2769C19.9377 14.2752 19.9363 14.2735 19.935 14.2718C19.6267 13.8744 19.0546 13.8022 18.6572 14.1105C18.6544 14.1127 18.6515 14.115 18.6486 14.1173L12.9235 18.6705C12.377 19.1052 11.6029 19.1052 11.0564 18.6707Z"
      fill="currentColor"
    />
  </svg>
);

export const DashboardIcon = () => <Icon component={DashboardSvg} />;
