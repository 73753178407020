import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { PageTitle, Button } from 'atoms';

interface IProps {
  isShowBackBtn?: boolean;
  changeFormHandler?(): void;
  children: ReactNode;
}

export const Header = ({ isShowBackBtn = false, changeFormHandler, children }: IProps) => {
  const backBtnHandler = () => {
    if (changeFormHandler) {
      changeFormHandler();
    }
  };

  return (
    <Row gutter={[0, 32]}>
      <Col flex="auto">
        <PageTitle>{children}</PageTitle>
      </Col>
      {isShowBackBtn ? (
        <Col flex="none">
          <Button onClick={backBtnHandler} icon={<LeftOutlined />} type="ghost" size="small">
            Back to settings stream
          </Button>
        </Col>
      ) : null}
    </Row>
  );
};
