import { createAction } from 'typesafe-actions';

import { ACTIONS } from './constants';

interface IUser {
  id: number;
  email: string;
  username: string;
}

interface IError {
  authServerError: string;
}

export const fetchUserRequestAction = createAction(ACTIONS.FETCH_USER_REQUEST)();
export const fetchUserSuccessAction = createAction(ACTIONS.FETCH_USER_SUCCESS)<IUser>();
export const fetchUserFailureAction = createAction(ACTIONS.FETCH_USER_FAILURE)();

interface IUpdateUserPassswordPassword {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export const updateUserPasswordRequestAction = createAction(ACTIONS.UPDATE_USER_PASSWORD_REQUEST)<
  IUpdateUserPassswordPassword
>();
export const updateUserPasswordSuccessAction = createAction(ACTIONS.UPDATE_USER_PASSWORD_SUCCESS)();
export const updateUserPasswordFailureAction = createAction(ACTIONS.UPDATE_USER_PASSWORD_FAILURE)<IError>();
