import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Tooltip, Modal } from 'antd';

import { StreamStatus } from 'constants/statuses';
import { StreamSettings, Switch, TextInput, StatusLabel } from 'atoms';
import { ConfirmationModal } from 'molecules';
import { StreamIcon } from 'assets';
import { TStreamStatus } from 'types';
import { streamIsNotProcessing } from 'helpers';

interface IProps {
  id?: string;
  name?: string;
  outputIp?: string;
  deleteHandler?(): void;
  rebootHandler?(): void;
  streamStatus?: TStreamStatus;
  changeStreamActiveHandler?(value: boolean): void;
  isActive?: boolean;
}

export const StreamItem = ({
  id,
  name = '',
  outputIp = '',
  deleteHandler,
  rebootHandler,
  streamStatus = StreamStatus.NotReady,
  changeStreamActiveHandler,
  isActive,
}: IProps) => {
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [getIsActiveSwitchLoading, setIsActiveSwitchLoading] = useState(false);

  useEffect(() => {
    setIsActiveSwitchLoading(false);
  }, [isActive]);

  const statusSwitchChangeHandler = (value: boolean) => {
    setIsActiveSwitchLoading(!getIsActiveSwitchLoading);

    if (changeStreamActiveHandler) {
      changeStreamActiveHandler(value);
    }
  };

  const showShareModal = () => {
    setIsShareModalVisible(true);
  };

  const hideShareModal = () => {
    setIsShareModalVisible(false);
  };

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const deleteConfirmationHandle = () => {
    setIsDeleteModalVisible(false);
    if (deleteHandler) {
      deleteHandler();
    }
  };

  const copyToClipboardHandler = (value: string) => {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  return (
    <Container>
      <Content>
        <Preview>
          <StreamStatusLabel status={streamStatus} />
          {streamIsNotProcessing(streamStatus) && (
            <StreamSettingsSelect
              streamId={id}
              showShareStreamHandler={showShareModal}
              isShowShareButton={Boolean(outputIp)}
              showDeleteStreamHandler={showDeleteModal}
              rebootHandler={rebootHandler}
              streamStatus={streamStatus}
            />
          )}

          <VideoIconBlock>
            <StreamIcon />
            Video
          </VideoIconBlock>
        </Preview>
      </Content>
      <Footer>
        {streamIsNotProcessing(streamStatus) && streamStatus !== StreamStatus.NotReady ? (
          <StatusSwitch
            checked={isActive}
            size="small"
            onChange={statusSwitchChangeHandler}
            loading={getIsActiveSwitchLoading}
          />
        ) : null}

        <FooterContent>{name}</FooterContent>
      </Footer>
      <ShareModal title="Share stream" onCancel={hideShareModal} footer={[]} centered visible={isShareModalVisible}>
        <ShareModalContent>
          <TextInput disabled id="streamName" name={name} value={outputIp} />
          <Tooltip title="Copied" trigger="click">
            <CopyToClipboardButton onClick={() => copyToClipboardHandler(outputIp)}>Copy</CopyToClipboardButton>
          </Tooltip>
        </ShareModalContent>
      </ShareModal>
      <ConfirmationModal
        isModalVisible={isDeleteModalVisible}
        handleCancel={hideDeleteModal}
        handleOk={deleteConfirmationHandle}
      >
        Are you sure you want to delete the stream?
      </ConfirmationModal>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 25px 35px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
`;

const Content = styled.div`
  padding-top: 56.25%;
  text-align: center;
  position: relative;
`;

const Preview = styled.div`
  ${({ theme: { streamItemPreviewBgColor } }) => css`
    & {
      background-color: ${streamItemPreviewBgColor};
      border-radius: 4px 4px 0 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:after {
        display: inline-block;
        height: 100%;

        vertical-align: middle;
        content: '';
      }
    }
  `}
`;

const StreamSettingsSelect = styled(StreamSettings)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const VideoIconBlock = styled.div`
  ${({ theme: { streamItemVideoIconColor } }) => css`
    & {
      color: ${streamItemVideoIconColor};
      width: 72px;
      height: 80px;
      background: rgba(255, 255, 255, 0.16);
      border-radius: 4px;
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle;
      padding: 15px 10px;
    }
  `}
`;

const Footer = styled.div`
  padding: 24px 0;
  position: relative;

  &:after {
    display: inline-block;
    height: 100%;

    vertical-align: middle;
    content: '';
  }
`;

const FooterContent = styled.div`
  ${({ theme: { streamItemNameColor } }) => css`
     {
      font-weight: 600;
      font-size: 16px;
      color: ${streamItemNameColor};
      display: inline-block;
      width: 100%;
      vertical-align: middle;
      padding: 0 75px 0 24px;
    }
  `}
`;

const StatusSwitch = styled(Switch)`
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 24px;
`;

const ShareModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      border: none;
      border-radius: 4px 4px 0 0;
    }

    .ant-modal-footer {
      border: none;
      padding: 0;
    }
  }
`;

const ShareModalContent = styled.div`
  position: relative;
`;

const CopyToClipboardButton = styled.button`
  ${({ theme: { primaryBtnHoverBgColor } }) => css`
    font-weight: 500;
    font-size: 12px;
    color: #ff7c29;
    line-height: 13px;
    height: 26px;
    display: block;
    background: none;
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    outline: none;
    border: 1px solid transparent;
    transition: all 0.5s;
    border-radius: 2px;

    &:hover {
      border-color: ${primaryBtnHoverBgColor};
    }
  `}
`;

const StreamStatusLabel = styled(StatusLabel)`
  position: absolute;
  top: 24px;
  left: 24px;
`;
