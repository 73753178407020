import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Moveable from 'react-moveable';
import { useDispatch } from 'react-redux';

import { DublicateIcon } from 'assets/icons/DublicateIcon';
import { TrashIcon } from 'assets/icons/TrashIcon';
import { putLogotypeCoordinatesToStoreAction } from 'core';
import { getStyleObjectFromString, getTranslateValues } from 'helpers';

import { MoveableContainer } from './MoveableContainer';

interface IProps {
  logotypeImgUrl?: string;
  dublicateLogotypeHandler?(): void;
  removeLogotypeHandler?(index: number): void;
  index: number;
  cssStyleString?: string;
  canvasSize?: Array<number>;
}

export const LogotypeBlock = ({
  logotypeImgUrl,
  dublicateLogotypeHandler,
  removeLogotypeHandler,
  index,
  cssStyleString = '',
  canvasSize = [992, 558],
}: IProps) => {
  const dispatch = useDispatch();
  const element = useRef<HTMLDivElement>(null);
  const moveable = useRef<Moveable>(null);

  const [isActive, setIsActive] = useState(false);

  const styleObj = getStyleObjectFromString(cssStyleString);

  const onBlurHandler = () => {
    setIsActive(false);
  };

  const onMouseDownHandler = () => {
    element.current!.focus();
    setIsActive(true);
  };

  const removeHandler = () => {
    if (removeLogotypeHandler) {
      removeLogotypeHandler(index);
    }
  };

  const blockChangingHandler = (blockIndex: number, el: SVGElement | HTMLElement) => {
    const { angle, x, y } = getTranslateValues(el);
    const elHeight = el instanceof HTMLElement ? el.offsetHeight : 0;
    const elWidth = el instanceof HTMLElement ? el.offsetWidth : 0;

    dispatch(
      putLogotypeCoordinatesToStoreAction({
        blockIndex,
        topLeftX: Number(x),
        topLeftY: Number(y),
        cssStyleString: el.style.cssText,
        angle: Number(angle),
        height: Number(elHeight),
        width: Number(elWidth),
      }),
    );
  };

  const logotypeOnloadHandler = () => {
    moveable.current!.updateRect();
  };

  return (
    <>
      <Element
        style={styleObj}
        isActive={isActive}
        ref={element}
        tabIndex={0}
        onMouseDown={onMouseDownHandler}
        onBlur={onBlurHandler}
      >
        <img style={{ width: '100%' }} src={logotypeImgUrl} alt="logo" onLoad={logotypeOnloadHandler} />
        <ElementMenu isActive={isActive}>
          {/* <span>Logotype</span> */}
          <ElementMenuButton onClick={dublicateLogotypeHandler}>
            <DublicateIcon />
          </ElementMenuButton>
          <ElementMenuButton onClick={removeHandler}>
            <TrashIcon />
          </ElementMenuButton>
        </ElementMenu>
      </Element>

      <MoveableContainer
        target={element}
        ref={moveable}
        renderDirections={['nw', 'ne', 'sw', 'se']}
        snappable
        bounds={{ left: 0, top: 0, right: canvasSize[0], bottom: canvasSize[1] }}
        origin
        edge={false}
        draggable
        throttleDrag={0}
        onDrag={({ target, transform }) => {
          target!.style.transform = transform;
        }}
        onDragEnd={({ target }) => {
          blockChangingHandler(index, target);
        }}
        keepRatio
        rotatable
        throttleRotate={0}
        onRotate={({ target, transform }) => {
          target!.style.transform = transform;
        }}
        onRotateEnd={({ target }) => {
          blockChangingHandler(index, target);
        }}
        scalable
        throttleScale={0}
        onScale={({ target, transform }) => {
          target!.style.transform = transform;
        }}
        onScaleEnd={({ target }) => {
          blockChangingHandler(index, target);
        }}
        resizable
        throttleResize={0}
        onResize={({ target, width, height, delta }) => {
          delta[0] && (target!.style.width = `${width}px`);
          delta[1] && (target!.style.height = `${height}px`);
        }}
        onResizeEnd={({ target }) => {
          blockChangingHandler(index, target);
        }}
      />
    </>
  );
};

interface IElementProps {
  isActive: boolean;
}

const Element = styled.div<IElementProps>`
  && {
    color: #ffffff;
    outline: none;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;

    & + .moveable-control-box {
      opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    }
  }
`;

const ElementMenu = styled.div<IElementProps>`
  && {
    height: 40px;
    line-height: 40px;
    background: #1b2124;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    position: absolute;
    left: 0;
    bottom: -40px;
    color: #ffffff;
    padding: 0 10px;
    width: 80px;
    transition: opacity ease-in-out 0.2s;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  }
`;

const ElementMenuButton = styled.div`
  && {
    display: inline-block;
    margin: 0 12px 0 0;
    vertical-align: sub;
    cursor: pointer;

    &:last-child {
      margin: 0;
    }
  }
`;
