import React from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { IconSvgProps } from 'types';

const ArrowDownSvg = (props: IconSvgProps) => {
  return (
    <svg {...props} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2929 0.292892C12.6834 -0.0976315 13.3166 -0.0976315 13.7071 0.292892C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.32853 8.08568 6.71894 8.09893 6.32428 7.73715L0.324276 2.23715C-0.0828428 1.86396 -0.110347 1.23139 0.262846 0.824274C0.636039 0.417155 1.26861 0.389653 1.67572 0.762845L6.96992 5.61586L12.2929 0.292892Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ArrowDownIcon = ({ rotate, style }: IconComponentProps) => (
  <Icon component={ArrowDownSvg} rotate={rotate} style={style} />
);
