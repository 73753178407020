import React from 'react';
import Icon from '@ant-design/icons';

const TrashSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 19.5V8H17V19.5C17 19.7761 16.7761 20 16.5 20H7.5C7.22386 20 7 19.7761 7 19.5Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3.5V3C14 2.44772 13.5523 2 13 2H11C10.4477 2 10 2.44772 10 3V3.5H5.5C5.22386 3.5 5 3.72386 5 4V4.5C5 4.77614 5.22386 5 5.5 5H18.5C18.7761 5 19 4.77614 19 4.5V4C19 3.72386 18.7761 3.5 18.5 3.5H14Z"
      fill="currentColor"
    />
  </svg>
);

export const TrashIcon = () => <Icon component={TrashSvg} />;
