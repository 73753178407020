import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { PageLayout, NewPasswordForm } from 'organisms';
import { confirmNewPasswordRequestAction } from 'core/actions';
import { useOnRequestSuccess } from 'hooks';

interface IValues {
  newPassword: string;
  repeatPassword: string;
}

interface IRouteParams {
  uid: string;
  token: string;
}

export const NewPasswordConfirm = () => {
  const dispatch = useDispatch();
  const { uid, token } = useParams<IRouteParams>();
  const { push } = useHistory();

  useOnRequestSuccess(confirmNewPasswordRequestAction, () => {
    push('/reset-password-success');
  });

  const onSubmitForm = ({ newPassword, repeatPassword }: IValues) => {
    dispatch(confirmNewPasswordRequestAction({ uid, token, newPassword, repeatPassword }));
  };
  return (
    <PageLayout isHeaderVisible isMenuVisible={false} isAuthPage>
      <LoginFormContainer>
        <NewPasswordForm onSubmit={onSubmitForm} />
      </LoginFormContainer>
    </PageLayout>
  );
};

const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.loginPageBgColor};
`;
