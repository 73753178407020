import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { resetServerAuthErrorAction } from 'core/actions';
import { getAuthServerError } from 'core/selectors';

export const useHandleServerError = () => {
  const dispatch = useDispatch();
  const authServerError = useSelector(getAuthServerError);

  useEffect(() => {
    dispatch(resetServerAuthErrorAction());

    return () => {
      dispatch(resetServerAuthErrorAction());
    };
  }, [dispatch]);

  const resetServerError = () => {
    dispatch(resetServerAuthErrorAction());
  };

  return { authServerError, resetServerError };
};
