interface IValues {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

interface IErrors {
  currentPassword?: string;
  newPassword?: string;
  repeatPassword?: string;
}

export const validationSchema = (values: IValues) => {
  const errors: IErrors = {};

  if (!values.currentPassword) {
    errors.currentPassword = 'Password is required';
  }

  if (values.currentPassword.length < 8) {
    errors.currentPassword = 'Must be at least 8 characters';
  }

  if (!values.newPassword) {
    errors.newPassword = 'New password is required';
  }

  if (values.newPassword.length < 8) {
    errors.newPassword = 'Must be at least 8 characters';
  }

  if (values.newPassword.length > 30) {
    errors.newPassword = '';
  }

  if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(values.newPassword)) {
    errors.newPassword = 'Must contain at least 1 lowercase, 1 uppercase letter and 1 digit';
  }

  if (!values.repeatPassword) {
    errors.repeatPassword = 'Repeat password is required';
  }

  if (values.repeatPassword.length < 8) {
    errors.repeatPassword = 'Must be at least 8 characters';
  }

  if (values.newPassword !== values.repeatPassword) {
    errors.repeatPassword = "Passwords doesn't match";
  }

  return errors;
};
