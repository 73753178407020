import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { TLogotype, TInputStream, TMask, TCamera } from 'types';
import canvasBg from 'assets/images/canvasBg.png';

import { VideoBlock } from './components/VideoBlock';
import { LogotypeBlock } from './components/LogotypeBlock';
import { InputMaskBlock } from './components/InputMaskBlock';
import { Menu } from './components/Menu';

interface IProps {
  canvasSize?: Array<number>;
  isMasksDisabled?: boolean;
  changeBackgroundHandler?(image: File, video?: File): void;
  removeBackgroundHandler?(): void;
  backgroundUrl?: string;
  changeFrontImageHandler?(image: File, video?: File): void;
  removeFrontImageHandler?(): void;
  frontImgUrl?: string;
  changeLogotypeImageHandler?(image: FileList): void;
  logotypeImgUrl?: string;
  logotypeItems?: Array<TLogotype>;
  dublicateLogotypeHandler?(): void;
  removeLogotypeHandler?(index: number): void;
  inputStreams: Array<TInputStream>;
  camerasList?: Array<TCamera>;
  videoInputMasks: Array<TMask>;
  changeMaskImageHandler?(image: FileList, index: number): void;
}

export const StreamCanvas = ({
  canvasSize = [992, 558],
  isMasksDisabled = true,
  changeBackgroundHandler,
  removeBackgroundHandler,
  backgroundUrl,
  changeFrontImageHandler,
  removeFrontImageHandler,
  frontImgUrl,
  changeLogotypeImageHandler,
  logotypeImgUrl,
  logotypeItems = [],
  dublicateLogotypeHandler,
  removeLogotypeHandler,
  inputStreams,
  camerasList = [],
  videoInputMasks,
  changeMaskImageHandler,
}: IProps) => {
  const [isPreviewVisible, setPreviewVisible] = useState(true);

  return (
    <Container canvasWidth={String(canvasSize[0])} bgImage={canvasBg}>
      <Menu
        isMasksDisabled={isMasksDisabled}
        changeBackgroundHandler={changeBackgroundHandler}
        removeBackgroundHandler={removeBackgroundHandler}
        backgroundUrl={backgroundUrl}
        changeFrontImageHandler={changeFrontImageHandler}
        removeFrontImageHandler={removeFrontImageHandler}
        frontImgUrl={frontImgUrl}
        changeLogotypeImageHandler={changeLogotypeImageHandler}
        logotypeImgUrl={logotypeImgUrl}
        videoInputMasks={videoInputMasks}
        changeMaskImageHandler={changeMaskImageHandler}
        previewVisibleHandler={setPreviewVisible}
        isPreviewVisible={isPreviewVisible}
      />
      <HeightController canvasHeight={String(canvasSize[1])}>
        <PreviewArea bgImage={backgroundUrl} frontImage={frontImgUrl}>
          {isPreviewVisible
            ? inputStreams.map(({ cameraId, cssStyleString }, index) => (
                <VideoBlock
                  key={cameraId}
                  name={camerasList.find((item) => item.id === cameraId)?.name || ''}
                  blockIndex={index}
                  cssStyleString={cssStyleString}
                  canvasSize={canvasSize}
                />
              ))
            : null}

          {logotypeItems.map(({ cssStyleString }, index) => (
            <LogotypeBlock
              dublicateLogotypeHandler={dublicateLogotypeHandler}
              removeLogotypeHandler={removeLogotypeHandler}
              logotypeImgUrl={logotypeImgUrl}
              // eslint-disable-next-line
              key={index}
              index={index}
              cssStyleString={cssStyleString}
              canvasSize={canvasSize}
            />
          ))}

          {!isMasksDisabled
            ? videoInputMasks.map(({ file, fileUrl, cssStyleString }, index) =>
                // eslint-disable-next-line
                  file || fileUrl ? <InputMaskBlock key={index} index={index} imageFile={file} imageUrl={fileUrl} cssStyleString={cssStyleString} canvasSize={  canvasSize }/> : null
              )
            : null}
        </PreviewArea>
      </HeightController>
    </Container>
  );
};

interface IContainerProps {
  bgImage: string;
  canvasWidth: string;
}

const Container = styled.div<IContainerProps>`
  ${({ theme: { streamCanvasBgColor }, bgImage, canvasWidth }) => css`
    & {
      background-color: ${streamCanvasBgColor}; 
      background-image: url('${bgImage}');
      width: ${canvasWidth}px;
      margin: 0 auto; 
      position: relative;
      }
    } 
  `}
`;

interface IHeightControllerProps {
  canvasHeight: string;
}

const HeightController = styled.div<IHeightControllerProps>`
  ${({ canvasHeight }) => css`
    & {
      position: relative;
      height: ${canvasHeight}px;
    }
  `}
`;

interface IPreviewAreaProps {
  bgImage: string | undefined;
  frontImage: string | undefined;
}

const PreviewArea = styled.div<IPreviewAreaProps>`
  ${({ bgImage = '', frontImage = '' }) => css`
    & {
      background-image: url('${bgImage}');
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('${frontImage}');
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 3;
      }
    }
  `}
`;
