import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteStreamRequestAction, startInstanceRequestAction, stopInstanceRequestAction } from 'core';
import { TStreamStatus } from 'types';
import { StreamStatus } from 'constants/statuses';
import { deleteInstanceRequestAction, rebootInstanceRequestAction } from 'core/actions';

import { StreamItem } from './StreamItem';

interface IProps {
  id?: string;
  name?: string;
  outputIp?: string;
  streamStatus?: TStreamStatus;
  instanceId?: string;
  isActive?: boolean;
}

export const StreamItemContainer = ({ id, name = '', outputIp = '', streamStatus, instanceId, isActive }: IProps) => {
  const dispatch = useDispatch();

  const deleteHandler = () => {
    if (streamStatus === StreamStatus.Ready && instanceId) {
      dispatch(deleteInstanceRequestAction({ instanceId }));
    }

    dispatch(deleteStreamRequestAction({ streamId: id! }));
  };

  const rebootHandler = () => {
    dispatch(rebootInstanceRequestAction({ instanceId: instanceId! }));
  };

  const changeStreamActiveHandler = (value: boolean) => {
    value
      ? dispatch(startInstanceRequestAction({ instanceId: instanceId!, streamId: id!, isActive: value }))
      : dispatch(stopInstanceRequestAction({ instanceId: instanceId!, streamId: id!, isActive: value }));
  };

  return (
    <StreamItem
      name={name}
      id={id}
      outputIp={outputIp}
      deleteHandler={deleteHandler}
      rebootHandler={rebootHandler}
      streamStatus={streamStatus}
      changeStreamActiveHandler={changeStreamActiveHandler}
      isActive={isActive}
    />
  );
};
