import React from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const EyeSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path
        d="M1 12.5L0.534818 12.3167L0.462576 12.5L0.534818 12.6833L1 12.5ZM23 12.5L23.4652 12.6833L23.5374 12.5L23.4652 12.3167L23 12.5ZM12 4.5C6.78697 4.5 2.33728 7.74281 0.534818 12.3167L1.46518 12.6833C3.12272 8.47719 7.21303 5.5 12 5.5V4.5ZM0.534818 12.6833C2.33728 17.2572 6.78697 20.5 12 20.5V19.5C7.21303 19.5 3.12272 16.5228 1.46518 12.3167L0.534818 12.6833ZM12 20.5C17.213 20.5 21.6627 17.2572 23.4652 12.6833L22.5348 12.3167C20.8773 16.5228 16.787 19.5 12 19.5V20.5ZM23.4652 12.3167C21.6627 7.74281 17.213 4.5 12 4.5V5.5C16.787 5.5 20.8773 8.47719 22.5348 12.6833L23.4652 12.3167Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.5C10.34 9.5 9 10.84 9 12.5C9 14.16 10.34 15.5 12 15.5C13.66 15.5 15 14.16 15 12.5C15 10.84 13.66 9.5 12 9.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const EyeIcon = ({ style }: IconComponentProps) => <Icon component={EyeSvg} style={style} />;
