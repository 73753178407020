import React from 'react';
import { useDispatch } from 'react-redux';
import { clone } from 'ramda';

import { getImageSize } from 'helpers';
import { aspectRatios, defaultLogotype } from 'mock';
import {
  putBackgroundToStoreAction,
  putBackgroundVideoToStoreAction,
  removeBackgroundToStoreAction,
  putFrontImageToStoreAction,
  putFrontVideoToStoreAction,
  removeFrontImageToStoreAction,
  putLogotypeImageToStoreAction,
  addLogotypeItemToStoreAction,
  removeLogotypeItemToStoreAction,
  putInputMaskImageToStoreAction,
} from 'core/actions';
import { StreamCanvas } from 'organisms';
import { TCamera, TInputStream, TLogotype, TMask } from 'types';

import { Footer } from './components/Footer';

interface IProps {
  isChromakey?: boolean;
  inputStreams?: Array<TInputStream>;
  camerasList?: Array<TCamera>;
  aspectRatio?: string;
  backgroundFile?: File | null;
  backgroundFileUrl?: string;
  frontgroundFile?: File | null;
  frontgroundFileUrl?: string;
  logotypes?: Array<TLogotype>;
  masks?: Array<TMask>;
  saveStreamHandler?(): void;
  saveAndLaunchStreamHandler?(): void;
  isVisibleSaveButton?: boolean;
}

export const CanvasSettingsStream = ({
  isChromakey,
  inputStreams = [],
  camerasList = [],
  aspectRatio,
  backgroundFile,
  backgroundFileUrl = '',
  frontgroundFile,
  frontgroundFileUrl = '',
  logotypes = [],
  masks = [],
  saveStreamHandler,
  saveAndLaunchStreamHandler,
  isVisibleSaveButton,
}: IProps) => {
  const dispatch = useDispatch();

  const backgroundUrl = backgroundFile ? window.URL.createObjectURL(backgroundFile!) : backgroundFileUrl;
  const frontImgUrl = frontgroundFile ? window.URL.createObjectURL(frontgroundFile!) : frontgroundFileUrl;

  let logotypeImgUrl = logotypes.length && logotypes[0].file ? window.URL.createObjectURL(logotypes[0].file!) : '';

  if (logotypes.length && logotypes[0].fileUrl) {
    logotypeImgUrl = logotypes[0].fileUrl;
  }

  const canvasAspectRatio = aspectRatio!.split(':');

  const canvasSize = aspectRatios.get(aspectRatio!);

  const changeBackgroundHandler = async (image: File, video?: File) => {
    const file = image;
    const backgroundSize: { width: number; height: number } = await getImageSize(image);

    if (
      Math.abs(
        backgroundSize.width / backgroundSize.height - Number(canvasAspectRatio[0]) / Number(canvasAspectRatio[1]),
      ) > 0.01
    ) {
      // eslint-disable-next-line no-alert
      alert(`Incorrect image. It must be ${canvasAspectRatio[0]}:${canvasAspectRatio[1]}.`);
      return null;
    }

    dispatch(removeBackgroundToStoreAction()); // Clear all bg types before put new one
    dispatch(putBackgroundToStoreAction(file));

    if (video) {
      dispatch(putBackgroundVideoToStoreAction(video));
    }

    return null;
  };

  const removeBackgroundHandler = () => {
    dispatch(removeBackgroundToStoreAction());
  };

  const changeFrontImageHandler = async (image: File, video?: File) => {
    const file = image;
    const backgroundSize: { width: number; height: number } = await getImageSize(image);

    if (
      Math.abs(
        backgroundSize.width / backgroundSize.height - Number(canvasAspectRatio[0]) / Number(canvasAspectRatio[1]),
      ) > 0.01
    ) {
      // eslint-disable-next-line no-alert
      alert(`Incorrect image. It must be ${canvasAspectRatio[0]}:${canvasAspectRatio[1]}.`);
      return null;
    }

    dispatch(removeFrontImageToStoreAction()); // Clear all front types before put new one
    dispatch(putFrontImageToStoreAction(file));

    if (video) {
      dispatch(putFrontVideoToStoreAction(video));
    }

    return null;
  };

  const removeFrontImageHandler = () => {
    dispatch(removeFrontImageToStoreAction());
  };

  const changeLogotypeImageHandler = (image: FileList) => {
    logotypes.length ? null : dispatch(addLogotypeItemToStoreAction(defaultLogotype));
    dispatch(putLogotypeImageToStoreAction(image[0]));
  };

  const dublicateLogotypeHandler = () => {
    const dublicatedLogotype = clone(defaultLogotype);
    dublicatedLogotype.file = logotypes[0].file;
    dublicatedLogotype.fileUrl = logotypes[0].fileUrl;

    dispatch(addLogotypeItemToStoreAction(dublicatedLogotype));
  };

  const removeLogotypeHandler = (index: number) => {
    dispatch(removeLogotypeItemToStoreAction(index));
  };

  const changeMaskImageHandler = (image: FileList, index: number) => {
    dispatch(putInputMaskImageToStoreAction({ image: image[0], index }));
  };

  return (
    <>
      <StreamCanvas
        canvasSize={canvasSize}
        isMasksDisabled={isChromakey}
        changeBackgroundHandler={changeBackgroundHandler}
        removeBackgroundHandler={removeBackgroundHandler}
        backgroundUrl={backgroundUrl}
        changeFrontImageHandler={changeFrontImageHandler}
        removeFrontImageHandler={removeFrontImageHandler}
        frontImgUrl={frontImgUrl}
        changeLogotypeImageHandler={changeLogotypeImageHandler}
        logotypeImgUrl={logotypeImgUrl}
        logotypeItems={logotypes}
        dublicateLogotypeHandler={dublicateLogotypeHandler}
        removeLogotypeHandler={removeLogotypeHandler}
        inputStreams={inputStreams}
        camerasList={camerasList}
        videoInputMasks={masks}
        changeMaskImageHandler={changeMaskImageHandler}
      />
      <Footer
        saveHandler={saveStreamHandler}
        saveAndLaunchHandler={saveAndLaunchStreamHandler}
        isVisibleSaveButton={isVisibleSaveButton}
      />
    </>
  );
};
