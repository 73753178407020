import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';

import { Select, TextInput } from 'atoms';
import { ConfirmationModal, FormSection } from 'molecules';
import { canvasFormats, defaultCanvas } from 'mock';
import { putDefaultCanvasToStoreAction, putStreamFormValueToStoreAction } from 'core';

interface IProps {
  streamName?: string;
  aspectRatio?: string;
  isShowAspectRatioConfirmation?: boolean;
  error?: string;
  onBlurInputHandler?: (e: any) => void;
}

interface ICurrentAspectRatioFieldValue {
  inputName: string;
  value: string;
}

export const AboutStreamBlock = ({
  streamName = '',
  aspectRatio,
  isShowAspectRatioConfirmation = false,
  error,
  onBlurInputHandler,
}: IProps) => {
  const dispatch = useDispatch();

  const [isAspectRatioChangeModalVisible, setIsAspectRatioChangeModalVisible] = useState(false);
  const [currentAspectRatioFieldValue, setCurrentAspectRatioFieldValue] = useState<ICurrentAspectRatioFieldValue>({
    inputName: '',
    value: '',
  });

  const showAspectRatioChangeModal = () => {
    setIsAspectRatioChangeModalVisible(true);
  };

  const hideAspectRatioChangeModal = () => {
    setIsAspectRatioChangeModalVisible(false);
  };

  const aspectRatioChange = useCallback(() => {
    dispatch(
      putStreamFormValueToStoreAction({
        name: currentAspectRatioFieldValue.inputName,
        value: currentAspectRatioFieldValue.value,
      }),
    );
    dispatch(putDefaultCanvasToStoreAction(defaultCanvas));
  }, [dispatch, currentAspectRatioFieldValue.inputName, currentAspectRatioFieldValue.value]);

  const aspectRatioChangeHandler = (inputName: string, value: string) => {
    setCurrentAspectRatioFieldValue({ inputName, value });
  };

  const aspectRatioChangeHandleOkHandler = () => {
    aspectRatioChange();
    hideAspectRatioChangeModal();
  };

  const streamNameChangeHandler = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    dispatch(
      putStreamFormValueToStoreAction({
        name: target.name,
        value: target.value,
      }),
    );
  };

  useEffect(() => {
    if (currentAspectRatioFieldValue.inputName) {
      isShowAspectRatioConfirmation ? showAspectRatioChangeModal() : aspectRatioChange();
    }
    // eslint-disable-next-line
  }, [currentAspectRatioFieldValue]);

  return (
    <FormSection title="About stream">
      <Row gutter={[32, 0]}>
        <Col xs={24} md={12} xl={8}>
          <AboutStreamInput
            id="streamName"
            name="streamName"
            label="Stream Name"
            value={streamName}
            error={error}
            onChange={streamNameChangeHandler}
            onBlur={onBlurInputHandler}
          />
        </Col>
        <Col xs={24} md={12} xl={8}>
          <Select
            inputName="aspectRatio"
            label="Aspect ratio"
            onChange={aspectRatioChangeHandler}
            optionItems={canvasFormats}
            value={aspectRatio}
          />
        </Col>
      </Row>
      <ConfirmationModal
        isModalVisible={isAspectRatioChangeModalVisible}
        handleCancel={hideAspectRatioChangeModal}
        handleOk={aspectRatioChangeHandleOkHandler}
      >
        Attention! All data on the Canvas will be lost. Are you sure you want to change the aspect ratio of the Canvas?
      </ConfirmationModal>
    </FormSection>
  );
};

const AboutStreamInput = styled(TextInput)`
  margin-bottom: 8px;
`;
