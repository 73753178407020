import React from 'react';
import styled, { css } from 'styled-components';
import { Menu as AntdMenu } from 'antd';

import { UploadBox, VideoUploadBox } from 'molecules';
import { TMask } from 'types';
import { Switch } from 'atoms';

const { SubMenu } = AntdMenu;

interface IProps {
  isMasksDisabled?: boolean;
  changeBackgroundHandler?(image: File, video?: File): void;
  removeBackgroundHandler?(): void;
  backgroundUrl?: string;
  changeFrontImageHandler?(image: File, video?: File): void;
  removeFrontImageHandler?(): void;
  frontImgUrl?: string;
  changeLogotypeImageHandler?(image: FileList): void;
  logotypeImgUrl?: string;
  videoInputMasks?: Array<TMask>;
  changeMaskImageHandler?(image: FileList, index: number): void;
  previewVisibleHandler?(value: boolean): void;
  isPreviewVisible?: boolean;
}

export const Menu = ({
  isMasksDisabled,
  changeBackgroundHandler,
  removeBackgroundHandler,
  backgroundUrl,
  changeFrontImageHandler,
  frontImgUrl,
  changeLogotypeImageHandler,
  removeFrontImageHandler,
  logotypeImgUrl,
  videoInputMasks,
  changeMaskImageHandler,
  previewVisibleHandler,
  isPreviewVisible = true,
}: IProps) => {
  const changeMaskImage = (index: number, image: FileList) => {
    if (changeMaskImageHandler) {
      changeMaskImageHandler(image, index);
    }
  };
  return (
    <MenuContainer mode="horizontal">
      {!isMasksDisabled ? (
        <SubMenu key="Mask" title="Mask" style={{ display: 'none' }}>
          <AntdMenu.Item
            style={{ height: 'auto', padding: '10px 16px 10px', background: 'transparent' }}
            key="setting:3"
          >
            {videoInputMasks!.map(({ fileUrl, file }, index) => {
              return (
                <UploadBox
                  // eslint-disable-next-line
                  key={index}
                  caption={`Choose Mask Stream #${index + 1}`}
                  onChange={(image) => changeMaskImage(index, image)}
                  fileUrl={file ? window.URL.createObjectURL(file) : fileUrl}
                />
              );
            })}
          </AntdMenu.Item>
        </SubMenu>
      ) : null}

      <SubMenu key="Background" title="Background">
        <AntdMenu.Item style={{ height: 'auto', padding: '10px 16px 20px', background: 'transparent' }} key="setting:4">
          <VideoUploadBox
            caption="Choose background"
            onChange={changeBackgroundHandler}
            onRemove={removeBackgroundHandler}
            fileUrl={backgroundUrl}
          />
        </AntdMenu.Item>
      </SubMenu>
      <SubMenu key="FrontImage" title="Front Image">
        <AntdMenu.Item style={{ height: 'auto', padding: '10px 16px 20px', background: 'transparent' }} key="setting:5">
          <VideoUploadBox
            caption="Choose front image"
            onChange={changeFrontImageHandler}
            onRemove={removeFrontImageHandler}
            fileUrl={frontImgUrl}
          />
        </AntdMenu.Item>
      </SubMenu>
      <SubMenu key="Logotype" title="Logotype">
        <AntdMenu.Item style={{ height: 'auto', padding: '10px 16px 20px', background: 'transparent' }} key="setting:6">
          <UploadBox caption="Choose logotype" onChange={changeLogotypeImageHandler} fileUrl={logotypeImgUrl} />
        </AntdMenu.Item>
      </SubMenu>
      <StreamPreviewMenuItem key="StreamPreview">
        <Switch size="small" offLabel="Stream preview" onChange={previewVisibleHandler} checked={isPreviewVisible} />
      </StreamPreviewMenuItem>
    </MenuContainer>
  );
};

const MenuContainer = styled(AntdMenu)`
  ${({ theme: { streamCanvasMenuBgColor, streamCanvasMenuColor, streamCanvasMenuHoverColor } }) => css`
    & {
      background-color: ${streamCanvasMenuBgColor};
      border-bottom: none;
      color: ${streamCanvasMenuColor};
      height: 56px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      position: relative;
      z-index: 4;
    }
    .ant-menu-item-selected {
      background-color: transparent;
    }

    &.ant-menu-horizontal {
      line-height: 54px;
    }

    && .ant-menu-submenu {
      border-bottom: none;
      margin: 0;
      top: 0;

      .ant-menu-submenu-title {
        padding: 0 20px;
      }

      .anticon {
        vertical-align: middle;
      }
    }

    & .ant-menu-submenu.ant-menu-submenu-open,
    && .ant-menu-submenu:hover {
      background-color: ${streamCanvasMenuHoverColor};
      border-bottom: none;
      color: ${streamCanvasMenuColor};

      .ant-menu-submenu-title:hover {
        color: ${streamCanvasMenuColor};
      }
    }

    & .ant-menu-submenu.ant-menu-submenu-selected {
      border-bottom: none;
      color: ${streamCanvasMenuColor};
    }

    && .ant-menu-item {
      border-bottom: none;
    }

    && .ant-menu-item-active,
    && .ant-menu-item-selected,
    && .ant-menu-item:hover {
      border-bottom: none;
      color: ${streamCanvasMenuColor};
    }
  `}
`;

const StreamPreviewMenuItem = styled(AntdMenu.Item)`
  & {
    float: right;
  }
`;
