import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';

import { StreamStatus } from 'constants/statuses';
import { TStreamStatus } from 'types';

interface IProps {
  status: TStreamStatus;
}

interface IColors {
  textColor: string;
  backgroundColor: string;
}

export const StatusLabel = ({ status, ...props }: IProps) => {
  const theme = useContext(ThemeContext);

  const colorConfig = {
    [StreamStatus.Ready]: {
      textColor: theme.statusSuccessTextColor,
      backgroundColor: theme.statusSuccessBgColor,
    },
    [StreamStatus.NotReady]: {
      textColor: theme.statusErrorTextColor,
      backgroundColor: theme.statusErrorBgColor,
    },
    [StreamStatus.Launching]: {
      textColor: theme.statusWarningTextColor,
      backgroundColor: theme.statusWarningBgColor,
    },
    [StreamStatus.Stopping]: {
      textColor: theme.statusWarningTextColor,
      backgroundColor: theme.statusWarningBgColor,
    },
    [StreamStatus.Stopped]: {
      textColor: theme.statusSuccessTextColor,
      backgroundColor: theme.statusSuccessBgColor,
    },
    [StreamStatus.Failed]: {
      textColor: theme.statusErrorTextColor,
      backgroundColor: theme.statusErrorBgColor,
    },
  };

  const { textColor, backgroundColor } = colorConfig[status];

  return (
    <StreamStatusLabelContainer textColor={textColor} backgroundColor={backgroundColor} {...props}>
      {status}
    </StreamStatusLabelContainer>
  );
};

const StreamStatusLabelContainer = styled.div`
  ${({ textColor, backgroundColor }: IColors) => css`
    color: ${textColor};
    background: ${backgroundColor};
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
    border-radius: 5px;
  `}
`;
