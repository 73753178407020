import { BaseService } from './BaseService';
import { checkTokenInterceptor } from './axiosInterceptors';

checkTokenInterceptor();

class CameraAPIService extends BaseService {
  public async getCameraList() {
    const route = `list`;

    return this.get(route);
  }
}

export const cameraService = new CameraAPIService();
