import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Moveable from 'react-moveable';
import { useDispatch } from 'react-redux';

import { getStyleObjectFromString, getTranslateValues } from 'helpers';
import { putInputStreamCoordinatesToStoreAction } from 'core/actions';

import { MoveableContainer } from './MoveableContainer';

interface IProps {
  name: string;
  blockIndex: number;
  cssStyleString?: string;
  canvasSize?: Array<number>;
}

export const VideoBlock = ({ name, blockIndex, cssStyleString = '', canvasSize = [992, 558] }: IProps) => {
  const dispatch = useDispatch();
  const element = useRef<HTMLDivElement>(null);
  const moveable = useRef<Moveable>(null);

  const [isActive, setIsActive] = useState(false);

  const onBlurHandler = () => {
    setIsActive(false);
  };

  const onMouseDownHandler = () => {
    element.current!.focus();
    setIsActive(true);
  };

  const styleObj = getStyleObjectFromString(cssStyleString);

  const blockChangingHandler = (elementIndex: number, el: SVGElement | HTMLElement) => {
    const { angle, x, y } = getTranslateValues(el);
    const elHeight = el instanceof HTMLElement ? el.offsetHeight : 0;
    const elWidth = el instanceof HTMLElement ? el.offsetWidth : 0;

    dispatch(
      putInputStreamCoordinatesToStoreAction({
        blockIndex: elementIndex,
        topLeftX: Number(x),
        topLeftY: Number(y),
        cssStyleString: el.style.cssText,
        angle: Number(angle),
        height: Number(elHeight),
        width: Number(elWidth),
      }),
    );
  };

  return (
    <>
      <Container
        style={styleObj}
        isActive={isActive}
        ref={element}
        tabIndex={0}
        onMouseDown={onMouseDownHandler}
        onBlur={onBlurHandler}
      >
        <Header>{name}</Header>
      </Container>
      <MoveableContainer
        target={element}
        ref={moveable}
        renderDirections={['nw', 'ne', 'sw', 'se']}
        snappable
        bounds={{ left: 0, top: 0, right: canvasSize[0], bottom: canvasSize[1] }}
        origin
        edge={false}
        draggable
        throttleDrag={0}
        onDrag={({ target, transform }) => {
          target!.style.transform = transform;
        }}
        onDragEnd={({ target }) => {
          blockChangingHandler(blockIndex, target);
        }}
        keepRatio
        rotatable
        throttleRotate={0}
        onRotate={({ target, transform }) => {
          target!.style.transform = transform;
        }}
        onRotateEnd={({ target }) => {
          blockChangingHandler(blockIndex, target);
        }}
        scalable
        throttleScale={0}
        onScale={({ target, transform }) => {
          target!.style.transform = transform;
        }}
        onScaleEnd={({ target }) => {
          blockChangingHandler(blockIndex, target);
        }}
        resizable
        throttleResize={0}
        onResize={({ target, width, height, delta }) => {
          delta[0] && (target!.style.width = `${width}px`);
          delta[1] && (target!.style.height = `${height}px`);
        }}
        onResizeEnd={({ target }) => {
          blockChangingHandler(blockIndex, target);
        }}
      />
    </>
  );
};

interface IElementProps {
  isActive: boolean;
}

const Container = styled.div<IElementProps>`
  ${({ theme: { streamCanvasVideoBlockBgColor }, isActive }) => css`
    & {
      position: absolute;
      left: 0;
      top: 0;
      width: 640px;
      height: 360px;
      background-color: ${streamCanvasVideoBlockBgColor};
      border-radius: 4px;
      outline: none;
      overflow: hidden;
      z-index: 6;
    }
    & + .moveable-control-box {
      opacity: ${isActive ? 1 : 0};
    }
  `}
`;

const Header = styled.div`
  ${({ theme: { streamCanvasVideoBlockHeaderBgColor, streamCanvasVideoBlockHeaderTextColor } }) => css`
    & {
      height: 45px;
      font-weight: 600;
      font-size: 16px;
      color: ${streamCanvasVideoBlockHeaderTextColor};
      line-height: 45px;
      padding: 0 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: ${streamCanvasVideoBlockHeaderBgColor};
    }
  `}
`;
