export enum StreamStatus {
  Ready = 'Ready',
  NotReady = 'Not ready',
  Launching = 'Launching',
  Stopping = 'Stopping',
  Stopped = 'Stopped',
  Failed = 'Failed',
}

export enum ReceiverStatus {
  Stopped = 'stopped',
  Running = 'running',
}
