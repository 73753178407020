import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { InputSubText } from 'atoms';
import { ArrowDownIcon, CheckedIcon } from 'assets';

interface IOption {
  id: string;
  value: string;
  caption: string;
}

interface IProps {
  label: string;
  optionItems: IOption[];
  inputName: string;
  value?: string;
  onChange?: (inputName: string, value: string) => void;
  onFilterButtonBlur?: () => void;
  isShowDefaultOption?: boolean;
  error?: string;
}

export const Select = ({
  label,
  optionItems,
  inputName,
  value = 'Choose',
  onChange,
  onFilterButtonBlur,
  isShowDefaultOption = false,
  error = '',
  ...props
}: IProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const onSelectClick = () => setIsOpened(true);

  const onBlur = () => {
    setIsOpened(false);

    if (onFilterButtonBlur) {
      onFilterButtonBlur();
    }
  };

  const onClick = (optionValue: string) => {
    if (onChange) {
      onChange(inputName, optionValue);
    }
    setIsOpened(false);
  };

  let currentCaption;
  if (value !== 'Choose') {
    optionItems.forEach((item) => {
      if (item.value === value) {
        currentCaption = item.caption;
      }
    });
  }

  const selectOptions = [...optionItems];
  if (isShowDefaultOption) {
    selectOptions.push({ id: 'choose', value: '', caption: 'Choose' });
  }

  return (
    <Container {...props} tabIndex={0} onBlur={onBlur}>
      <SelectInput error={error} onClick={onSelectClick}>
        {currentCaption || 'Choose'}
      </SelectInput>
      <Label error={error}>{label}</Label>
      <OptionsContainer isOpened={isOpened}>
        {selectOptions.map((option: IOption) => {
          const active = option.value === value;
          return (
            <OptionWrapper key={option.id}>
              <Option onClick={() => onClick(option.value)} active={active}>
                {option.caption}
              </Option>
              {active && (
                <CheckedIconContainer>
                  <CheckedIcon />
                </CheckedIconContainer>
              )}
            </OptionWrapper>
          );
        })}
      </OptionsContainer>
      <ArrowIconContainer>
        <ArrowDownIcon />
      </ArrowIconContainer>
      <InputSubText text={error || ''} error type="default" />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 0 5px 0;
  position: relative;
  outline: none;
`;

const ArrowIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  pointer-events: none;
`;

interface IInput {
  error: string;
}

const SelectInput = styled.div<IInput>`
  ${({
    error,
    theme: {
      selectTextColor,
      selectDefaultBorderColor,
      selectHoverBorderColor,
      selectDisabledBorderColor,
      selectDisabledTextColor,
      baseInputErrorColor,
    },
  }) => css`
    display: flex;
    position: relative;
    width: 100%;
    padding: 16px;
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${selectTextColor};
    border: 1px solid ${selectDefaultBorderColor};
    border: 1px solid ${error ? baseInputErrorColor : selectDefaultBorderColor};
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      border: 1px solid ${selectHoverBorderColor};
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      border: 1px solid ${selectDisabledBorderColor};
      color: ${selectDisabledTextColor};
    }
  `}
`;

interface IOptionsContainer {
  isOpened: boolean;
}

const OptionsContainer = styled.div<IOptionsContainer>`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 25px 35px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  transform: ${({ isOpened }) => (isOpened ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  transition: transform 0.15s ease;
  outline: none;
`;

interface IOptionStyles {
  active: boolean;
}

const OptionWrapper = styled.div`
  position: relative;
`;

const Option = styled.div<IOptionStyles>`
  ${({
    theme: { optionActiveTextColor, optionTextColor, optionActiveBgColor, optionBgColor, optionHoverTextColor },
    active,
  }) => css`
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${active ? optionActiveTextColor : optionTextColor};
    padding: 8px 16px;
    background-color: ${active ? optionActiveBgColor : optionBgColor};
    cursor: pointer;

    &:hover {
      color: ${optionHoverTextColor};
    }
  `}
`;

const CheckedIconContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  pointer-events: none;
`;

interface ILabelProp {
  error: string;
}

const Label = styled.span<ILabelProp>`
  position: absolute;
  z-index: 2;
  top: -8px;
  left: 16px;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.selectLabelTextColor};
  opacity: 1;
  padding: 0 4px;
  background-color: ${({ theme }) => theme.selectLabelBgColor};

  ${({ error, theme }) =>
    error &&
    css`
      color: ${theme.baseInputErrorColor};
      opacity: 1;
      top: -8px;
      padding: 0 4px;
      background-color: ${theme.baseInputLabelBgColor};
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;

      transition: color 0.2s ease;
      transition: opacity 0.2s ease;
      transition: top 0.2s ease;
      transition: padding 0.2s ease;
      transition: background-color 0.2s ease;
      transition: font-weight 0.2s ease;
      transition: font-size 0.2s ease;
      transition: line-height 0.2s ease;
    `};
`;
