import { ActionType, createReducer } from 'typesafe-actions';

import { TStreamsListItem, TStreamStatus } from 'types';
import {
  putStreamsToStoreAction,
  clearStreamsToStoreAction,
  updateStreamsPaginationAction,
  putIsActiveFilterToStoreAction,
  putStatusFilterToStoreAction,
  putActiveStreamsNumberToStoreAction,
  putSearchStrToStoreAction,
} from 'core/actions';

export interface IStreamsState {
  streamsList: TStreamsListItem[];
  total: number;
  limit: number;
  offset: number;
  isActiveFilter: boolean | null;
  statusFilter: TStreamStatus | null;
  activeStreamsNumber: number;
  searchStr: string | '';
}

const defaultState: IStreamsState = {
  streamsList: [],
  total: 0,
  limit: 8,
  offset: 1,
  isActiveFilter: null,
  statusFilter: null,
  activeStreamsNumber: 0,
  searchStr: '',
};

const actions = {
  putStreamsToStoreAction,
  clearStreamsToStoreAction,
  updateStreamsPaginationAction,
  putIsActiveFilterToStoreAction,
  putStatusFilterToStoreAction,
  putActiveStreamsNumberToStoreAction,
  putSearchStrToStoreAction,
};

export const streamsReducer = createReducer<IStreamsState, ActionType<typeof actions>>(defaultState)
  .handleAction(putStreamsToStoreAction, (state, { payload: { streamsList, total, offset } }) => {
    if (offset) {
      return {
        ...state,
        streamsList,
        total,
        offset,
      };
    }

    return {
      ...state,
      streamsList,
      total,
    };
  })
  .handleAction(putActiveStreamsNumberToStoreAction, (state, { payload: activeStreamsNumber }) => ({
    ...state,
    activeStreamsNumber,
  }))
  .handleAction(clearStreamsToStoreAction, (state) => ({
    ...state,
    streamsList: [],
  }))
  .handleAction(updateStreamsPaginationAction, (state, { payload: { offset, limit } }) => ({
    ...state,
    offset,
    limit,
  }))
  .handleAction(putIsActiveFilterToStoreAction, (state, { payload: value }) => ({
    ...state,
    offset: 1, // Redirect to first page when filter was applying
    isActiveFilter: value,
    statusFilter: null,
  }))
  .handleAction(putStatusFilterToStoreAction, (state, { payload: value }) => ({
    ...state,
    offset: 1, // Redirect to first page when filter was applying
    isActiveFilter: null,
    statusFilter: value,
  }))
  .handleAction(putSearchStrToStoreAction, (state, { payload: searchStr }) => ({
    ...state,
    offset: 1, // List must start from first page
    searchStr,
  }));
