import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { clone } from 'ramda';

import { Button } from 'atoms';
import { TCamera, TInputStream, TMask, TSettingsStreamOutput } from 'types';
import { defaultMask } from 'mock';

import { AboutStreamBlock } from './components/AboutStreamBlock';
import { InputStreamSettingsBlock } from './components/InputStreamSettingsBlock';
import { BackgroundSettingsBlock } from './components/BackgroundSettingsBlock';
import { RTMPServerBlock } from './components/RTMPServerBlock';
import { AudioSettingsBlock } from './components/AudioSettingsBlock';
import { validationSchema as validate } from './validationSchema';

interface IProps {
  streamName?: string;
  aspectRatio: string;
  isShowAspectRatioConfirmation?: boolean;
  inputStreamList?: Array<TInputStream>;
  camerasList?: Array<TCamera>;
  isChromakey?: boolean;
  addInputStreamHandler?(): void;
  changeFormHandler?(): void;
  saveSettingsFormHandler?(formData: TSettingsStreamOutput): void;
  isAudioEnabled?: boolean;
  audioUrl?: string;
  masks?: Array<TMask>;
  streamKey: string;
  ingestIp: string;
}

export const SettingsStream = ({
  streamName = '',
  aspectRatio,
  isShowAspectRatioConfirmation = false,
  inputStreamList = [],
  camerasList = [],
  isChromakey = false,
  addInputStreamHandler,
  changeFormHandler,
  saveSettingsFormHandler,
  isAudioEnabled = false,
  audioUrl = '',
  masks = [],
  streamKey,
  ingestIp,
}: IProps) => {
  const [inputStream1, inputStream2] = inputStreamList;
  const cameraId1 = inputStream1 ? inputStream1.cameraId : '';
  const cameraId2 = inputStream2 ? inputStream2.cameraId : '';

  const { values, errors, touched, handleBlur, handleSubmit, isSubmitting, isValidating } = useFormik({
    initialValues: {
      streamName,
      aspectRatio,
      cameraId1,
      cameraId2,
      isChromakey,
      isAudioEnabled,
      audioUrl,
      streamKey,
      ingestIp,
    },
    enableReinitialize: true,
    validate,
    onSubmit: () => {
      const newInputStreamList = clone(inputStreamList);

      if (inputStreamList?.length === 2 && !inputStreamList[1].cameraId) {
        newInputStreamList.length = 1;
      }

      // masks = [] if it's a new stream. And we should fill masks by default values.
      let newMasks: Array<TMask> = clone(masks);
      if (!masks.length) {
        newMasks = newInputStreamList.map(() => clone(defaultMask));
      }

      if (newInputStreamList.length > newMasks.length) {
        newMasks.push(clone(defaultMask));
      }

      if (newInputStreamList.length < newMasks.length) {
        newMasks.length = newInputStreamList?.length;
      }

      const formData: TSettingsStreamOutput = {
        inputStreams: newInputStreamList,
        masks: newMasks,
      };

      if (saveSettingsFormHandler) {
        saveSettingsFormHandler(formData);
      }
      if (changeFormHandler) {
        changeFormHandler();
      }
    },
  });

  // TODO: add smooth transition
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = Object.keys(errors);
      if (keys.length > 0) {
        const selector = `[name=${keys[0]}]`;
        const errorElement = document.querySelector(selector) as HTMLElement;
        if (errorElement) {
          errorElement.focus();
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return (
    <SettingsFormContainer>
      <AboutStreamBlock
        streamName={values.streamName}
        aspectRatio={values.aspectRatio}
        isShowAspectRatioConfirmation={isShowAspectRatioConfirmation}
        onBlurInputHandler={handleBlur}
        error={errors.streamName && touched.streamName ? errors.streamName : ''}
      />
      <InputStreamSettingsBlock
        inputStreamList={inputStreamList}
        camerasList={camerasList}
        addInputStreamHandler={addInputStreamHandler}
        inputValues={[values.cameraId1, values.cameraId2]}
        errors={[
          errors.cameraId1 && touched.cameraId1 ? errors.cameraId1 : '',
          errors.cameraId2 && touched.cameraId2 ? errors.cameraId2 : '',
        ]}
      />
      {/* <WorkerSettingsBlock /> */}
      <BackgroundSettingsBlock value={values.isChromakey} />
      <AudioSettingsBlock
        isAudioEnabled={values.isAudioEnabled}
        audioUrl={values.audioUrl}
        error={errors.audioUrl && touched.audioUrl ? errors.audioUrl : ''}
      />
      <RTMPServerBlock
        value={ingestIp}
        title="Server RTMP"
        name="ingest_ip"
        label="Server RTMP"
        onBlurInputHandler={handleBlur}
        error={errors.ingestIp}
      />
      <RTMPServerBlock
        value={streamKey}
        title="Stream Key"
        name="stream_key"
        label="Stream Key"
        onBlurInputHandler={handleBlur}
        error={errors.streamKey}
      />
      {/* <OutputStreamSettingsBlock /> */}
      <ApplySection>
        <Button onClick={() => handleSubmit()}>Continue</Button>
      </ApplySection>
    </SettingsFormContainer>
  );
};

const SettingsFormContainer = styled.form`
  margin-top: 32px;
  background-color: ${({ theme }) => theme.settingsFormBgColor};
`;

const ApplySection = styled.div`
  display: flex;
  padding: 32px 32px 48px 32px;
`;
