import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useOnRequestSuccess } from 'hooks';
import { TSettingsStreamOutput } from 'types';
import { getStream, getCamerasList } from 'core/selectors';
import { defaultInputStream } from 'mock';
import { PageLayout, SettingsStream, CanvasSettingsStream } from 'organisms';
import {
  getStreamRequestAction,
  clearStreamToStoreAction,
  putDefaultInputStreamToStoreAction,
  putSettingsStreamToStoreAction,
  updateStreamRequestAction,
  createStreamRequestAction,
  getCameraListRequestAction,
  updateStreamWithInstanceIdRequestAction,
  createStreamWithInstanceIdRequestAction,
  startInstanceRequestAction,
} from 'core/actions';
import { StreamStatus } from 'constants/statuses';

import { Header } from './components/Header';

export const CreateEditStream = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams() as any;

  const {
    streamName,
    aspectRatio,
    inputStreams,
    isChromakey,
    isAudioEnabled,
    audioUrl,
    streamStatus,
    instanceId,
    canvas: {
      backgroundImage,
      backgroundImageUrl,
      backgroundVideo,
      backgroundVideoUrl,
      frontgroundImage,
      frontgroundImageUrl,
      frontgroundVideo,
      frontgroundVideoUrl,
      logotypes = [],
      masks,
    },
    stream_key,
    ingest_ip,
  } = useSelector(getStream);

  const camerasList = useSelector(getCamerasList);

  const isEditStream = Boolean(id);
  const isVisibleSaveButton = streamStatus === StreamStatus.NotReady;

  const isShowAspectRatioConfirmation = Boolean(
    backgroundImage ||
      backgroundImageUrl ||
      backgroundVideo ||
      backgroundVideoUrl ||
      frontgroundImage ||
      frontgroundImageUrl ||
      frontgroundVideo ||
      frontgroundVideoUrl ||
      logotypes.length ||
      masks.find((item) => item.file || item.fileUrl),
  );

  const [isShowCanvasForm, setIsShowCanvasForm] = useState(false);
  const changeFormHandler = () => {
    setIsShowCanvasForm(!isShowCanvasForm);
  };

  const addInputStreamHandler = () => {
    dispatch(putDefaultInputStreamToStoreAction(defaultInputStream));
  };

  const saveSettingsFormHandler = (formData: TSettingsStreamOutput) => {
    dispatch(putSettingsStreamToStoreAction(formData));
  };

  const saveStreamHandler = () => {
    isEditStream ? dispatch(updateStreamRequestAction({ streamId: id })) : dispatch(createStreamRequestAction());
  };

  const saveAndLaunchStreamHandler = () => {
    if (streamStatus === StreamStatus.NotReady) {
      // NotReady is default status. And it can be a new stream.
      isEditStream
        ? dispatch(updateStreamWithInstanceIdRequestAction({ streamId: id }))
        : dispatch(createStreamWithInstanceIdRequestAction());
      return;
    }

    if (streamStatus === StreamStatus.Stopped) {
      // If status Stopped stream is only editing.
      dispatch(updateStreamRequestAction({ streamId: id }));
      dispatch(startInstanceRequestAction({ instanceId, streamId: id, isActive: true }));
      return;
    }

    dispatch(updateStreamRequestAction({ streamId: id }));
  };

  useEffect(() => {
    dispatch(getCameraListRequestAction());

    if (id) {
      dispatch(
        getStreamRequestAction({
          streamId: id,
        }),
      );
    }

    return () => {
      dispatch(clearStreamToStoreAction());
    };
  }, [dispatch, id]);

  useOnRequestSuccess(updateStreamRequestAction, () => {
    push('/dashboard');
  });

  useOnRequestSuccess(createStreamRequestAction, () => {
    push('/dashboard');
  });

  useOnRequestSuccess(updateStreamWithInstanceIdRequestAction, () => {
    push('/dashboard');
  });

  useOnRequestSuccess(createStreamWithInstanceIdRequestAction, () => {
    push('/dashboard');
  });

  return (
    <PageLayout>
      <Header isShowBackBtn={!!isShowCanvasForm} changeFormHandler={changeFormHandler}>
        Settings stream
      </Header>
      {isShowCanvasForm ? (
        <CanvasSettingsStream
          isChromakey={isChromakey}
          inputStreams={inputStreams}
          camerasList={camerasList}
          aspectRatio={aspectRatio}
          backgroundFile={backgroundImage}
          backgroundFileUrl={backgroundImageUrl}
          frontgroundFile={frontgroundImage}
          frontgroundFileUrl={frontgroundImageUrl}
          logotypes={logotypes}
          masks={masks}
          saveStreamHandler={saveStreamHandler}
          saveAndLaunchStreamHandler={saveAndLaunchStreamHandler}
          isVisibleSaveButton={isVisibleSaveButton}
        />
      ) : (
        <SettingsStream
          streamName={streamName}
          aspectRatio={aspectRatio}
          isShowAspectRatioConfirmation={isShowAspectRatioConfirmation}
          inputStreamList={inputStreams}
          camerasList={camerasList}
          isChromakey={isChromakey}
          addInputStreamHandler={addInputStreamHandler}
          saveSettingsFormHandler={saveSettingsFormHandler}
          changeFormHandler={changeFormHandler}
          isAudioEnabled={isAudioEnabled}
          audioUrl={audioUrl}
          masks={masks}
          streamKey={stream_key}
          ingestIp={ingest_ip}
        />
      )}
    </PageLayout>
  );
};
