import React, { ReactNode } from 'react';
import { Switch as AntdSwitch } from 'antd';
import styled, { css } from 'styled-components';

interface IProps {
  className?: string;
  checked?: boolean;
  size?: 'default' | 'small';
  onChange?(checked: boolean, event: Event): void;
  offLabel?: string | ReactNode;
  onLabel?: string | ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

export const Switch = ({
  checked = false,
  size,
  onChange,
  offLabel = '',
  onLabel = '',
  disabled = false,
  loading = false,
  ...props
}: IProps) => (
  <ComponentContainer {...props}>
    {offLabel && <SwitchLabel>{offLabel}</SwitchLabel>}
    <SwitchContainer checked={checked} onChange={onChange} size={size} disabled={disabled} loading={loading} />
    {onLabel && <SwitchLabel>{onLabel}</SwitchLabel>}
  </ComponentContainer>
);

const ComponentContainer = styled.div`
  font-family: 'Avenir Next Cyr';
  font-size: 12px;
  font-weight: 600;
`;

const SwitchLabel = styled.span`
  & {
    padding: 0 16px;
  }
`;

const SwitchContainer = styled(AntdSwitch)`
  ${({
    theme: {
      switchBgColor,
      switchHeadBgColor,
      switchActiveBgColor,
      switchActiveHeadBgColor,
      switchBorderColor,
      switchActiveBorderColor,
    },
  }) => css`
    & {
      height: 24px;
      min-width: 48px;
      background-color: ${switchBgColor};
      border: 1px solid ${switchBgColor};
      --antd-wave-shadow-color: ${switchBgColor};

      &:hover {
        border-color: ${switchBorderColor};
      }

      &.ant-switch-small {
        height: 16px;
        min-width: 32px;

        & .ant-switch-handle {
          width: 16px;
          height: 16px;
        }

        &.ant-switch-checked {
          & .ant-switch-handle {
            left: calc(100% - 18px + 3px);
          }
        }
      }
    }

    & .ant-switch-handle {
      left: -1px;
      top: -1px;
      width: 24px;
      height: 24px;

      &:before {
        background-color: ${switchHeadBgColor};
        border-radius: 12px;
      }
    }

    &.ant-switch-checked {
      background-color: ${switchActiveBgColor};
      border-color: ${switchActiveBgColor};
      --antd-wave-shadow-color: ${switchActiveBgColor};

      &:hover {
        border-color: ${switchActiveBorderColor};
      }

      & .ant-switch-handle {
        left: calc(100% - 18px - 5px);

        &:before {
          background-color: ${switchActiveHeadBgColor};
        }
      }
    }
  `}
`;
