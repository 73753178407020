import { createAction } from 'typesafe-actions';

import { TLogotype } from 'types';

import { ACTIONS } from './constants';

export const putBackgroundToStoreAction = createAction(ACTIONS.PUT_BACKGROUND_IMAGE_TO_STORE)<File>();
export const putBackgroundVideoToStoreAction = createAction(ACTIONS.PUT_BACKGROUND_VIDEO_TO_STORE)<File>();
export const removeBackgroundToStoreAction = createAction(ACTIONS.REMOVE_BACKGROUND_TO_STORE)();

export const putFrontImageToStoreAction = createAction(ACTIONS.PUT_FRONT_IMAGE_TO_STORE)<File>();
export const putFrontVideoToStoreAction = createAction(ACTIONS.PUT_FRONT_VIDEO_TO_STORE)<File>();
export const removeFrontImageToStoreAction = createAction(ACTIONS.REMOVE_FRONT_TO_STORE)();

export const putLogotypeImageToStoreAction = createAction(ACTIONS.PUT_LOGOTYPE_IMAGE_TO_STORE)<File>();
export const removeLogotypeImageToStoreAction = createAction(ACTIONS.REMOVE_LOGOTYPE_IMAGE_TO_STORE)();
export const addLogotypeItemToStoreAction = createAction(ACTIONS.ADD_LOGOTYPE_ITEM_TO_STORE)<TLogotype>();
export const removeLogotypeItemToStoreAction = createAction(ACTIONS.REMOVE_LOGOTYPE_ITEM_TO_STORE)<number>();

export const putInputMaskImageToStoreAction = createAction(ACTIONS.PUT_INPUTMASK_IMAGE_TO_STORE)<{
  image: File;
  index: number;
}>();
