import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import { Button, LoginInput } from 'atoms';
import { LoginFormLayout } from 'molecules';
import { ArrowBack } from 'assets';

import { validationSchema as validate } from './validationSchema';

interface IProps {
  serverValidationError?: string;
  onSubmitForm: (values: IValues) => void;
  onChangeDecorator?: () => void;
}

interface IValues {
  email: string;
}

export const ForgotPasswordForm = ({ serverValidationError, onSubmitForm, onChangeDecorator }: IProps) => {
  return (
    <LoginFormLayout>
      <Heading>Forgot your password?</Heading>

      <Formik initialValues={{ email: '' }} validate={validate} onSubmit={onSubmitForm}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <InputGroup>
              <LoginInput
                id="email"
                name="email"
                label="Email"
                value={values.email}
                placeholder="Email"
                type="email"
                onChange={(e) => {
                  onChangeDecorator && onChangeDecorator();
                  handleChange('email')(e);
                }}
                onBlur={handleBlur('email')}
                error={errors.email && touched.email ? errors.email : ''}
                serverError={serverValidationError}
              />
            </InputGroup>
            <SubmitButton onClick={() => handleSubmit()}>Submit</SubmitButton>
          </Form>
        )}
      </Formik>

      <GoBackToLoginParagraph>
        I remember my password.{' '}
        <GoBackToLogin to="/login">
          Log in <ArrowBack style={{ marginLeft: '4px' }} />
        </GoBackToLogin>
      </GoBackToLoginParagraph>
    </LoginFormLayout>
  );
};

const Form = styled.form``;

const Heading = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.loginHeadingTextColor};
  margin-bottom: 48px;
  margin-top: 28px;
`;

const InputGroup = styled.div`
  margin-bottom: 8px;
`;

const GoBackToLoginParagraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.loginSubHeadingTextColor};
  margin-top: 24px;
  margin-bottom: 56px;
  width: 100%;
`;

const GoBackToLogin = styled(Link)`
  ${({ theme: { loginGoBackButtonColor } }) => css`
    display: inline-flex;
    align-items: center;
    color: ${loginGoBackButtonColor};
    font-size: 16px;
    line-height: 24px;

    &:hover {
      color: ${loginGoBackButtonColor};
    }

    &:focus {
      outline: 0.5px dashed #fff;
    }

    &:active {
      outline: none;
    }
  `}
`;

const SubmitButton = styled(Button)`
  width: 432px;
`;
