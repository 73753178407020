import React from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SearchSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.70711 16.7071C10.0976 16.3166 10.0976 15.6834 9.70711 15.2929C9.31658 14.9024 8.68342 14.9024 8.29289 15.2929L4.29289 19.2929C3.90237 19.6834 3.90237 20.3166 4.29289 20.7071C4.68342 21.0976 5.31658 21.0976 5.70711 20.7071L9.70711 16.7071Z"
      fill="currentColor"
    />
    <path
      d="M13 16C10.2386 16 8 13.7614 8 11C8 8.23858 10.2386 6 13 6C15.7614 6 18 8.23858 18 11C18 13.7614 15.7614 16 13 16ZM13 18C16.866 18 20 14.866 20 11C20 7.13401 16.866 4 13 4C9.13401 4 6 7.13401 6 11C6 14.866 9.13401 18 13 18Z"
      fill="currentColor"
    />
  </svg>
);

export const SearchIcon = ({ style }: IconComponentProps) => <Icon component={SearchSvg} style={style} />;
