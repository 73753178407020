import React from 'react';
import Icon from '@ant-design/icons';

const CheckedSvg = () => (
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66667 5.33366L5 8.66699L12 1.66699"
      stroke="#FF7C29"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckedIcon = () => <Icon component={CheckedSvg} />;
