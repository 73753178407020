const formatStringToCamelCase = (str: string) => {
  const splitted = str.split('-');

  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('')
  );
};

export const getStyleObjectFromString = (str: string) => {
  const style: { [key: string]: string } = {};

  str.split(';').forEach((el) => {
    const [property, value] = el.split(':');
    if (!property) return;

    const formattedProperty: string = formatStringToCamelCase(property.trim());

    style[formattedProperty] = value.trim();
  });

  return style;
};

export const getTranslateValues = (el: SVGElement | HTMLElement) => {
  const style = window.getComputedStyle(el);
  const matrix = style.transform || style.webkitTransform;

  // No transform property. Simply return 0 values.
  if (matrix === 'none') {
    return {
      x: 0,
      y: 0,
      angle: 0,
    };
  }

  // Can either be 2d or 3d transform
  const matrixType = matrix.includes('3d') ? '3d' : '2d';
  // @ts-ignore
  const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');

  // 2d matrices have 6 values
  // Last 2 values are X and Y.
  // 2d matrices does not have Z value.
  if (matrixType === '2d') {
    return {
      x: matrixValues[4],
      y: matrixValues[5],
      angle: Math.round(Math.atan2(Number(matrixValues[1]), Number(matrixValues[0])) * (180 / Math.PI)),
    };
  }

  // 3d matrices have 16 values
  // The 13th, 14th, and 15th values are X, Y, and Z
  if (matrixType === '3d') {
    return {
      x: matrixValues[12],
      y: matrixValues[13],
      angle: Math.round(Math.atan2(Number(matrixValues[1]), Number(matrixValues[0])) * (180 / Math.PI)),
    };
  }

  return {
    x: 0,
    y: 0,
    angle: 0,
  };
};
