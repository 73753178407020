import { SagaIterator } from 'redux-saga';
import { ActionType } from 'typesafe-actions';
import { takeEvery, call, all, put, select } from 'redux-saga/effects';

import { getStreamsPagination, getStream } from 'core/selectors';
import { convertStreamToApiSchema, convertStreamsToAppSchema, convertStreamToAppSchema } from 'services/apiMappers';
import { fileService, streamsService, instanceStarterService } from 'services';
import {
  getStreamsRequestAction,
  getStreamsSuccessAction,
  getStreamsFailureAction,
  putStreamsToStoreAction,
  getStreamRequestAction,
  getStreamSuccessAction,
  getStreamFailureAction,
  putStreamToStoreAction,
  updateStreamRequestAction,
  updateStreamSuccessAction,
  updateStreamFailureAction,
  createStreamRequestAction,
  createStreamSuccessAction,
  createStreamFailureAction,
  deleteStreamRequestAction,
  deleteStreamSuccessAction,
  deleteStreamFailureAction,
  getInstanceIdRequestAction,
  getInstanceIdSuccessAction,
  getInstanceIdFailureAction,
  putInstanceIdToStoreAction,
  updateStreamWithInstanceIdRequestAction,
  updateStreamWithInstanceIdSuccessAction,
  updateStreamWithInstanceIdFailureAction,
  createStreamWithInstanceIdRequestAction,
  createStreamWithInstanceIdSuccessAction,
  createStreamWithInstanceIdFailureAction,
  updateStreamsPaginationAction,
  getActiveStreamsNumberRequestAction,
  getActiveStreamsNumberSuccessAction,
  getActiveStreamsNumberFailureAction,
  putActiveStreamsNumberToStoreAction,
  putSearchStrToStoreAction,
} from 'core/actions';
import { TLogotype, TMask } from 'types';

import { StreamStatus } from '../../constants/statuses';

export function* getStreamsSaga({
  payload: { limit, offset, searchStr, isActiveFilter, statusFilter },
}: ActionType<typeof getStreamsRequestAction>): SagaIterator {
  try {
    const {
      data: { results, total },
    } = yield call(() => streamsService.getSteams(limit, offset, searchStr, isActiveFilter, statusFilter));
    const streamsList = convertStreamsToAppSchema(results);

    yield put(
      putStreamsToStoreAction({
        streamsList,
        total,
        offset,
      }),
    );
    yield put(getStreamsSuccessAction());
  } catch (error) {
    yield put(getStreamsFailureAction());
  }
}

export function* getActiveStreamsNumberSaga(): SagaIterator {
  try {
    const {
      data: { total },
    } = yield call(() => streamsService.getSteams(1, 1, '', true, StreamStatus.Ready));

    yield put(putActiveStreamsNumberToStoreAction(Number(total)));
    yield put(getActiveStreamsNumberSuccessAction());
  } catch (error) {
    yield put(getActiveStreamsNumberFailureAction());
  }
}

export function* getStreamSaga({ payload: { streamId } }: ActionType<typeof getStreamRequestAction>): SagaIterator {
  try {
    const { data } = yield call(() => streamsService.getSteam(streamId));
    const stream = convertStreamToAppSchema(data);

    yield put(
      putStreamToStoreAction({
        stream,
      }),
    );
    yield put(getStreamSuccessAction());
  } catch (error) {
    yield put(getStreamFailureAction());
  }
}

function* uploadStreamImages(): any {
  const stream = yield select(getStream);

  // Logotypes image
  const {
    canvas: { logotypes },
  } = stream;

  const logotypeImageFile = logotypes.length && logotypes[0].file ? logotypes[0].file : null;

  if (logotypeImageFile) {
    const {
      data: { file_url },
    } = yield call(() => fileService.getFileUrl(logotypeImageFile));
    // eslint-disable-next-line no-return-assign
    stream.canvas.logotypes.forEach((logotype: TLogotype) => (logotype.fileUrl = file_url));
  }

  // Masks images
  const {
    canvas: { masks },
  } = stream;

  const masksUrlCollection = yield all(
    // eslint-disable-next-line
      masks.map((mask: TMask) => {
      if (mask.file) {
        return call(uploadSingleFile, mask.file);
      }
    }),
  );

  // eslint-disable-next-line no-return-assign
  stream.canvas.masks.forEach((mask: TMask, index: number) => {
    if (masksUrlCollection[index]) {
      mask.fileUrl = masksUrlCollection[index];
    }
  });

  // Background image
  if (stream.canvas.backgroundImage) {
    stream.canvas.backgroundImageUrl = yield call(uploadSingleFile, stream.canvas.backgroundImage);
  }

  // Background video
  if (stream.canvas.backgroundVideo) {
    stream.canvas.backgroundVideoUrl = yield call(uploadSingleFile, stream.canvas.backgroundVideo);
  }

  // Front image
  if (stream.canvas.frontgroundImage) {
    stream.canvas.frontgroundImageUrl = yield call(uploadSingleFile, stream.canvas.frontgroundImage);
  }

  // Front video
  if (stream.canvas.frontgroundVideo) {
    stream.canvas.frontgroundVideoUrl = yield call(uploadSingleFile, stream.canvas.frontgroundVideo);
  }

  return stream;
}

export function* updateStreamSaga({
  payload: { streamId },
}: ActionType<typeof updateStreamRequestAction>): SagaIterator {
  try {
    const stream = yield uploadStreamImages();

    const apiStream = convertStreamToApiSchema(stream);
    yield call(() => streamsService.saveSteam(streamId, apiStream));
    yield put(updateStreamSuccessAction());
  } catch (error) {
    yield put(updateStreamFailureAction());
  }
}

export function* updateStreamWithInstanceIdSaga({
  payload: { streamId },
}: ActionType<typeof updateStreamWithInstanceIdRequestAction>): SagaIterator {
  try {
    const stream = yield uploadStreamImages();

    const {
      data: {
        Instances: [{ InstanceId }],
      },
    } = yield call(() => instanceStarterService.createInstance());

    stream.instanceId = InstanceId;
    const apiStream = convertStreamToApiSchema(stream);

    yield call(() => streamsService.saveSteam(streamId, apiStream));
    yield put(updateStreamWithInstanceIdSuccessAction());
  } catch (error) {
    yield put(updateStreamWithInstanceIdFailureAction());
  }
}

export function* createStreamSaga(): SagaIterator {
  try {
    const stream = yield uploadStreamImages();

    const apiStream = convertStreamToApiSchema(stream);
    yield call(() => streamsService.createSteam(apiStream));
    yield put(createStreamSuccessAction());
  } catch (error) {
    yield put(createStreamFailureAction());
  }
}

export function* createStreamWithInstanceIdSaga(): SagaIterator {
  try {
    const stream = yield uploadStreamImages();

    const {
      data: {
        Instances: [{ InstanceId }],
      },
    } = yield call(() => instanceStarterService.createInstance());

    stream.instanceId = InstanceId;
    const apiStream = convertStreamToApiSchema(stream);

    yield call(() => streamsService.createSteam(apiStream));
    yield put(createStreamWithInstanceIdSuccessAction());
  } catch (error) {
    yield put(createStreamWithInstanceIdFailureAction());
  }
}

export function* deleteStreamSaga({
  payload: { streamId },
}: ActionType<typeof deleteStreamRequestAction>): SagaIterator {
  try {
    const { limit, offset } = yield select(getStreamsPagination);

    yield call(() => streamsService.deleteStream(streamId));
    yield put(getStreamsRequestAction({ limit, offset }));
    yield put(deleteStreamSuccessAction());
  } catch (error) {
    yield put(deleteStreamFailureAction());
  }
}

function* uploadSingleFile(file: File) {
  let url;
  try {
    const {
      data: { file_url },
    } = yield call(() => fileService.getFileUrl(file));
    url = file_url;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Fail to upload file');
  }
  return url;
}

export function* getInstanceIdSaga(): SagaIterator {
  try {
    const {
      data: {
        Instances: [{ InstanceId }],
      },
    } = yield call(() => instanceStarterService.createInstance());

    yield put(getInstanceIdSuccessAction());
    yield put(putInstanceIdToStoreAction(InstanceId));
  } catch (error) {
    yield put(getInstanceIdFailureAction());
  }
}

export function* updateStreamsPaginationSaga({
  payload: { limit, offset },
}: ActionType<typeof updateStreamsPaginationAction>): SagaIterator {
  yield put(getStreamsRequestAction({ offset, limit }));
}

export function* sSaga({ payload: value }: ActionType<typeof putSearchStrToStoreAction>): SagaIterator {
  yield put(getStreamsRequestAction({ searchStr: value, offset: 1, limit: 8 }));
}

export function* streamsRootSaga(): SagaIterator {
  yield takeEvery(getStreamsRequestAction, getStreamsSaga);
  yield takeEvery(getActiveStreamsNumberRequestAction, getActiveStreamsNumberSaga);
  yield takeEvery(getStreamRequestAction, getStreamSaga);
  yield takeEvery(updateStreamRequestAction, updateStreamSaga);
  yield takeEvery(createStreamRequestAction, createStreamSaga);
  yield takeEvery(deleteStreamRequestAction, deleteStreamSaga);
  yield takeEvery(getInstanceIdRequestAction, getInstanceIdSaga);
  yield takeEvery(updateStreamWithInstanceIdRequestAction, updateStreamWithInstanceIdSaga);
  yield takeEvery(createStreamWithInstanceIdRequestAction, createStreamWithInstanceIdSaga);
  // yield takeEvery(updateStreamsPaginationAction, updateStreamsPaginationSaga);
  // yield takeEvery(putSearchStrToStoreAction, sSaga);
}
