import React from 'react';
import styled, { css } from 'styled-components';

import { InputSubText } from 'atoms';

interface IProps {
  id?: string;
  name?: string;
  label?: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  multiline?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const TextInput = ({
  id,
  name,
  label,
  onChange,
  onBlur,
  error = '',
  disabled = false,
  value = '',
  multiline = false,
  ...props
}: IProps) => {
  return (
    <Container {...props}>
      {multiline ? (
        <MultiLineInputField
          id={id}
          name={name}
          value={value}
          error={error}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          rows={3}
          required
          spellCheck={false}
        />
      ) : (
        <InputField
          id={id}
          name={name}
          value={value}
          error={error}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          pattern=".*"
          required
          spellCheck={false}
          max="30"
        />
      )}
      <Label error={error} value={value}>
        {label}
      </Label>
      <InputSubText text={error || ''} error type="default" />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

interface IInput {
  error: string;
  value: string;
}

const InputField = styled.input<IInput>`
  ${({ error, theme: { baseInputTextColor, baseInputErrorColor, baseInputBorderColor } }) => css`
    width: 100%;
    padding: 16px;
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${baseInputTextColor};
    border: 1px solid ${error ? baseInputErrorColor : baseInputBorderColor};
    border-radius: 4px;
  `}

  &:hover {
    border: 1px solid ${({ theme }) => theme.baseInputHoverColor};
  }

  &:focus {
    ${({ error, theme: { baseInputTextColor, baseInputErrorColor, baseInputActiveColor } }) => css`
      color: ${baseInputTextColor};
      border: 1px solid ${error ? baseInputErrorColor : baseInputActiveColor};
      outline: none;
    `}
  }

  &:focus + span {
    ${({ error, theme: { baseInputErrorColor, baseInputActiveColor, baseInputLabelBgColor } }) => css`
      color: ${error ? baseInputErrorColor : baseInputActiveColor};
      opacity: 1;
      top: -8px;
      padding: 0 4px;
      background-color: ${baseInputLabelBgColor};
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    `}
  }

  &:focus + span,
  &:invalid + span,
  &:valid + span {
    transition: color 0.2s ease;
    transition: opacity 0.2s ease;
    transition: top 0.2s ease;
    transition: padding 0.2s ease;
    transition: background-color 0.2s ease;
    transition: font-weight 0.2s ease;
    transition: font-size 0.2s ease;
    transition: line-height 0.2s ease;
  }

  &:valid + span,
  &:disabled + span {
    color: ${({ theme }) => theme.baseInputTextColor};
    opacity: 1;
    top: -8px;
    padding: 0 4px;
    background-color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.baseInputDisabledColor};
    /* color: ${({ theme }) => theme.baseInputDisabledColor}; */
  }
`;

const MultiLineInputField = styled.textarea<IInput>`
  ${({ error, theme: { baseInputTextColor, baseInputErrorColor, baseInputBorderColor } }) => css`
    width: 100%;
    padding: 16px;
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${baseInputTextColor};
    border: 1px solid ${error ? baseInputErrorColor : baseInputBorderColor};
    border-radius: 4px;
  `}

  &:hover {
    border: 1px solid ${({ theme }) => theme.baseInputHoverColor};
  }

  &:focus {
    ${({ error, theme: { baseInputTextColor, baseInputErrorColor, baseInputActiveColor } }) => css`
      color: ${baseInputTextColor};
      border: 1px solid ${error ? baseInputErrorColor : baseInputActiveColor};
      outline: none;
    `}
  }

  &:focus + span {
    ${({ error, theme: { baseInputErrorColor, baseInputActiveColor, baseInputLabelBgColor } }) => css`
      color: ${error ? baseInputErrorColor : baseInputActiveColor};
      opacity: 1;
      top: -8px;
      padding: 0 4px;
      background-color: ${baseInputLabelBgColor};
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    `}
  }

  &:valid + span {
    color: ${({ theme }) => theme.baseInputTextColor};
    opacity: 1;
    top: -8px;
    padding: 0 4px;
    background-color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &:focus + span,
  &:invalid + span,
  &:valid + span {
    transition: color 0.2s ease;
    transition: opacity 0.2s ease;
    transition: top 0.2s ease;
    transition: padding 0.2s ease;
    transition: background-color 0.2s ease;
    transition: font-weight 0.2s ease;
    transition: font-size 0.2s ease;
    transition: line-height 0.2s ease;
  }

  &::disabled {
    border: 1px solid ${({ theme }) => theme.baseInputDisabledColor};
    color: ${({ theme }) => theme.baseInputDisabledColor};
  }
`;

const Label = styled.span<IInput>`
  position: absolute;
  z-index: 100;
  top: 16px;
  left: 16px;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.baseInputLabelTextColor};
  opacity: 1;
  pointer-events: none;

  ${({ error, value, theme }) =>
    error &&
    value &&
    css`
      color: ${theme.baseInputErrorColor};
      opacity: 1;
      top: -8px;
      padding: 0 4px;
      background-color: ${theme.baseInputLabelBgColor};
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;

      transition: color 0.2s ease;
      transition: opacity 0.2s ease;
      transition: top 0.2s ease;
      transition: padding 0.2s ease;
      transition: background-color 0.2s ease;
      transition: font-weight 0.2s ease;
      transition: font-size 0.2s ease;
      transition: line-height 0.2s ease;
    `};
`;
