import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { ArrowUpOutlined, EditOutlined } from '@ant-design/icons';
import { Radio } from 'antd';

import { TrashIcon } from 'assets/icons/TrashIcon';
import canvasBg from 'assets/images/canvasBg.png';
import { Button } from 'atoms';

interface IProps {
  caption?: string;
  onChange?(image: FileList): void;
  onRemove?(): void;
  fileUrl?: string;
}

export const UploadBox = ({ caption = '', onChange, onRemove, fileUrl, ...props }: IProps) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const openFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.currentTarget.files!);
    }
    inputFile!.current!.value = '';
  };

  const onEditButtonClick = () => {
    inputFile.current!.click();
  };

  return (
    <Container {...props}>
      <Caption>{caption}</Caption>
      <PreviewArea backgroungImage={canvasBg} image={fileUrl}>
        {fileUrl && !onRemove ? (
          <EditButton icon={<EditOutlined />} onClick={onEditButtonClick}>
            Edit
          </EditButton>
        ) : null}
        {fileUrl && onRemove ? (
          <ButtonsGroup>
            <EditButton icon={<EditOutlined />} onClick={onEditButtonClick}>
              Edit
            </EditButton>
            <EditButton icon={<TrashIcon />} onClick={onRemove}>
              Delete
            </EditButton>
          </ButtonsGroup>
        ) : null}
        {!fileUrl ? (
          <UploadButton icon={<ArrowUpOutlined />} onClick={onEditButtonClick}>
            Upload
          </UploadButton>
        ) : null}
      </PreviewArea>

      <input
        type="file"
        id="file"
        accept="image/png, image/jpeg"
        ref={inputFile}
        onChange={openFileHandler}
        style={{ display: 'none' }}
      />
    </Container>
  );
};

const Container = styled.div`
  & {
    margin: 0 0 10px 0;
  }
`;

const Caption = styled.div`
  ${({ theme: { uploadBoxTextColor } }) => css`
     {
      color: ${uploadBoxTextColor};
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin: 0 0 5px 0;
    }
  `}
`;

interface IPreviewAreaProps {
  backgroungImage: string;
  image: string | undefined;
}

const PreviewArea = styled.div<IPreviewAreaProps>`
  ${({ theme: { uploadBoxPreviewAreaBorderColor, streamCanvasBgColor }, backgroungImage, image = '' }) => css`
     {
      background: url(${backgroungImage}) 0 0 repeat;
      background-color: ${streamCanvasBgColor};
      width: 248px;
      height: 208px;
      line-height: 208px;
      text-align: center;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid ${uploadBoxPreviewAreaBorderColor};
      position: relative;

      &:before {
        display: block;
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: url(${image}) 50% 50% no-repeat;
        background-size: contain;
      }
    }
  `}
`;

const UploadButton = styled(Button)`
   {
    padding: 16px 15px;
    height: auto;
    position: absolute;
    top: 65px;
    left: 88px;

    && .anticon {
      display: block;
      font-size: 20px;
    }

    && span {
      margin-right: 0;
      margin-left: 0;
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

const EditButton = styled(Button)`
  && {
    padding: 16px 15px;
    height: auto;
    position: absolute;
    top: 65px;
    left: 88px;
    background: transparent;
    border: 1px solid #ffffff;
    width: 72px;

    && .anticon {
      display: block;
      font-size: 20px;
    }

    && span {
      margin-right: 0;
      margin-left: 0;
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

const ButtonsGroup = styled(Radio.Group)`
  & {
    position: absolute;
    top: 0;
    left: 0;
    height: 208px;
    width: 100%;
    line-height: 205px;
    text-align: center;

    .ant-btn-primary {
      height: 82px;
      position: static;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`;
