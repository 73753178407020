import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import styled, { css } from 'styled-components';

export type IBtnType = 'primary' | 'default';

interface IRadioItem {
  title: string;
  value: string;
  disabled: boolean;
}

interface IProps {
  radioItems: Array<IRadioItem>;
  onChange?(e: RadioChangeEvent): void;
  value?: number | string;
}

export const RadioGroup = ({ radioItems, onChange, value }: IProps) => (
  <RadioContainer onChange={onChange} value={value}>
    {radioItems.map(({ value: itemValue, disabled, title }) => {
      return (
        <Radio key={itemValue} disabled={disabled} value={itemValue}>
          {title}
        </Radio>
      );
    })}
  </RadioContainer>
);

const RadioContainer = styled(Radio.Group)`
  ${({
    theme: {
      radioGroupItemBorderColor,
      radioGroupItemActiveBorderColor,
      radioGroupItemActiveBgColor,
      radioGroupItemTitleColor,
    },
  }) => css`
    display: inline-flex;
    width: 100%;

    & .ant-radio-wrapper {
      font-family: 'Avenir Next Cyr';
      font-size: 16px;
      font-weight: 500;
      color: ${radioGroupItemTitleColor};
      width: 100%;

      &:last-child {
        margin-right: 0;
      }
    }

    & .ant-radio {
      vertical-align: top;

      &:hover .ant-radio-inner {
        border-color: ${radioGroupItemActiveBorderColor};
      }
    }

    & span.ant-radio + * {
      padding-right: 0;
      padding-left: 16px;
    }

    & .ant-radio-inner {
      width: 24px;
      height: 24px;

      &:after {
        top: 5px;
        left: 5px;
        width: 12px;
        height: 12px;
      }
    }

    & .ant-radio-inner {
      border-color: ${radioGroupItemBorderColor};
    }

    & .ant-radio-checked .ant-radio-inner {
      border-color: ${radioGroupItemActiveBorderColor};

      &:after {
        background-color: ${radioGroupItemActiveBgColor};
      }
    }

    & .ant-radio-disabled .ant-radio-inner {
      background-color: transparent;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: ${radioGroupItemActiveBorderColor};
    }
  `}
`;
