import { ActionType, createReducer } from 'typesafe-actions';

import { fetchUserSuccessAction } from 'core/actions';

export interface IUserState {
  id: number;
  email: string;
  username: string;
}

const defaultState: IUserState = {
  id: 0,
  email: '',
  username: '',
};

const actions = {
  fetchUserSuccessAction,
};

export const userReducer = createReducer<IUserState, ActionType<typeof actions>>(defaultState).handleAction(
  fetchUserSuccessAction,
  (state, { payload }) => ({
    ...state,
    ...payload,
  }),
);
