import React from 'react';
import { Layout, Menu } from 'antd';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

// import { UserAvatar } from 'atoms';
import { DashboardIcon, SettingsIcon, StreamIcon, ExitIcon } from 'assets';

const { Sider } = Layout;

interface IProps {
  location: string;
  collapsed: boolean;
  logOut: () => void;
  dashboardMenuItemClickHandler?(): void;
}

export const AppMenu = ({ collapsed, location, logOut, dashboardMenuItemClickHandler }: IProps) => {
  return (
    <Sider width="256" collapsed={collapsed}>
      <AppMenuContainer defaultSelectedKeys={[location]} mode="inline">
        <Menu.Item key="/dashboard" icon={<DashboardIcon />}>
          <NavLink onClick={() => dashboardMenuItemClickHandler?.()} to="/dashboard">
            Dashboard
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/settings" icon={<SettingsIcon />}>
          <NavLink to="/settings">Settings</NavLink>
        </Menu.Item>
        <Menu.Item key="/create-stream" icon={<StreamIcon />}>
          <NavLink to="/create-stream">Add new stream</NavLink>
        </Menu.Item>

        {/* <UserContainer key="4"> */}
        {/*  <UserAvatar text="JM" /> */}
        {/*  <span className="user-name">Johnny Morant</span> */}
        {/* </UserContainer> */}
        <LogOutContainer key="5" icon={<ExitIcon />} onClick={logOut}>
          Log out
        </LogOutContainer>
      </AppMenuContainer>
    </Sider>
  );
};

const AppMenuContainer = styled(Menu)`
  ${({ theme: { appMenuBgColor, appMenuTextColor, appMenuActiveTextColor } }) => css`
    & {
      font-family: 'Avenir Next Cyr';
      background-color: ${appMenuBgColor};
      font-weight: 500;
      font-size: 16px;
      height: 100%;
      max-width: 256px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-item {
        padding: 0 10px;
        text-align: center;

        .user-name {
          display: none;
        }
      }
    }

    & .ant-menu-item {
      height: 56px;
      line-height: 56px;
      color: ${appMenuTextColor};

      a {
        color: ${appMenuTextColor};
      }

      &:hover {
        color: ${appMenuActiveTextColor};

        a {
          color: ${appMenuActiveTextColor};
        }
      }

      & .anticon {
        vertical-align: sub;
      }

      .user-name {
        margin: 0 0 0 16px;
      }

      &.ant-menu-item-selected {
        background-color: ${appMenuBgColor};
        color: ${appMenuActiveTextColor};
        width: calc(100%);

        a {
          color: ${appMenuActiveTextColor};
        }

        &:after {
          left: 0;
          top: 0;
          bottom: 0;
          width: 3px;
          border: none;
          background-color: ${appMenuActiveTextColor};
        }
      }
    }
  `}
`;

// const UserContainer = styled(Menu.Item)`
//   && {
//     border-top: 1px solid #40414a;
//     margin-top: auto;
//   }
// `;

const LogOutContainer = styled(Menu.Item)`
  && {
    border-top: 1px solid #40414a;
    margin-top: auto;
  }
`;
