import { all } from 'redux-saga/effects';

import {
  initRootSaga,
  authRootSaga,
  streamsRootSaga,
  inputsStreamRootSaga,
  starterRootSaga,
  receiverRootSaga,
} from 'core/sagas';

export function* rootSaga() {
  try {
    yield all([
      initRootSaga(),
      authRootSaga(),
      streamsRootSaga(),
      inputsStreamRootSaga(),
      starterRootSaga(),
      receiverRootSaga(),
    ]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
