import { createAction } from 'typesafe-actions';

import { ACTIONS } from './constants';

interface ILoginRequestPayload {
  email: string;
  password: string;
}

interface IResetPasswordRequestPayload {
  email: string;
}

interface IConfirmNewPasswordRequestPayload {
  uid: string;
  token: string;
  newPassword: string;
  repeatPassword: string;
}

interface IError {
  authServerError: string;
}

export const initAppAction = createAction(ACTIONS.INIT_APP)();
export const initAppSuccessAction = createAction(ACTIONS.INIT_APP_SUCCESS)();
export const initAppFailureAction = createAction(ACTIONS.INIT_APP_FAILURE)();

export const logInRequestAction = createAction(ACTIONS.LOG_IN_REQUEST)<ILoginRequestPayload>();
export const logInSuccessAction = createAction(ACTIONS.LOG_IN_SUCCESS)();
export const logInFailureAction = createAction(ACTIONS.LOG_IN_FAILURE)<IError>();

export const resetPasswordRequestAction = createAction(ACTIONS.RESET_PASSWORD_REQUEST)<IResetPasswordRequestPayload>();
export const resetPasswordSuccessAction = createAction(ACTIONS.RESET_PASSWORD_SUCCESS)();
export const resetPasswordFailureAction = createAction(ACTIONS.RESET_PASSWORD_FAILURE)<IError>();

export const logOutAction = createAction(ACTIONS.LOG_OUT)();
export const logOutSuccessAction = createAction(ACTIONS.LOG_OUT_SUCCESS)();
export const logOutFailureAction = createAction(ACTIONS.LOG_OUT_FAILURE)();

export const resetServerAuthErrorAction = createAction(ACTIONS.RESET_SERVER_AUTH_ERROR)();
export const putUserAuthStatusAction = createAction(ACTIONS.PUT_USER_AUTH_STATUS)();
export const startAuthLoadingAction = createAction(ACTIONS.START_AUTH_LOADING)();
export const endAuthLoadingAction = createAction(ACTIONS.END_AUTH_LOADING)();

export const confirmNewPasswordRequestAction = createAction(ACTIONS.CONFIRM_NEW_PASSWORD_REQUEST)<
  IConfirmNewPasswordRequestPayload
>();
export const confirmNewPasswordSuccessAction = createAction(ACTIONS.CONFIRM_NEW_PASSWORD_SUCCESS)();
export const confirmNewPasswordFailureAction = createAction(ACTIONS.CONFIRM_NEW_PASSWORD_FAILURE)<IError>();
