import React from 'react';
import { Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PageTitle, Button, SearchInput, FilterDropDown } from 'atoms';
import { TStreamStatus } from 'types';
import { ReceiverStatus } from 'constants/statuses';

export interface IProps {
  streamsAmount?: number;
  isActiveFilter?: boolean | null;
  statusFilter?: TStreamStatus | null;
  searchInputChangeHandler(value: string): void;
  changeIsActiveFilterhandler?(value: boolean | null): void;
  changeStatusFilterhandler?(value: TStreamStatus | null): void;
  receiverStatus?: string;
  toggleReceiver: () => void;
}

export const DashboardHeader = ({
  streamsAmount = 0,
  isActiveFilter,
  statusFilter,
  searchInputChangeHandler,
  changeIsActiveFilterhandler,
  changeStatusFilterhandler,
  receiverStatus,
  toggleReceiver,
}: IProps) => {
  const isReceiverRunning = receiverStatus === ReceiverStatus.Running;

  return (
    <>
      <PanelRow>
        <Col>
          <PanelButtonsContainer>
            <ToggleReceiverButton
              onClick={toggleReceiver}
              size="small"
              style={{ background: isReceiverRunning ? 'red' : 'green' }}
            >
              {isReceiverRunning ? 'Stop Receiver' : 'Start Receiver'}{' '}
            </ToggleReceiverButton>
          </PanelButtonsContainer>
        </Col>
      </PanelRow>
      <Row gutter={[0, 32]}>
        <Col flex="0 1 210px">
          <PageTitle>Active streams: {String(streamsAmount)}</PageTitle>
        </Col>

        <Col flex="1 1 auto">
          <Row gutter={[0, { xs: 32, sm: 32, lg: 0 }]}>
            <Col flex="1 1 auto">
              <SearchInput placeholder="Search..." onEnterDown={searchInputChangeHandler} />
            </Col>
            <Col flex="0 1 313px">
              <ButtonsContainer>
                <FilterDropDown
                  buttonName="Filters"
                  isActiveFilter={isActiveFilter}
                  statusFilter={statusFilter}
                  changeIsActiveFilterhandler={changeIsActiveFilterhandler}
                  changeStatusFilterhandler={changeStatusFilterhandler}
                />

                <Link to="/create-stream">
                  <NewStreamButton size="small" icon={<PlusOutlined />}>
                    Add new stream
                  </NewStreamButton>
                </Link>
              </ButtonsContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const ButtonsContainer = styled.div`
  text-align: right;
`;

const NewStreamButton = styled(Button)`
  margin-left: 20px;
`;

const PanelButtonsContainer = styled.div`
  text-align: right;
`;

const PanelRow = styled(Row)`
  display: block;
  margin-right: 0;
  padding-bottom: 40px;
`;

const ToggleReceiverButton = styled(Button)`
  margin-left: 20px;
`;
