import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  title: string;
  children: ReactNode;
}

export const FormSection = ({ title, children }: IProps) => {
  return (
    <Container>
      <FormSectionTitle>{title}</FormSectionTitle>
      <InnnerContent>{children}</InnnerContent>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 32px;
  border-bottom: 1px solid ${({ theme }) => theme.formSectionBorder};
`;

const FormSectionTitle = styled.h3`
  margin: 0;
  padding: 25px 0 24px 0;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.formTitleTextColor};
`;

const InnnerContent = styled.div``;
