import styled from 'styled-components';
import Moveable from 'react-moveable';

export const MoveableContainer = styled(Moveable)`
  && {
    --moveable-color: #4fff56;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
  }

  && .moveable-control {
    background: #000;
    border: 2px solid #4fff56;
  }

  && .moveable-rotation .moveable-control {
    background: #000;
    border: 2px solid #4fff56;
  }

  && .moveable-origin {
    display: none;
  }
`;
