import React from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';

import { Switch, TextInput } from 'atoms';
import { FormSection } from 'molecules';
import { putStreamFormValueToStoreAction } from 'core';

interface IProps {
  isAudioEnabled?: boolean;
  audioUrl?: string;
  error?: string;
}

export const AudioSettingsBlock = ({ isAudioEnabled = false, audioUrl, error }: IProps) => {
  const dispatch = useDispatch();

  const onSwichChange = (checked: boolean) => {
    dispatch(
      putStreamFormValueToStoreAction({
        name: 'isAudioEnabled',
        value: checked,
      }),
    );
  };

  const audioUrlChangeHandler = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    dispatch(
      putStreamFormValueToStoreAction({
        name: target.name,
        value: target.value,
      }),
    );
  };

  return (
    <FormSection title="Audio Settings">
      <Row gutter={[32, 32]}>
        <Col xs={24} md={12} xl={6}>
          <Switch checked={isAudioEnabled} offLabel="Off" onLabel="On" onChange={onSwichChange} />
        </Col>
        <Col xs={24} md={12} xl={10}>
          <span>Please, paste the audio link.</span>
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={16}>
          <TextInput
            id="audioUrl"
            name="audioUrl"
            label="Audio link"
            value={audioUrl}
            multiline
            onChange={audioUrlChangeHandler}
            error={error}
          />
        </Col>
      </Row>
    </FormSection>
  );
};
