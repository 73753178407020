import { BaseService } from './BaseService';
import { checkTokenInterceptor } from './axiosInterceptors';

checkTokenInterceptor();

class InstanceStarterAPIService extends BaseService {
  public async createInstance() {
    return this.post('create', '');
  }

  public async rebootInstance(instanceId: string) {
    return this.post(`reboot/${instanceId}`, '');
  }

  public async startInstance(instanceId: string) {
    return this.post(`start/${instanceId}`, '');
  }

  public async stopInstance(instanceId: string) {
    return this.post(`stop/${instanceId}`, '');
  }

  public async deleteSeat(instanceId: string) {
    return this.remove(`delete/${instanceId}`);
  }
}

export const instanceStarterService = new InstanceStarterAPIService();
