import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put, select } from 'redux-saga/effects';

import { receiverService } from 'services';
import { putReceiverStatusAction, getReceiverStatusAction, toggleReceiverRequestAction } from 'core/actions';
import { getReceiverData } from 'core/selectors';
import { ReceiverStatus } from 'constants/statuses';

export function* getReceiverStatusSaga(): SagaIterator {
  try {
    const { data } = yield call(() => receiverService.getReceiverStatus());

    yield put(putReceiverStatusAction({ receiverStatus: data?.receiver_status }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log({ error });
  }
}

export function* toggleReceiverSaga(): SagaIterator {
  const { receiverStatus } = yield select(getReceiverData);

  try {
    yield call(() => receiverService.toggleReceiver());

    yield put(
      putReceiverStatusAction({
        receiverStatus: receiverStatus === ReceiverStatus.Running ? ReceiverStatus.Stopped : ReceiverStatus.Running,
      }),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    yield put(putReceiverStatusAction({ receiverStatus }));
  }
}

export function* receiverRootSaga(): SagaIterator {
  yield takeEvery(getReceiverStatusAction, getReceiverStatusSaga);
  yield takeEvery(toggleReceiverRequestAction, toggleReceiverSaga);
}
