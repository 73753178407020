import React from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ArrowBackSvg = () => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5H0M11 5L7 1M11 5L7 9" stroke="white" strokeWidth="1.4" strokeLinejoin="round" />
  </svg>
);

export const ArrowBack = ({ style }: IconComponentProps) => <Icon component={ArrowBackSvg} style={style} />;
