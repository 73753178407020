import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ForgotPasswordForm, PageLayout } from 'organisms';
import { useHandleServerError, useOnRequestSuccess } from 'hooks';
import { resetPasswordRequestAction } from 'core/actions';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { authServerError, resetServerError } = useHandleServerError();

  useOnRequestSuccess(resetPasswordRequestAction, () => {
    push('/check-email');
  });

  const onSubmitForm = ({ email }: { email: string }) => {
    dispatch(resetPasswordRequestAction({ email }));
  };

  const onChangeDecorator = () => {
    resetServerError();
  };

  return (
    <PageLayout isHeaderVisible isMenuVisible={false} isAuthPage>
      <LoginFormContainer>
        <ForgotPasswordForm
          onSubmitForm={onSubmitForm}
          onChangeDecorator={onChangeDecorator}
          serverValidationError={authServerError}
        />
      </LoginFormContainer>
    </PageLayout>
  );
};

const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.loginPageBgColor};
`;
