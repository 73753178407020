import { BaseService } from './BaseService';
import { checkTokenInterceptor } from './axiosInterceptors';

checkTokenInterceptor();

class FileAPIService extends BaseService {
  public async getFileUrl(file: File) {
    const fd = new FormData();
    fd.append('file', file!, file!.name);
    return this.post('upload', fd);
  }
}

export const fileService = new FileAPIService();
