interface IValidatedFields {
  email: string;
  password: string;
}

interface IErrors {
  email?: string;
  password?: string;
}

export const validationSchema = (values: IValidatedFields) => {
  const errors: IErrors = {};

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Must be at least 8 characters';
  }

  return errors;
};
