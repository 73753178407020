import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';

import { UserService } from 'services';
import {
  initAppAction,
  initAppSuccessAction,
  initAppFailureAction,
  putUserAuthStatusAction,
  startAuthLoadingAction,
  endAuthLoadingAction,
  fetchUserSuccessAction,
} from 'core/actions';

export function* initAppSaga() {
  try {
    yield put(startAuthLoadingAction());
    const { data } = yield call(() => UserService.fetchUser());

    yield put(putUserAuthStatusAction());
    yield put(fetchUserSuccessAction(data));
    yield put(initAppSuccessAction());
    yield put(endAuthLoadingAction());
  } catch {
    yield put(endAuthLoadingAction());
    yield put(initAppFailureAction());
  }
}

export function* initRootSaga(): SagaIterator {
  yield takeEvery(initAppAction, initAppSaga);
}
