import React from 'react';
import Icon from '@ant-design/icons';

const StreamSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6H4C2.89543 6 2 6.89543 2 8V16C2 17.1046 2.89543 18 4 18H13C14.1046 18 15 17.1046 15 16V8C15 6.89543 14.1046 6 13 6Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0001 8.41422V15.5858C22.0001 16.1381 21.5524 16.5858 21.0001 16.5858C20.7349 16.5858 20.4805 16.4804 20.293 16.2929L16.7072 12.7071C16.3167 12.3166 16.3167 11.6834 16.7072 11.2929L20.293 7.70711C20.6835 7.31658 21.3167 7.31658 21.7072 7.70711C21.8947 7.89465 22.0001 8.149 22.0001 8.41422Z"
      fill="currentColor"
    />
  </svg>
);

export const StreamIcon = () => <Icon component={StreamSvg} />;
