import { IState } from 'core/types';

export const getStreams = ({ streams }: IState) => streams;

export const getActiveStreamsNumber = ({ streams }: IState) => streams.activeStreamsNumber;

export const getStreamsPagination = ({ streams }: IState) => ({
  total: streams.total,
  limit: streams.limit,
  offset: streams.offset,
  isActiveFilter: streams.isActiveFilter,
  statusFilter: streams.statusFilter,
  searchStr: streams.searchStr,
});
