import React from 'react';
import Icon from '@ant-design/icons';

const ExitSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1961 8.99979L17.1961 10.9793L8.13454 10.9793C8.01668 10.9793 7.92025 11.0757 7.92025 11.1935L7.92025 12.8007C7.92025 12.9185 8.01668 13.015 8.13454 13.015L17.1961 13.015L17.1961 14.9998C17.1961 15.1793 17.4024 15.2784 17.5417 15.1685L21.3399 12.1685C21.3655 12.1485 21.3862 12.1229 21.4005 12.0936C21.4147 12.0644 21.4221 12.0323 21.4221 11.9998C21.4221 11.9673 21.4147 11.9352 21.4005 11.9059C21.3862 11.8767 21.3655 11.8511 21.3399 11.831L17.5444 8.83104C17.4024 8.72122 17.1961 8.82033 17.1961 8.99979ZM8.93811 21.8034L8.93811 20.1962C8.93811 20.0784 8.84168 19.9819 8.72382 19.9819L4.59882 19.9819L4.59883 4.01765L8.72383 4.01765C8.84168 4.01765 8.93811 3.92122 8.93811 3.80336L8.93811 2.19622C8.93811 2.07836 8.84168 1.98193 8.72383 1.98193L3.42025 1.98193C2.94615 1.98193 2.56311 2.36497 2.56311 2.83908L2.56311 21.1605C2.56311 21.6346 2.94615 22.0176 3.42025 22.0176L8.72382 22.0176C8.84168 22.0176 8.93811 21.9212 8.93811 21.8034Z"
      fill="currentColor"
    />
  </svg>
);

export const ExitIcon = () => <Icon component={ExitSvg} />;
