import React from 'react';
import ReactDOM from 'react-dom';

import {
  AuthService,
  UserService,
  streamsService,
  fileService,
  instanceStarterService,
  cameraService,
  receiverService,
} from 'services';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

AuthService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
AuthService.prefix = '';
UserService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
UserService.prefix = '';
streamsService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
streamsService.prefix = 'stream/';
fileService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
fileService.prefix = 'file/';
instanceStarterService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
instanceStarterService.prefix = 'instance-starter/';
cameraService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
cameraService.prefix = 'camera/';
receiverService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
receiverService.prefix = 'receiver';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
