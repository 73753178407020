import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import styled, { css } from 'styled-components';

const { Title } = Typography;

interface IProps {
  children: ReactNode;
}

export const PageTitle = ({ children }: IProps) => <PageTitleContainer>{children}</PageTitleContainer>;

const PageTitleContainer = styled(Title)`
  ${({ theme: { pageTitleTextColor } }) => css`
    && {
      margin: 0;
      padding: 0;
      font-family: 'Avenir Next', sans-serif;
      font-weight: 700;
      font-size: 22px;
      line-height: 33px;
      letter-spacing: 0.3px;
      color: ${pageTitleTextColor};
    }
  `}
`;
