import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { AuthService, UserService } from 'services';
import {
  logInRequestAction,
  logInSuccessAction,
  logInFailureAction,
  resetPasswordRequestAction,
  resetPasswordSuccessAction,
  resetPasswordFailureAction,
  logOutAction,
  logOutSuccessAction,
  logOutFailureAction,
  fetchUserSuccessAction,
  updateUserPasswordRequestAction,
  updateUserPasswordSuccessAction,
  updateUserPasswordFailureAction,
  confirmNewPasswordRequestAction,
  confirmNewPasswordSuccessAction,
  confirmNewPasswordFailureAction,
} from 'core/actions';

export function* logInSaga({ payload: { email, password } }: ActionType<typeof logInRequestAction>): SagaIterator {
  try {
    const {
      data: { access, refresh },
    } = yield call(() => AuthService.logIn(email, password));

    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);

    const { data } = yield call(() => UserService.fetchUser());

    yield put(fetchUserSuccessAction(data));

    yield put(logInSuccessAction());
  } catch (error) {
    yield put(logInFailureAction({ authServerError: error.response.data.message }));
  }
}

export function* logOutSaga(): SagaIterator {
  try {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    yield put(logOutSuccessAction());
  } catch (error) {
    yield put(logOutFailureAction());
  }
}

export function* resetPasswordSaga({
  payload: { email },
}: ActionType<typeof resetPasswordRequestAction>): SagaIterator {
  try {
    yield call(() => AuthService.resetPassword(email));

    yield put(resetPasswordSuccessAction());
  } catch (error) {
    yield put(resetPasswordFailureAction({ authServerError: error.response.data.message }));
  }
}

export function* confirmNewPasswordSaga({ payload }: ActionType<typeof confirmNewPasswordRequestAction>) {
  const { uid, token, newPassword, repeatPassword } = payload;

  try {
    yield call(() => AuthService.confirmNewPassword({ uid, token, newPassword, repeatPassword }));

    yield put(confirmNewPasswordSuccessAction());
  } catch (error) {
    yield put(confirmNewPasswordFailureAction({ authServerError: error.response.data.message }));
  }
}

export function* updateUserPasswordSaga({ payload }: ActionType<typeof updateUserPasswordRequestAction>): SagaIterator {
  const { currentPassword, newPassword, repeatPassword } = payload;

  try {
    yield call(() =>
      UserService.post('auth/set-password', {
        old_password: currentPassword,
        new_password: newPassword,
        new_password_confirm: repeatPassword,
      }),
    );

    yield put(updateUserPasswordSuccessAction());
  } catch (error) {
    if (error.response.status === 400) {
      yield put(updateUserPasswordFailureAction({ authServerError: 'Invalid current password' }));
    }
  }
}

export function* authRootSaga(): SagaIterator {
  yield takeEvery(logInRequestAction, logInSaga);
  yield takeEvery(logOutAction, logOutSaga);
  yield takeEvery(resetPasswordRequestAction, resetPasswordSaga);
  yield takeEvery(confirmNewPasswordRequestAction, confirmNewPasswordSaga);
  yield takeEvery(updateUserPasswordRequestAction, updateUserPasswordSaga);
}
