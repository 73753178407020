import { SagaIterator } from 'redux-saga';
import { ActionType } from 'typesafe-actions';
import { takeEvery, call, put, select } from 'redux-saga/effects';

import { getStreamsPagination } from 'core/selectors';
import { instanceStarterService, streamsService } from 'services';
import {
  getStreamsRequestAction,
  rebootInstanceRequestAction,
  rebootInstanceSuccessAction,
  rebootInstanceFailureAction,
  startInstanceRequestAction,
  startInstanceSuccessAction,
  startInstanceFailureAction,
  stopInstanceRequestAction,
  stopInstanceSuccessAction,
  stopInstanceFailureAction,
  deleteInstanceRequestAction,
  deleteInstanceSuccessAction,
  deleteInstanceFailureAction,
} from 'core/actions';

export function* rebootSaga({ payload: { instanceId } }: ActionType<typeof rebootInstanceRequestAction>): SagaIterator {
  try {
    yield call(() => instanceStarterService.rebootInstance(instanceId));

    yield put(rebootInstanceSuccessAction());
  } catch (error) {
    yield put(rebootInstanceFailureAction());
  }
}

export function* startSaga({
  payload: { instanceId, isActive, streamId },
}: ActionType<typeof startInstanceRequestAction>): SagaIterator {
  try {
    const { limit, offset, isActiveFilter, statusFilter, searchStr } = yield select(getStreamsPagination);

    yield call(() => instanceStarterService.startInstance(instanceId));
    yield call(() => streamsService.changeStreamActiveFlag(streamId, isActive));

    yield put(startInstanceSuccessAction());
    yield put(getStreamsRequestAction({ limit, offset, isActiveFilter, statusFilter, searchStr }));
  } catch (error) {
    yield put(startInstanceFailureAction());
  }
}

export function* stopSaga({
  payload: { instanceId, isActive, streamId },
}: ActionType<typeof stopInstanceRequestAction>): SagaIterator {
  try {
    const { limit, offset, isActiveFilter, statusFilter, searchStr } = yield select(getStreamsPagination);

    yield call(() => instanceStarterService.stopInstance(instanceId));
    yield call(() => streamsService.changeStreamActiveFlag(streamId, isActive));

    yield put(stopInstanceSuccessAction());
    yield put(getStreamsRequestAction({ limit, offset, isActiveFilter, statusFilter, searchStr }));
  } catch (error) {
    yield put(stopInstanceFailureAction());
  }
}

export function* deleteSaga({ payload: { instanceId } }: ActionType<typeof deleteInstanceRequestAction>): SagaIterator {
  try {
    yield call(() => instanceStarterService.deleteSeat(instanceId));

    yield put(deleteInstanceSuccessAction());
  } catch (error) {
    yield put(deleteInstanceFailureAction());
  }
}

export function* starterRootSaga(): SagaIterator {
  yield takeEvery(rebootInstanceRequestAction, rebootSaga);
  yield takeEvery(startInstanceRequestAction, startSaga);
  yield takeEvery(stopInstanceRequestAction, stopSaga);
  yield takeEvery(deleteInstanceRequestAction, deleteSaga);
}
