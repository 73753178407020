import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getStreamsPagination } from 'core/selectors';
import { logOutAction, updateStreamsPaginationAction } from 'core/actions';

import { AppMenu } from './AppMenu';

interface IProps {
  collapsed: boolean;
}

export const AppMenuContainer = ({ collapsed }: IProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { limit } = useSelector(getStreamsPagination);

  const logOutHandler = () => {
    dispatch(logOutAction());
  };

  const dashboardMenuItemClickHandler = () => {
    dispatch(updateStreamsPaginationAction({ limit, offset: 1 }));
  };

  return (
    <AppMenu
      collapsed={collapsed}
      location={location.pathname}
      logOut={logOutHandler}
      dashboardMenuItemClickHandler={dashboardMenuItemClickHandler}
    />
  );
};
