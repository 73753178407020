import { createAction } from 'typesafe-actions';

import { ACTIONS } from './constants';

export const toggleReceiverRequestAction = createAction(ACTIONS.TOGGLE_RECEIVER)();
export const getReceiverStatusAction = createAction(ACTIONS.GET_RECEIVER_STATUS)();

export const putReceiverStatusAction = createAction(ACTIONS.PUT_RECEIVER_STATUS_TO_STORE)<{
  receiverStatus: string | undefined;
}>();
