import React from 'react';
import Icon from '@ant-design/icons';

const DublicateSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      d="M15.9956 6H9C7.34315 6 6 7.34315 6 9V15.9956C4.70185 15.9316 4 15.1706 4 13.8182V6.18182C4 4.76751 4.76751 4 6.18182 4H13.8182C15.1706 4 15.9316 4.70185 15.9956 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1818 8H17.8182C19.2325 8 20 8.76751 20 10.1818V17.8182C20 19.2325 19.2325 20 17.8182 20H10.1818C8.76751 20 8 19.2325 8 17.8182V10.1818C8 8.76751 8.76751 8 10.1818 8Z"
      fill="currentColor"
    />
  </svg>
);

export const DublicateIcon = () => <Icon component={DublicateSvg} />;
