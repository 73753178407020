import { ActionType, createReducer } from 'typesafe-actions';

import { putReceiverStatusAction } from 'core/actions';

export interface IReceiverState {
  receiverStatus?: string;
}

const defaultState: IReceiverState = {
  receiverStatus: 'stopped',
};

const actions = {
  putReceiverStatusAction,
};

export const receiverReducer = createReducer<IReceiverState, ActionType<typeof actions>>(defaultState).handleAction(
  putReceiverStatusAction,
  (state, { payload }) => ({
    ...state,
    ...payload,
  }),
);
