import React from 'react';
import Icon from '@ant-design/icons';

const SettingsSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.039 14.3319L19.375 13.371C19.543 12.4648 19.543 11.5351 19.375 10.6288L21.039 9.66788C21.2305 9.55851 21.3164 9.33195 21.2539 9.12101C20.8203 7.73038 20.082 6.47257 19.1172 5.4257C18.9687 5.26554 18.7265 5.22648 18.539 5.33585L16.875 6.29679C16.1758 5.69523 15.3711 5.23038 14.5 4.9257V3.00773C14.5 2.78898 14.3476 2.59757 14.1328 2.5507C12.6992 2.23038 11.2305 2.24601 9.86718 2.5507C9.65233 2.59757 9.49999 2.78898 9.49999 3.00773V4.9296C8.6328 5.2382 7.82812 5.70304 7.12499 6.3007L5.46484 5.33976C5.27343 5.23038 5.03515 5.26554 4.88671 5.4296C3.92187 6.47257 3.18359 7.73038 2.74999 9.12491C2.68359 9.33585 2.77343 9.56241 2.96484 9.67179L4.6289 10.6327C4.46093 11.539 4.46093 12.4687 4.6289 13.3749L2.96484 14.3359C2.77343 14.4452 2.68749 14.6718 2.74999 14.8827C3.18359 16.2734 3.92187 17.5312 4.88671 18.578C5.03515 18.7382 5.27734 18.7773 5.46484 18.6679L7.1289 17.7069C7.82812 18.3085 8.6328 18.7734 9.5039 19.078V20.9999C9.5039 21.2187 9.65624 21.4101 9.87108 21.4569C11.3047 21.7773 12.7734 21.7616 14.1367 21.4569C14.3516 21.4101 14.5039 21.2187 14.5039 20.9999V19.078C15.3711 18.7694 16.1758 18.3046 16.8789 17.7069L18.543 18.6679C18.7344 18.7773 18.9726 18.7421 19.1211 18.578C20.0859 17.5351 20.8242 16.2773 21.2578 14.8827C21.3164 14.6679 21.2305 14.4413 21.039 14.3319ZM12 15.1249C10.2773 15.1249 8.87499 13.7226 8.87499 11.9999C8.87499 10.2773 10.2773 8.87491 12 8.87491C13.7226 8.87491 15.125 10.2773 15.125 11.9999C15.125 13.7226 13.7226 15.1249 12 15.1249Z"
      fill="currentColor"
    />
  </svg>
);

export const SettingsIcon = () => <Icon component={SettingsSvg} />;
