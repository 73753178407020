import { TDefaultCanvas, TInputStream, TLogotype, TMask } from 'types';

export const backgroundRadioOptions = [
  {
    title: 'Chroma key',
    value: '1',
    disabled: false,
  },
  {
    title: 'Without chroma key',
    value: '2',
    disabled: false,
  },
];

export const defaultInputStream: TInputStream = {
  cameraId: '',
  topLeftX: 0,
  topLeftY: 0,
  angle: 0,
  height: 360,
  width: 640,
  cssStyleString: '',
};

export const defaultLogotype: TLogotype = {
  file: null,
  fileUrl: '',
  topLeftX: 0,
  topLeftY: 0,
  angle: 0,
  height: 0,
  width: 0,
  cssStyleString: '',
};

export const defaultMask: TMask = {
  file: null,
  fileUrl: '',
  topLeftX: 0,
  topLeftY: 0,
  angle: 0,
  height: 0,
  width: 0,
  cssStyleString: '',
};

export const defaultCanvas: TDefaultCanvas = {
  canvas: {
    backgroundImage: null,
    backgroundImageUrl: '',
    backgroundVideo: null,
    backgroundVideoUrl: '',
    frontgroundImage: null,
    frontgroundImageUrl: '',
    frontgroundVideo: null,
    frontgroundVideoUrl: '',
    logotypes: [],
    masks: [],
  },
};

export const aspectRatios = new Map([
  ['16:9', [992, 558]],
  ['4:3', [800, 600]],
  ['1:1', [800, 800]],
]);
