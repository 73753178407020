import { BaseService } from './BaseService';
import { checkTokenInterceptor } from './axiosInterceptors';

checkTokenInterceptor();

class UserAPIService extends BaseService {
  public async fetchUser() {
    return this.get('auth/profile');
  }
}

export const UserService = new UserAPIService();
