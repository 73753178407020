import { createAction } from 'typesafe-actions';

import { ACTIONS } from './constants';

export const getInstanceIdRequestAction = createAction(ACTIONS.GET_INSTANCE_ID_REQUEST)();
export const getInstanceIdSuccessAction = createAction(ACTIONS.GET_INSTANCE_ID_SUCCESS)();
export const getInstanceIdFailureAction = createAction(ACTIONS.GET_INSTANCE_ID_FAILURE)();
export const putInstanceIdToStoreAction = createAction(ACTIONS.PUT_INSTANCE_ID_TO_STORE)<string>();

export const deleteInstanceRequestAction = createAction(ACTIONS.DELETE_INSTANCE_REQUEST)<{ instanceId: string }>();
export const deleteInstanceSuccessAction = createAction(ACTIONS.DELETE_INSTANCE_SUCCESS)();
export const deleteInstanceFailureAction = createAction(ACTIONS.DELETE_INSTANCE_FAILURE)();

export const rebootInstanceRequestAction = createAction(ACTIONS.REBOOT_INSTANCE_REQUEST)<{ instanceId: string }>();
export const rebootInstanceSuccessAction = createAction(ACTIONS.REBOOT_INSTANCE_SUCCESS)();
export const rebootInstanceFailureAction = createAction(ACTIONS.REBOOT_INSTANCE_FAILURE)();

export const startInstanceRequestAction = createAction(ACTIONS.START_INSTANCE_REQUEST)<{
  instanceId: string;
  streamId: string;
  isActive: boolean;
}>();
export const startInstanceSuccessAction = createAction(ACTIONS.START_INSTANCE_SUCCESS)();
export const startInstanceFailureAction = createAction(ACTIONS.START_INSTANCE_FAILURE)();

export const stopInstanceRequestAction = createAction(ACTIONS.STOP_INSTANCE_REQUEST)<{
  instanceId: string;
  streamId: string;
  isActive: boolean;
}>();
export const stopInstanceSuccessAction = createAction(ACTIONS.STOP_INSTANCE_SUCCESS)();
export const stopInstanceFailureAction = createAction(ACTIONS.STOP_INSTANCE_FAILURE)();
