import { combineReducers } from 'redux';

import {
  authReducer,
  successReducer,
  userReducer,
  streamReducer,
  streamsReducer,
  cameraReducer,
  receiverReducer,
} from 'core/reducers';

export const rootReducer = combineReducers({
  auth: authReducer,
  success: successReducer,
  user: userReducer,
  stream: streamReducer,
  streams: streamsReducer,
  cameras: cameraReducer,
  receiver: receiverReducer,
});
