import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getStreams, getStreamsPagination, getActiveStreamsNumber, getReceiverData } from 'core/selectors';
import {
  getStreamsRequestAction,
  clearStreamsToStoreAction,
  updateStreamsPaginationAction,
  putIsActiveFilterToStoreAction,
  putStatusFilterToStoreAction,
  getActiveStreamsNumberRequestAction,
  putSearchStrToStoreAction,
  getReceiverStatusAction,
  toggleReceiverRequestAction,
} from 'core/actions';
import { TStreamStatus } from 'types';
import { ReceiverStatus } from 'constants/statuses';

import { Dashboard } from './Dashboard';

export const Container = () => {
  const dispatch = useDispatch();
  const { streamsList } = useSelector(getStreams);
  const { receiverStatus } = useSelector(getReceiverData);
  const { total, limit, offset, isActiveFilter, statusFilter, searchStr } = useSelector(getStreamsPagination);
  const activeStreamsNumber = useSelector(getActiveStreamsNumber);

  const searchInputChangeHandler = (value: string) => {
    // dispatch(getStreamsRequestAction({ limit, offset: 1, searchStr: value, statusFilter, isActiveFilter }));
    dispatch(putSearchStrToStoreAction(value));
  };

  const changeIsActiveFilterhandler = (value: boolean | null) => {
    dispatch(putIsActiveFilterToStoreAction(value));
  };

  const changeStatusFilterhandler = (value: TStreamStatus | null) => {
    dispatch(putStatusFilterToStoreAction(value));
  };

  const pageChangeHandler = (page: number, pageSize?: number) => {
    // TODO: `page` is page offset, but it must be item offset

    dispatch(updateStreamsPaginationAction({ limit: pageSize!, offset: page }));
  };

  useEffect(() => {
    dispatch(getStreamsRequestAction({ limit, offset, isActiveFilter, statusFilter, searchStr }));
  }, [dispatch, limit, offset, isActiveFilter, statusFilter, searchStr]);

  useEffect(() => {
    return () => {
      dispatch(clearStreamsToStoreAction());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getActiveStreamsNumberRequestAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getReceiverStatusAction());
  }, [dispatch]);

  const toggleReceiver = () => {
    // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-alert
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      `Do you really want to ${receiverStatus === ReceiverStatus.Running ? 'STOP' : 'START'} the receiver?`,
    );
    if (confirmed) {
      dispatch(toggleReceiverRequestAction());
    }
  };

  return (
    <Dashboard
      streamsList={streamsList}
      activeStreamsNumber={activeStreamsNumber}
      streamsTotal={total}
      streamsLimit={limit}
      streamsOffset={offset}
      isActiveFilter={isActiveFilter}
      statusFilter={statusFilter}
      searchInputChangeHandler={searchInputChangeHandler}
      changeIsActiveFilterhandler={changeIsActiveFilterhandler}
      changeStatusFilterhandler={changeStatusFilterhandler}
      pageChangeHandler={pageChangeHandler}
      receiverStatus={receiverStatus}
      toggleReceiver={toggleReceiver}
    />
  );
};
