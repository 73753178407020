import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { additionalColors } from 'constants/theme';
import { DotsIcon } from 'assets/icons/DotsIcon';
import { TStreamStatus } from 'types';
import { StreamStatus } from 'constants/statuses';

interface IProps {
  streamId?: string;
  showShareStreamHandler?(): void;
  isShowShareButton?: boolean;
  showDeleteStreamHandler?(): void;
  rebootHandler?(): void;
  onSettingsMenuBlur?: () => void;
  streamStatus?: TStreamStatus;
}

export const StreamSettings = ({
  streamId = '',
  showShareStreamHandler,
  isShowShareButton = true,
  showDeleteStreamHandler,
  // rebootHandler,
  onSettingsMenuBlur,
  streamStatus = StreamStatus.NotReady,
  ...props
}: IProps) => {
  const { push } = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  const onSettingsMenuClick = () => setIsOpened(true);

  const editStreamHandler = () => {
    push(`/edit-stream/${streamId}`);
  };

  const onBlur = () => {
    setIsOpened(false);

    if (onSettingsMenuBlur) {
      onSettingsMenuBlur();
    }
  };

  return (
    <Container tabIndex={0} onBlur={onBlur} {...props}>
      <SettingsMenuContainer onClick={onSettingsMenuClick}>
        <DotsIcon style={{ color: additionalColors.dotsIcon }} />
      </SettingsMenuContainer>
      {isOpened && (
        <SettingsOptionsContainer>
          {streamStatus !== StreamStatus.Launching ? (
            <SettingsOptionWrapper>
              <SettingsOption onClick={editStreamHandler}>Edit stream</SettingsOption>
            </SettingsOptionWrapper>
          ) : null}

          {isShowShareButton ? (
            <SettingsOptionWrapper>
              <SettingsOption onClick={showShareStreamHandler}>Share stream</SettingsOption>
            </SettingsOptionWrapper>
          ) : null}

          {/* {streamStatus === StreamStatus.Ready ? ( */}
          {/*  <SettingsOptionWrapper> */}
          {/*    <SettingsOption onClick={rebootHandler}>Reboot stream</SettingsOption> */}
          {/*  </SettingsOptionWrapper> */}
          {/* ) : null} */}

          {/* <SettingsOptionWrapper> */}
          {/*  <SettingsOption>Create based on</SettingsOption> */}
          {/* </SettingsOptionWrapper> */}
          <SettingsOptionWrapper>
            <DeleteOption onClick={showDeleteStreamHandler}>Delete stream</DeleteOption>
          </SettingsOptionWrapper>
        </SettingsOptionsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: inline-flex;
  outline: none;
`;

const SettingsMenuContainer = styled.div`
  ${({ theme: { settingsDropDownButtonBgColor } }) => css`
    width: fit-content;
    display: flex;
    align-items: center;
    background-color: ${settingsDropDownButtonBgColor};
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    user-select: none;
    outline: none;
  `}
`;

const SettingsOptionsContainer = styled.div`
  ${({ theme: { optionBgColor } }) => css`
    position: absolute;
    z-index: 15;
    top: 48px;
    right: 0;
    width: 184px;
    background-color: ${optionBgColor};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 8px 0;
  `}
`;

const SettingsOptionWrapper = styled.div`
  position: relative;
`;

const SettingsOption = styled.div`
  ${({ theme: { optionTextColor, optionBgColor, optionHoverTextColor, optionActiveBgColor } }) => css`
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${optionTextColor};
    padding: 8px 16px;
    background-color: ${optionBgColor};
    cursor: pointer;
    text-align: left;

    &:hover {
      color: ${optionHoverTextColor};
      background-color: ${optionActiveBgColor};
    }

    &:first-of-type {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  `}
`;

const DeleteOption = styled(SettingsOption)`
  color: #c5292a;
`;
