import { TApiStream, TStreamStatus } from 'types';

import { StreamStatus } from '../constants/statuses';

import { BaseService } from './BaseService';
import { checkTokenInterceptor } from './axiosInterceptors';

checkTokenInterceptor();

class StreamsAPIService extends BaseService {
  public async getSteams(
    limit: number,
    offset: number,
    searchStr: string = '',
    isActiveFilter: boolean | null = null,
    statusFilter: TStreamStatus | null = null,
  ) {
    const limitStr = `limit=${limit}`;
    const offsetStr = offset !== 0 ? `&offset=${offset}` : '';
    const streamName = searchStr ? `&search=${searchStr}` : '';
    const streamStatusFilter = statusFilter ? `&stream_status=${statusFilter}` : '';

    const isActiveFilterValues = new Map([
      [null, ''],
      [true, `&is_active=${isActiveFilter}`],
      [false, `&stream_status=${StreamStatus.Stopped}`],
    ]);

    const streamIsActiveFilter = isActiveFilterValues.get(isActiveFilter);
    // const streamIsActiveFilter = isActiveFilter !== null ? `&is_active=${isActiveFilter}` : '';

    const route = `list?${limitStr}${offsetStr}${streamName}${streamIsActiveFilter}${streamStatusFilter}`;
    return this.get(route);
  }

  public async getSteam(id: string) {
    const route = `details/${id}`;

    return this.get(route);
  }

  public async createSteam(body: TApiStream) {
    return this.post('create', body);
  }

  public async saveSteam(id: string, body: TApiStream) {
    return this.put(`update/${id}`, body);
  }

  public async deleteStream(id: string) {
    return this.remove(`delete/${id}`);
  }

  public async changeStreamActiveFlag(id: string, value: boolean) {
    const route = `update-active-flag/${id}`;
    return this.patch(route, { is_active: value });
  }
}

export const streamsService = new StreamsAPIService();
