import React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  text?: string;
  error?: boolean;
  type?: 'default' | 'login';
  alignment?: 'start' | 'center';
}

export const InputSubText = ({ text = '', error = false, type = 'login', alignment = 'start' }: IProps) => (
  <Container error={error} type={type} alignment={alignment}>
    {text}
  </Container>
);

interface IInputSubText {
  error?: boolean;
  type?: 'default' | 'login';
  alignment?: 'start' | 'center';
}

const Container = styled.p<IInputSubText>`
  ${({
    error,
    type,
    alignment,
    theme: { baseInputErrorColor, loginInputErrorColor, baseInputAdditionalTextColor },
  }) => css`
    font-weight: 500;
    font-size: 12px;
    font-size: 12px;
    line-height: 24px;
    width: 100%;
    color: ${error ? baseInputErrorColor : baseInputAdditionalTextColor};
    height: 24px;
    color: ${error ? (type === 'default' ? baseInputErrorColor : loginInputErrorColor) : baseInputAdditionalTextColor};
    margin: 0;
    padding-left: 12px;
    visibility: ${error ? 'visible' : 'hidden'};
    text-align: ${alignment};
  `}
`;
