import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { EyeIcon } from 'assets';

import { InputSubText } from '../InputSubText';

interface IProps {
  id?: string;
  name?: string;
  label?: string;
  error?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const PasswordInput = ({ id, name, label, value = '', error = '', onChange, ...props }: IProps) => {
  const [openedPassword, setOpenedPassword] = useState(false);

  const onClickIcon = () => setOpenedPassword(!openedPassword);
  return (
    <Container {...props}>
      <Password
        id={id}
        name={name}
        type={openedPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        error={error}
        pattern=".*"
        required
        spellCheck={false}
      />
      <Label error={error} value={value}>
        {label}
      </Label>
      <InputSubText text={error || ''} error type="default" />
      <EyeIconContainer onClick={onClickIcon}>
        <EyeIcon style={{ color: openedPassword ? '#ff7c29' : '#7E8799', width: '13px' }} />
      </EyeIconContainer>
    </Container>
  );
};

interface IInput {
  error?: string;
  value?: string;
}

const Container = styled.div`
  position: relative;
`;

const EyeIconContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const Password = styled.input<IInput>`
  width: 100%;
  padding: 16px;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.baseInputTextColor};
  border: 1px solid ${({ theme, error }) => (error ? theme.baseInputErrorColor : theme.baseInputBorderColor)};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.baseInputHoverColor};
  }

  &:focus {
    color: ${({ theme }) => theme.baseInputTextColor};
    border: 1px solid ${({ error, theme }) => (error ? theme.baseInputErrorColor : theme.baseInputActiveColor)};
    outline: none;
  }

  &:focus + span {
    ${({ error, theme: { baseInputErrorColor, baseInputActiveColor, baseInputLabelBgColor } }) => css`
      color: ${error ? baseInputErrorColor : baseInputActiveColor};
      opacity: 1;
      top: -8px;
      padding: 0 4px;
      background-color: ${baseInputLabelBgColor};
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    `}
  }

  &:valid + span {
    opacity: 1;
    top: -8px;
    padding: 0 4px;
    background-color: ${({ theme }) => theme.baseInputLabelBgColor};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &:focus + span {
    transition: color 0.2s ease;
    transition: opacity 0.2s ease;
    transition: top 0.2s ease;
    transition: padding 0.2s ease;
    transition: background-color 0.2s ease;
    transition: font-weight 0.2s ease;
    transition: font-size 0.2s ease;
    transition: line-height 0.2s ease;
  }

  &::disabled {
    ${({ theme: { baseInputDisabledColor } }) => css`
      border: 1px solid ${baseInputDisabledColor};
      color: ${baseInputDisabledColor};
      background-color: #fff !important;
    `}
  }
`;

const Label = styled.span<IInput>`
  position: absolute;
  z-index: 100;
  top: 16px;
  left: 16px;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme, value }) => (value ? '#000' : theme.baseInputLabelTextColor)};
  opacity: 1;
  pointer-events: none;
  user-select: none;

  ${({ error, value, theme: { baseInputErrorColor, baseInputLabelBgColor } }) =>
    error &&
    value &&
    css`
      color: ${baseInputErrorColor};
      opacity: 1;
      top: -8px;
      padding: 0 4px;
      background-color: ${baseInputLabelBgColor};
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;

      transition: color 0.2s ease;
      transition: opacity 0.2s ease;
      transition: top 0.2s ease;
      transition: padding 0.2s ease;
      transition: background-color 0.2s ease;
      transition: font-weight 0.2s ease;
      transition: font-size 0.2s ease;
      transition: line-height 0.2s ease;
    `};
`;
