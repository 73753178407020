import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';

import { convertCameraToAppSchema } from 'services/apiMappers';
import { cameraService } from 'services';
import {
  getCameraListRequestAction,
  getCameraListSuccessAction,
  getCameraListFailureAction,
  getCameraListToStoreAction,
} from 'core/actions';

export function* getCameraListSaga(): SagaIterator {
  try {
    const { data } = yield call(() => cameraService.getCameraList());
    const cameraList = convertCameraToAppSchema(data);

    yield put(getCameraListSuccessAction());
    yield put(getCameraListToStoreAction(cameraList));
  } catch (error) {
    yield put(getCameraListFailureAction());
  }
}

export function* inputsStreamRootSaga(): SagaIterator {
  yield takeEvery(getCameraListRequestAction, getCameraListSaga);
}
