import React from 'react';
import { Row, Col } from 'antd';

import { PageLayout } from 'organisms';
import { StreamItem, Pagination } from 'molecules';
import { TStreamsListItem, TStreamStatus } from 'types';

import { DashboardHeader, IProps as IDashboardHeaderProps } from './DashboardHeader';

export interface IProps extends IDashboardHeaderProps {
  streamsList: TStreamsListItem[];
  activeStreamsNumber?: number;
  streamsTotal?: number;
  streamsLimit?: number;
  streamsOffset?: number;
  isActiveFilter?: boolean | null;
  statusFilter?: TStreamStatus | null;
  changeIsActiveFilterhandler?(value: boolean | null): void;
  changeStatusFilterhandler?(value: TStreamStatus | null): void;
  pageChangeHandler?(page: number, pageSize?: number): void;
  receiverStatus?: string;
  toggleReceiver: () => void;
}

export const Dashboard = ({
  searchInputChangeHandler,
  activeStreamsNumber,
  streamsList,
  streamsTotal,
  streamsLimit,
  streamsOffset,
  isActiveFilter,
  statusFilter,
  changeIsActiveFilterhandler,
  changeStatusFilterhandler,
  pageChangeHandler,
  receiverStatus,
  toggleReceiver,
}: IProps) => (
  <PageLayout>
    <DashboardHeader
      streamsAmount={activeStreamsNumber}
      isActiveFilter={isActiveFilter}
      statusFilter={statusFilter}
      searchInputChangeHandler={searchInputChangeHandler}
      changeIsActiveFilterhandler={changeIsActiveFilterhandler}
      changeStatusFilterhandler={changeStatusFilterhandler}
      receiverStatus={receiverStatus}
      toggleReceiver={toggleReceiver}
    />

    <Row gutter={[32, 32]}>
      {streamsList.map(({ id, streamName, outputIp, streamStatus, instanceId, isActive }) => (
        <Col key={id} xs={24} md={12} lg={8} xl={6}>
          <StreamItem
            id={id}
            name={streamName}
            outputIp={outputIp}
            streamStatus={streamStatus}
            instanceId={instanceId}
            isActive={isActive}
          />
        </Col>
      ))}
    </Row>

    {streamsTotal ? (
      <Pagination total={streamsTotal} pageSize={streamsLimit} current={streamsOffset} onChange={pageChangeHandler} />
    ) : null}
  </PageLayout>
);
