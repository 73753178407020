import React, { ReactNode } from 'react';
import { Layout, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';

import { AmberIcon } from 'assets';

const { Header } = Layout;

interface IProps {
  children: ReactNode;
  isMenuButtonVisible?: boolean;
}

export const AppHeader = ({ children, isMenuButtonVisible = false }: IProps) => (
  <HeaderContainer>
    {isMenuButtonVisible ? (
      <MenuButton type="text" icon={<MenuOutlined />} />
    ) : (
      <AmberIcon style={{ marginRight: '32px' }} />
    )}
    {children}
  </HeaderContainer>
);

const HeaderContainer = styled(Header)`
  ${({ theme: { appHeaderBgColor, appHeaderTextColor } }) => css`
    & {
      font-family: 'Avenir Next Cyr';
      background-color: ${appHeaderBgColor};
      color: ${appHeaderTextColor};
      font-weight: 500;
      font-size: 20px;
      height: 56px;
      line-height: 56px;
      padding: 0 31px;
      display: flex;
      align-items: center;
    }
  `}
`;

const MenuButton = styled(Button)`
  ${({ theme: { appHeaderTextColor } }) => css`
    & {
      color: ${appHeaderTextColor};
      margin-right: 23px;
      vertical-align: baseline;

      &:hover,
      &:focus {
        color: ${appHeaderTextColor};
      }
    }
  `}
`;
