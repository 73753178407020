import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { LoginInput, Button } from 'atoms';
import { LoginFormLayout } from 'molecules';

import { validationSchema as validate } from './validationSchema';

interface IValues {
  email: string;
  password: string;
}

interface IProps {
  serverValidationError?: string;
  onSubmitForm: (values: IValues) => void;
  onChangeDecorator?: () => void;
}

const isThereError = (errors: any, touched: any, inputName: 'email' | 'password'): string => {
  return errors[inputName] && touched[inputName] ? errors[inputName] : '';
};

export const LoginForm = ({ serverValidationError = '', onSubmitForm, onChangeDecorator }: IProps) => {
  return (
    <LoginFormLayout>
      <SubHeading>Welcome Back</SubHeading>
      <Heading>Log in to your account</Heading>

      <Formik initialValues={{ email: '', password: '' }} validate={validate} onSubmit={onSubmitForm}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
          return (
            <LoginFormContainer autoComplete="off">
              <InputGroup>
                <LoginEmailInput
                  label="Email"
                  id="email"
                  name="email"
                  type="email"
                  value={values.email}
                  placeholder="Email"
                  onChange={(e) => {
                    onChangeDecorator && onChangeDecorator();
                    handleChange('email')(e);
                  }}
                  onBlur={handleBlur('email')}
                  error={isThereError(errors, touched, 'email')}
                />

                <LoginPasswordInput
                  label="Password"
                  id="password"
                  name="password"
                  type="password"
                  value={values.password}
                  placeholder="Password"
                  onChange={(e) => {
                    onChangeDecorator && onChangeDecorator();
                    handleChange('password')(e);
                  }}
                  onBlur={handleBlur('password')}
                  error={isThereError(errors, touched, 'password')}
                  serverError={serverValidationError}
                />
              </InputGroup>

              <SubmitButton onClick={() => handleSubmit()}>Submit</SubmitButton>
            </LoginFormContainer>
          );
        }}
      </Formik>
    </LoginFormLayout>
  );
};

const LoginFormContainer = styled.form``;

const Heading = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.loginHeadingTextColor};
  margin-bottom: 48px;
  margin-top: 0;
  padding: 0;
`;

const SubHeading = styled.h2`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.loginSubHeadingTextColor};
  margin-bottom: 4px;
  margin-top: 0;
  padding: 0;
`;

const LoginEmailInput = styled(LoginInput)`
  margin-bottom: 4px;
`;

const LoginPasswordInput = styled(LoginInput)`
  margin-bottom: 4px;
`;

const InputGroup = styled.div`
  margin-bottom: 16px;
`;

const SubmitButton = styled(Button)`
  width: 432px;
`;
