import React from 'react';
import { Row, Col } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useDispatch } from 'react-redux';

import { RadioGroup } from 'atoms';
import { FormSection } from 'molecules';
import { backgroundRadioOptions } from 'mock';
import { putStreamFormValueToStoreAction } from 'core';

interface IProps {
  value?: boolean;
}

export const BackgroundSettingsBlock = ({ value = false }: IProps) => {
  const dispatch = useDispatch();
  const currentValue = value ? '1' : '2';

  const onChange = (e: RadioChangeEvent) => {
    const values = new Map([
      ['1', true],
      ['2', false],
    ]);

    const newValue = values.get(e.target.value) || false;

    dispatch(
      putStreamFormValueToStoreAction({
        name: 'isChromakey',
        value: newValue,
      }),
    );
  };

  return (
    <FormSection title="Background">
      <Row gutter={[32, 32]}>
        <Col xs={24} md={24} xl={16}>
          <RadioGroup radioItems={backgroundRadioOptions} onChange={onChange} value={currentValue} />
        </Col>
      </Row>
    </FormSection>
  );
};
