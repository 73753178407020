export const getImageSize = (img: File) => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const _URL = window.URL || window.webkitURL;

    const image = new Image();

    image.onload = () => {
      const result = { width: image.width, height: image.height };
      resolve(result);
    };

    image.src = _URL.createObjectURL(img);
  });
};
