import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';

import { TextInput } from 'atoms';
import { FormSection } from 'molecules';
import { putStreamFormValueToStoreAction } from 'core';

interface IProps {
  value?: string;
  error?: string;
  onBlurInputHandler?: (e: any) => void;
  name: string;
  label: string;
  title: string;
}

export const RTMPServerBlock = ({ value = '', error, onBlurInputHandler, name, label, title }: IProps) => {
  const dispatch = useDispatch();

  const valueChangeHandler = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    dispatch(
      putStreamFormValueToStoreAction({
        name: target.name,
        value: target.value,
      }),
    );
  };

  return (
    <FormSection title={title}>
      <Row gutter={[32, 0]}>
        <Col xs={24} md={12} xl={8}>
          <KeyInput
            id={name}
            name={name}
            label={label}
            value={value}
            error={error}
            onChange={valueChangeHandler}
            onBlur={onBlurInputHandler}
          />
        </Col>
      </Row>
    </FormSection>
  );
};

const KeyInput = styled(TextInput)`
  margin-bottom: 8px;
`;
