import { TApiStream, TApiStreamsListItem, TStream, TStreamsListItem } from 'types';

export const convertStreamsToAppSchema = (streamsList: TApiStreamsListItem[]): TStreamsListItem[] => {
  return streamsList.map((item) => ({
    id: String(item.id),
    isActive: item.is_active,
    streamStatus: item.stream_status,
    streamName: item.stream_name,
    aspectRatio: item.aspect_ratio,
    inputStreams: item.input_streams.map((inputItem) => ({
      cameraId: String(inputItem.camera_id),
      topLeftX: inputItem.top_left_x,
      topLeftY: inputItem.top_left_y,
      angle: inputItem.angle,
      height: inputItem.height,
      width: inputItem.width,
      cssStyleString: inputItem.css_style_string,
    })),
    isChromakey: item.is_chromakey,
    outputIp: item.output_ip,
    instanceId: item.instance_id,
  }));
};

export const convertStreamToAppSchema = (stream: TApiStream): TStream => {
  return {
    isActive: stream.is_active,
    streamStatus: stream.stream_status,
    streamName: stream.stream_name,
    aspectRatio: stream.aspect_ratio,
    inputStreams: stream.input_streams.map((inputItem) => ({
      cameraId: String(inputItem.camera_id),
      topLeftX: inputItem.top_left_x,
      topLeftY: inputItem.top_left_y,
      angle: inputItem.angle,
      height: inputItem.height,
      width: inputItem.width,
      cssStyleString: inputItem.css_style_string,
    })),
    isChromakey: stream.is_chromakey,
    outputIp: stream.output_ip,
    canvas: {
      backgroundImage: null,
      backgroundImageUrl: stream.canvas.background_image_url,
      backgroundVideo: null,
      backgroundVideoUrl: stream.canvas.background_video_url,
      frontgroundImage: null,
      frontgroundImageUrl: stream.canvas.frontground_image_url,
      frontgroundVideo: null,
      frontgroundVideoUrl: stream.canvas.frontground_video_url,
      logotypes: stream.canvas.logotypes.map((logotypeItem) => ({
        file: null,
        fileUrl: logotypeItem.file_url,
        topLeftX: logotypeItem.top_left_x,
        topLeftY: logotypeItem.top_left_y,
        angle: logotypeItem.angle,
        height: logotypeItem.height,
        width: logotypeItem.width,
        cssStyleString: logotypeItem.css_style_string,
      })),
      masks: stream.canvas.masks.map((masksItem) => ({
        file: null,
        fileUrl: masksItem.file_url,
        topLeftX: masksItem.top_left_x,
        topLeftY: masksItem.top_left_y,
        angle: masksItem.angle,
        height: masksItem.height,
        width: masksItem.width,
        cssStyleString: masksItem.css_style_string,
      })),
    },
    isAudioEnabled: stream.is_audio,
    audioUrl: stream.audio_url,
    instanceId: stream.instance_id,
    ingest_ip: stream.ingest_ip,
    stream_key: stream.stream_key,
  };
};

export const convertStreamToApiSchema = (stream: TStream): TApiStream => {
  return {
    is_active: stream.isActive,
    stream_status: stream.streamStatus,
    stream_name: stream.streamName,
    aspect_ratio: stream.aspectRatio,
    input_streams: stream.inputStreams.map((inputItem) => ({
      camera_id: Number(inputItem.cameraId),
      top_left_x: inputItem.topLeftX,
      top_left_y: inputItem.topLeftY,
      angle: inputItem.angle,
      height: inputItem.height,
      width: inputItem.width,
      css_style_string: inputItem.cssStyleString,
    })),
    is_chromakey: stream.isChromakey,
    output_ip: stream.outputIp,
    canvas: {
      background_image_url: stream.canvas.backgroundImageUrl,
      background_video_url: stream.canvas.backgroundVideoUrl,
      frontground_image_url: stream.canvas.frontgroundImageUrl,
      frontground_video_url: stream.canvas.frontgroundVideoUrl,
      logotypes: stream.canvas.logotypes.map((logotypeItem) => ({
        file_url: logotypeItem.fileUrl,
        top_left_x: logotypeItem.topLeftX,
        top_left_y: logotypeItem.topLeftY,
        angle: logotypeItem.angle,
        height: logotypeItem.height,
        width: logotypeItem.width,
        css_style_string: logotypeItem.cssStyleString,
      })),
      masks: stream.canvas.masks.map((masksItem) => ({
        file_url: masksItem.fileUrl,
        top_left_x: masksItem.topLeftX,
        top_left_y: masksItem.topLeftY,
        angle: masksItem.angle,
        height: masksItem.height,
        width: masksItem.width,
        css_style_string: masksItem.cssStyleString,
      })),
    },
    is_audio: stream.isAudioEnabled,
    audio_url: stream.audioUrl,
    instance_id: stream.instanceId,
    ingest_ip: stream.ingest_ip,
    stream_key: stream.stream_key,
  };
};
