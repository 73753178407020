import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PageLayout, UserSettingsForm } from 'organisms';
import { PageTitle } from 'atoms';
import { updateUserPasswordRequestAction } from 'core';
import { useHandleServerError, useOnRequestSuccess } from 'hooks';
import { getUsername } from 'core/selectors';

interface IValues {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export const Settings = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { authServerError, resetServerError } = useHandleServerError();

  const username = useSelector(getUsername);

  useOnRequestSuccess(updateUserPasswordRequestAction, () => {
    push('/password-updated');
  });

  const onSubmit = ({ currentPassword, newPassword, repeatPassword }: IValues) => {
    dispatch(updateUserPasswordRequestAction({ currentPassword, newPassword, repeatPassword }));
  };

  const onChangeDecorator = () => {
    resetServerError();
  };

  return (
    <PageLayout>
      <PageTitle>User settings</PageTitle>
      <UserSettingsForm
        onSubmit={onSubmit}
        username={username}
        onChangeDecorator={onChangeDecorator}
        authServerError={authServerError}
      />
    </PageLayout>
  );
};
