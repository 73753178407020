import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { LoginFormLayout } from 'molecules';
import { LoginInput, Button } from 'atoms';

import { validationSchema as validate } from './validationSchema';

interface IValues {
  newPassword: string;
  repeatPassword: string;
}

interface IProps {
  onSubmit: (values: IValues) => void;
}

export const NewPasswordForm = ({ onSubmit }: IProps) => {
  const isThereError = (errors: any, touched: any, inputName: 'newPassword' | 'repeatPassword'): string => {
    return errors[inputName] && touched[inputName] ? errors[inputName] : '';
  };

  return (
    <LoginFormLayout>
      <Heading>Set new password</Heading>

      <Formik initialValues={{ newPassword: '', repeatPassword: '' }} validate={validate} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
          return (
            <LoginFormContainer autoComplete="off">
              <InputGroup>
                <LoginPasswordInput
                  label="New password"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  value={values.newPassword}
                  placeholder="New password"
                  onChange={handleChange('newPassword')}
                  onBlur={handleBlur('newPassword')}
                  error={isThereError(errors, touched, 'newPassword')}
                  isForgotPassLinkVisible={false}
                />

                <LoginPasswordInput
                  label="Repeat password"
                  id="repeatPassword"
                  name="repeatPassword"
                  type="password"
                  value={values.repeatPassword}
                  placeholder="Repeat password"
                  onChange={handleChange('repeatPassword')}
                  onBlur={handleBlur('repeatPassword')}
                  error={isThereError(errors, touched, 'repeatPassword')}
                  isForgotPassLinkVisible={false}
                />
              </InputGroup>

              <SubmitButton onClick={() => handleSubmit()}>Submit</SubmitButton>
            </LoginFormContainer>
          );
        }}
      </Formik>
    </LoginFormLayout>
  );
};

const LoginFormContainer = styled.form``;

const Heading = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.loginHeadingTextColor};
  margin-bottom: 48px;
  margin-top: 0;
  padding: 0;
`;

const LoginPasswordInput = styled(LoginInput)`
  margin-bottom: 4px;
`;

const InputGroup = styled.div`
  margin-bottom: 16px;
`;

const SubmitButton = styled(Button)`
  width: 432px;
`;
