import React from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const AmberSvg = () => (
  <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8982 4.04308L11.6402 6.72518L15.5199 11.3408H7.77306L6.30103 13.0873H5.11593L13.2994 3.33203L11.029 0.625L0.625 13.0125L11.029 25.375L21.4205 13.0125L13.8982 4.04308Z"
      fill="#FFA46B"
    />
  </svg>
);

export const AmberIcon = ({ style }: IconComponentProps) => <Icon component={AmberSvg} style={style} />;
