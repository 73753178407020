import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button } from 'atoms';
import { LoginFormLayout } from 'molecules';
import { MailIcon } from 'assets';

interface IProps {
  heading: string;
  linkTo: string;
}

export const SuccessModal = ({ heading, linkTo }: IProps) => {
  return (
    <LoginFormLayout>
      <Heading>{heading}</Heading>

      <MailIcon style={{ marginTop: '48px', marginBottom: '48px' }} />
      <Link to={linkTo}>
        <SubmitButton>Ok</SubmitButton>
      </Link>
    </LoginFormLayout>
  );
};

const Heading = styled.h2`
  font-family: 'Avenir Next', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.loginHeadingTextColor};
  margin-top: 28px;
  margin-bottom: 0;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  width: 432px;
`;
