import React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  text?: string;
}

export const UserAvatar = ({ text }: IProps) => (
  <UserAvatarContainer>
    <TextContainer>{text}</TextContainer>
  </UserAvatarContainer>
);

const UserAvatarContainer = styled.div`
  ${({ theme: { userAvatarBgColor } }) => css`
    & {
      background-color: ${userAvatarBgColor};
      height: 32px;
      width: 32px;
      border-radius: 50%;
      text-align: center;
      line-height: 29px;
      display: inline-block;
    }
  `}
`;

const TextContainer = styled.span`
  ${({ theme: { userAvatarTextColor } }) => css`
    & {
      font-family: 'Avenir Next Cyr';
      font-weight: 600;
      font-size: 10px;
      color: ${userAvatarTextColor};
    }
  `}
`;
