import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import styled, { css } from 'styled-components';

import { AppHeader, AppMenu } from 'molecules';

const { Content } = Layout;

interface IProps {
  children: ReactNode;
  isHeaderVisible?: boolean;
  isMenuVisible?: boolean;
  isAuthPage?: boolean;
}

export const PageLayout = ({ children, isHeaderVisible = true, isMenuVisible = true, isAuthPage = false }: IProps) => {
  return (
    <LayoutContainer isAuthPage={isAuthPage}>
      {isHeaderVisible && (
        <AppHeader isMenuButtonVisible={isMenuVisible}>Amber Studios video stream master v1.0</AppHeader>
      )}
      <Layout style={{ backgroundColor: 'transparent' }}>
        {isMenuVisible && <AppMenu collapsed />}
        <ContentContainer>{children}</ContentContainer>
      </Layout>
    </LayoutContainer>
  );
};

interface ILayout {
  isAuthPage?: boolean;
}

const LayoutContainer = styled(Layout)<ILayout>`
  ${({ theme: { pageLayoutBgColor, loginPageBgColor }, isAuthPage }) => css`
    & {
      font-family: 'Avenir Next Cyr';
      background-color: ${isAuthPage ? loginPageBgColor : pageLayoutBgColor};
      height: 100vh;
    }
  `}
`;

const ContentContainer = styled(Content)`
   {
    padding: 72px;
  }
`;
