import { ActionType, createReducer } from 'typesafe-actions';

import {
  logInSuccessAction,
  logOutAction,
  logInFailureAction,
  resetServerAuthErrorAction,
  resetPasswordFailureAction,
  putUserAuthStatusAction,
  startAuthLoadingAction,
  endAuthLoadingAction,
  updateUserPasswordFailureAction,
} from 'core/actions';

export interface IAuthState {
  isLoggedIn: boolean;
  isAuthLoading: boolean;
  authServerError: string;
}

const defaultState: IAuthState = {
  isLoggedIn: false,
  isAuthLoading: true,
  authServerError: '',
};

const actions = {
  logInSuccessAction,
  logOutAction,
  logInFailureAction,
  resetPasswordFailureAction,
  updateUserPasswordFailureAction,
  putUserAuthStatusAction,
  resetServerAuthErrorAction,
  startAuthLoadingAction,
  endAuthLoadingAction,
};

export const authReducer = createReducer<IAuthState, ActionType<typeof actions>>(defaultState)
  .handleAction(logInSuccessAction, (state) => ({
    ...state,
    isLoggedIn: true,
  }))

  .handleAction(logOutAction, (state) => ({
    ...state,
    isLoggedIn: false,
  }))

  .handleAction(logInFailureAction, (state, { payload: { authServerError } }) => ({
    ...state,
    authServerError,
  }))

  .handleAction(resetPasswordFailureAction, (state, { payload: { authServerError } }) => ({
    ...state,
    authServerError,
  }))

  .handleAction(
    updateUserPasswordFailureAction,
    (state, { payload: { authServerError } }: ActionType<typeof updateUserPasswordFailureAction>) => ({
      ...state,
      authServerError,
    }),
  )

  .handleAction(putUserAuthStatusAction, (state) => ({
    ...state,
    isLoggedIn: true,
    isAuthLoading: false,
  }))

  .handleAction(resetServerAuthErrorAction, (state) => ({
    ...state,
    authServerError: '',
  }))

  .handleAction(startAuthLoadingAction, (state) => ({
    ...state,
    isAuthLoading: true,
  }))

  .handleAction(endAuthLoadingAction, (state) => ({
    ...state,
    isAuthLoading: false,
  }));
