import { createAction } from 'typesafe-actions';

import { TStreamsListItem, TStream, TInputStream, TSettingsStreamOutput, TDefaultCanvas, TStreamStatus } from 'types';

import { ACTIONS } from './constants';

// STREAMS LIST --------------------------------------------------------------------
export const getStreamsRequestAction = createAction(ACTIONS.GET_STREAMS_REQUEST)<{
  limit: number;
  offset: number;
  searchStr?: string;
  isActiveFilter?: boolean | null;
  statusFilter?: TStreamStatus | null;
}>();
export const getStreamsSuccessAction = createAction(ACTIONS.GET_STREAMS_SUCCESS)();
export const getStreamsFailureAction = createAction(ACTIONS.GET_STREAMS_FAILURE)();
export const putStreamsToStoreAction = createAction(ACTIONS.PUT_STREAMS_TO_STORE)<{
  streamsList: TStreamsListItem[];
  total: number;
  offset?: number;
}>();
export const clearStreamsToStoreAction = createAction(ACTIONS.CLEAR_STREAMS_TO_STORE)();
export const updateStreamsPaginationAction = createAction(ACTIONS.UPDATE_STREAMS_PAGINATION)<{
  limit: number;
  offset: number;
}>();

export const getActiveStreamsNumberRequestAction = createAction(ACTIONS.GET_ACTIVE_STREAMS_NUMBER_REQUEST)();
export const getActiveStreamsNumberSuccessAction = createAction(ACTIONS.GET_ACTIVE_STREAMS_NUMBER_SUCCESS)();
export const getActiveStreamsNumberFailureAction = createAction(ACTIONS.GET_ACTIVE_STREAMS_NUMBER_FAILURE)();
export const putActiveStreamsNumberToStoreAction = createAction(ACTIONS.PUT_ACTIVE_STREAMS_NUMBER_TO_STORE)<number>();

// STREAMS FILTERS --------------------------------------------------------------------

export const putIsActiveFilterToStoreAction = createAction(ACTIONS.PUT_IS_ACTIVE_FILTER_TO_STORE)<boolean | null>();
export const putStatusFilterToStoreAction = createAction(ACTIONS.PUT_STATUS_FILTER_TO_STORE)<TStreamStatus | null>();
export const putSearchStrToStoreAction = createAction(ACTIONS.PUT_SEARCH_STR_TO_STORE)<string>();

// SINGLE STREAM --------------------------------------------------------------------
export const getStreamRequestAction = createAction(ACTIONS.GET_STREAM_REQUEST)<{
  streamId: string;
}>();
export const getStreamSuccessAction = createAction(ACTIONS.GET_STREAM_SUCCESS)();
export const getStreamFailureAction = createAction(ACTIONS.GET_STREAM_FAILURE)();
export const putStreamToStoreAction = createAction(ACTIONS.PUT_STREAM_TO_STORE)<{
  stream: TStream;
}>();
export const clearStreamToStoreAction = createAction(ACTIONS.CLEAR_STREAM_TO_STORE)();
export const putDefaultInputStreamToStoreAction = createAction(ACTIONS.PUT_DEFAULT_INPUT_STREAM_TO_STORE)<
  TInputStream
>();
export const putSettingsStreamToStoreAction = createAction(ACTIONS.PUT_SETTINGS_STREAM_TO_STORE)<
  TSettingsStreamOutput
>();

// CANVAS --------------------------------------------------------------------
export const putDefaultCanvasToStoreAction = createAction(ACTIONS.PUT_DEFAULT_CANVAS_TO_STORE)<TDefaultCanvas>();
export const putStreamFormValueToStoreAction = createAction(ACTIONS.PUT_STREAM_FORM_VALUE_TO_STORE)<{
  name: string;
  value: string | boolean;
}>();
export const putInputStreamCameraIdToStoreAction = createAction(ACTIONS.PUT_INPUT_STREAM_CAMERA_ID_TO_STORE)<{
  index: number;
  cameraId: string;
}>();

export const updateStreamRequestAction = createAction(ACTIONS.UPDATE_STREAM_REQUEST)<{
  streamId: string;
}>();
export const updateStreamSuccessAction = createAction(ACTIONS.UPDATE_STREAM_SUCCESS)();
export const updateStreamFailureAction = createAction(ACTIONS.UPDATE_STREAM_FAILURE)();

export const updateStreamWithInstanceIdRequestAction = createAction(ACTIONS.UPDATE_STREAM_WITH_INSTANCE_ID_REQUEST)<{
  streamId: string;
}>();
export const updateStreamWithInstanceIdSuccessAction = createAction(ACTIONS.UPDATE_STREAM_WITH_INSTANCE_ID_SUCCESS)();
export const updateStreamWithInstanceIdFailureAction = createAction(ACTIONS.UPDATE_STREAM_WITH_INSTANCE_ID_FAILURE)();

export const createStreamRequestAction = createAction(ACTIONS.CREATE_STREAM_REQUEST)();
export const createStreamSuccessAction = createAction(ACTIONS.CREATE_STREAM_SUCCESS)();
export const createStreamFailureAction = createAction(ACTIONS.CREATE_STREAM_FAILURE)();

export const createStreamWithInstanceIdRequestAction = createAction(ACTIONS.CREATE_STREAM_WITH_INSTANCE_ID_REQUEST)();
export const createStreamWithInstanceIdSuccessAction = createAction(ACTIONS.CREATE_STREAM_WITH_INSTANCE_ID_SUCCESS)();
export const createStreamWithInstanceIdFailureAction = createAction(ACTIONS.CREATE_STREAM_WITH_INSTANCE_ID_FAILURE)();

export const deleteStreamRequestAction = createAction(ACTIONS.DELETE_STREAM_REQUEST)<{
  streamId: string;
}>();
export const deleteStreamSuccessAction = createAction(ACTIONS.DELETE_STREAM_SUCCESS)();
export const deleteStreamFailureAction = createAction(ACTIONS.DELETE_STREAM_FAILURE)();

// Movable blocks actions
export const putInputStreamCoordinatesToStoreAction = createAction(ACTIONS.PUT_INPUT_STREAM_COORDINATES_TO_STORE)<{
  blockIndex: number;
  topLeftX: number;
  topLeftY: number;
  angle: number;
  height: number;
  width: number;
  cssStyleString: string;
}>();

export const putLogotypeCoordinatesToStoreAction = createAction(ACTIONS.PUT_LOGOTYPE_COORDINATES_TO_STORE)<{
  blockIndex: number;
  topLeftX: number;
  topLeftY: number;
  angle: number;
  height: number;
  width: number;
  cssStyleString: string;
}>();
export const putMaskCoordinatesToStoreAction = createAction(ACTIONS.PUT_MASK_COORDINATES_TO_STORE)<{
  blockIndex: number;
  topLeftX: number;
  topLeftY: number;
  angle: number;
  height: number;
  width: number;
  cssStyleString: string;
}>();
