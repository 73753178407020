interface IValues {
  streamName: string | undefined;
  aspectRatio: string;
  audioUrl?: string;
  cameraId1: string;
  cameraId2: string;
  isChromakey?: boolean;
  isAudioEnabled?: boolean;
  streamKey?: string;
  ingestIp?: string;
}

interface IErrors {
  streamName?: string;
  audioUrl?: string;
  cameraId1?: string;
  cameraId2?: string;
  streamKey?: string;
  ingestIp?: string;
}

export const validationSchema = (values: IValues) => {
  const errors: IErrors = {};

  if (!values.streamName) {
    errors.streamName = 'Stream name is required';
  }

  if (values.streamName && values.streamName.length > 40) {
    errors.streamName = 'Stream name should be less than 40 symbols';
  }

  if (!values.cameraId1) {
    errors.cameraId1 = 'Stream address is required';
  }

  if (!values.streamKey) {
    errors.streamKey = 'Stream KEY is required';
  }

  if (!values.ingestIp) {
    errors.ingestIp = 'Server RTMP is required';
  }

  // if (!/^(http|https):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i.test(String(values.audioUrl))) {
  //   errors.audioUrl = 'Invalid url';
  // }

  return errors;
};
