import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';

import { store } from 'core';
import { Router } from 'components/pages';
import { orangeGrayTheme } from 'constants/theme';
import 'antd/dist/antd.css';
import 'assets/fonts/avenir/stylesheet.css';

export const App = () => {
  return (
    <ThemeProvider theme={orangeGrayTheme}>
      <Provider store={store}>
        <Router />
      </Provider>
    </ThemeProvider>
  );
};
