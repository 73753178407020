import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'atoms';

interface IProps {
  saveAndLaunchHandler?(): void;
  saveHandler?(): void;
  isVisibleSaveButton?: boolean;
}

export const Footer = ({ saveAndLaunchHandler, saveHandler, isVisibleSaveButton }: IProps) => {
  const [getSaveLoading, setSaveLoading] = useState(false);
  const [getSaveAndLaunchLoading, setSaveAndLaunchLoading] = useState(false);

  const [getSaveDisabled, setSaveDisabled] = useState(false);
  const [getSaveAndLaunchDisabled, setSaveAndLaunchDisabled] = useState(false);

  const save = () => {
    setSaveLoading(!getSaveLoading);
    setSaveAndLaunchDisabled(!getSaveAndLaunchDisabled);

    if (saveHandler) {
      saveHandler();
    }
  };

  const saveAndLaunch = () => {
    setSaveAndLaunchLoading(!getSaveAndLaunchLoading);
    setSaveDisabled(!getSaveDisabled);

    if (saveAndLaunchHandler) {
      saveAndLaunchHandler();
    }
  };

  return (
    <Container>
      <Button loading={getSaveAndLaunchLoading} disabled={getSaveAndLaunchDisabled} onClick={saveAndLaunch}>
        Save & Launсh
      </Button>

      {isVisibleSaveButton ? (
        <Button loading={getSaveLoading} type="default" disabled={getSaveDisabled} onClick={save}>
          Save
        </Button>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  ${({ theme: { uploadBoxBgColor } }) => css`
     {
      background-color: ${uploadBoxBgColor};
      padding: 48px 32px;
    }

    .ant-btn-primary {
      margin-right: 16px;
    }
  `}
`;
