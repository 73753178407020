import React from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const MailSvg = () => (
  <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 13.3334H120C123.682 13.3334 126.667 16.3181 126.667 20V80C126.667 83.6819 123.682 86.6667 120 86.6667H40C36.3181 86.6667 33.3333 83.6819 33.3333 80V20C33.3333 16.3181 36.3181 13.3334 40 13.3334ZM50 33.3334C48.159 33.3334 46.6667 34.8258 46.6667 36.6667C46.6667 38.5077 48.159 40 50 40H90C91.841 40 93.3333 38.5077 93.3333 36.6667C93.3333 34.8258 91.841 33.3334 90 33.3334H50ZM50 46.6667C48.159 46.6667 46.6667 48.1591 46.6667 50C46.6667 51.841 48.159 53.3334 50 53.3334H70C71.841 53.3334 73.3333 51.841 73.3333 50C73.3333 48.1591 71.841 46.6667 70 46.6667H50Z"
      fill="#7E8799"
    />
    <path
      d="M78.2435 85.7654L80 87.034L81.7565 85.7654L136.471 46.249L134.715 43.817L136.472 46.249C136.621 46.1412 136.829 46.1748 136.937 46.3241C136.978 46.3809 137 46.4492 137 46.5192V113.333C137 119.04 132.374 123.667 126.667 123.667H33.3333C27.6264 123.667 23 119.04 23 113.333V46.5192C23 46.3352 23.1492 46.1859 23.3333 46.1859C23.4034 46.1859 23.4717 46.208 23.5285 46.249L78.2435 85.7654Z"
      fill="#3E4049"
      stroke="#7E8799"
      strokeWidth="6"
    />
  </svg>
);

export const MailIcon = ({ style }: IconComponentProps) => <Icon component={MailSvg} style={style} />;
