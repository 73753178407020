import { createAction } from 'typesafe-actions';

import { TCamera } from 'types';

import { ACTIONS } from './constants';

export const getCameraListRequestAction = createAction(ACTIONS.GET_CAMERA_LIST_REQUEST)();
export const getCameraListSuccessAction = createAction(ACTIONS.GET_CAMERA_LIST_SUCCESS)();
export const getCameraListFailureAction = createAction(ACTIONS.GET_CAMERA_LIST_FAILURE)();
export const getCameraListToStoreAction = createAction(ACTIONS.PUT_CAMERA_LIST_TO_STORE)<TCamera[]>();
