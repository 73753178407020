export enum ACTIONS {
  INIT_APP = 'INIT_APP',
  INIT_APP_SUCCESS = 'INIT_APP_SUCCESS',
  INIT_APP_FAILURE = 'INIT_APP_FAILURE',
  LOG_IN_REQUEST = 'LOG_IN_REQUEST',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  LOG_OUT = 'LOG_OUT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',
  RESET_SERVER_AUTH_ERROR = 'RESET_SERVER_AUTH_ERROR',
  PUT_USER_AUTH_STATUS = 'PUT_USER_AUTH_STATUS',
  START_AUTH_LOADING = 'START_AUTH_LOADING',
  END_AUTH_LOADING = 'END_AUTH_LOADING',
  CONFIRM_NEW_PASSWORD_REQUEST = 'CONFIRM_NEW_PASSWORD_REQUEST',
  CONFIRM_NEW_PASSWORD_SUCCESS = 'CONFIRM_NEW_PASSWORD_SUCCESS',
  CONFIRM_NEW_PASSWORD_FAILURE = 'CONFIRM_NEW_PASSWORD_FAILURE',
  FETCH_USER_REQUEST = 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE = 'FETCH_USER_FAILURE',
  UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST',
  UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS',
  UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE',
  CLEAR_SUCCESS_BY_ACTION_TYPE = 'CLEAR_SUCCESS_BY_ACTION_TYPE',

  // streams list
  GET_STREAMS_REQUEST = 'GET_STREAMS_REQUEST',
  GET_STREAMS_SUCCESS = 'GET_STREAMS_SUCCESS',
  GET_STREAMS_FAILURE = 'GET_STREAMS_FAILURE',
  PUT_STREAMS_TO_STORE = 'PUT_STREAMS_TO_STORE',
  CLEAR_STREAMS_TO_STORE = 'CLEAR_STREAMS_TO_STORE',
  UPDATE_STREAMS_PAGINATION = 'UPDATE_STREAMS_PAGINATION',
  PUT_IS_ACTIVE_FILTER_TO_STORE = 'PUT_IS_ACTIVE_FILTER_TO_STORE',
  PUT_STATUS_FILTER_TO_STORE = 'PUT_STATUS_FILTER_TO_STORE',
  PUT_SEARCH_STR_TO_STORE = 'PUT_SEARCH_STR_TO_STORE',
  GET_ACTIVE_STREAMS_NUMBER_REQUEST = 'GET_ACTIVE_STREAMS_NUMBER_REQUEST',
  GET_ACTIVE_STREAMS_NUMBER_SUCCESS = 'GET_ACTIVE_STREAMS_NUMBER_SUCCESS',
  GET_ACTIVE_STREAMS_NUMBER_FAILURE = 'GET_ACTIVE_STREAMS_NUMBER_FAILURE',
  PUT_ACTIVE_STREAMS_NUMBER_TO_STORE = 'PUT_ACTIVE_STREAMS_NUMBER_TO_STORE',

  // single stream
  GET_STREAM_REQUEST = 'GET_STREAM_REQUEST',
  GET_STREAM_SUCCESS = 'GET_STREAM_SUCCESS',
  GET_STREAM_FAILURE = 'GET_STREAM_FAILURE',
  PUT_STREAM_TO_STORE = 'PUT_STREAM_TO_STORE',
  CLEAR_STREAM_TO_STORE = 'CLEAR_STREAM_TO_STORE',
  PUT_DEFAULT_INPUT_STREAM_TO_STORE = 'PUT_DEFAULT_INPUT_STREAM_TO_STORE',
  PUT_SETTINGS_STREAM_TO_STORE = 'PUT_SETTINGS_STREAM_TO_STORE',
  PUT_DEFAULT_CANVAS_TO_STORE = 'PUT_DEFAULT_CANVAS_TO_STORE',

  PUT_STREAM_FORM_VALUE_TO_STORE = 'PUT_STREAM_FORM_VALUE_TO_STORE',
  PUT_INPUT_STREAM_CAMERA_ID_TO_STORE = 'PUT_INPUT_STREAM_CAMERA_ID_TO_STORE',

  UPDATE_STREAM_REQUEST = 'UPDATE_STREAM_REQUEST',
  UPDATE_STREAM_SUCCESS = 'UPDATE_STREAM_SUCCESS',
  UPDATE_STREAM_FAILURE = 'UPDATE_STREAM_FAILURE',

  UPDATE_STREAM_WITH_INSTANCE_ID_REQUEST = 'UPDATE_STREAM_WITH_INSTANCE_ID_REQUEST',
  UPDATE_STREAM_WITH_INSTANCE_ID_SUCCESS = 'UPDATE_STREAM_WITH_INSTANCE_ID_SUCCESS',
  UPDATE_STREAM_WITH_INSTANCE_ID_FAILURE = 'UPDATE_STREAM_WITH_INSTANCE_ID_FAILURE',

  CREATE_STREAM_REQUEST = 'CREATE_STREAM_REQUEST',
  CREATE_STREAM_SUCCESS = 'CREATE_STREAM_SUCCESS',
  CREATE_STREAM_FAILURE = 'CREATE_STREAM_FAILURE',

  CREATE_STREAM_WITH_INSTANCE_ID_REQUEST = 'CREATE_STREAM_WITH_INSTANCE_ID_REQUEST',
  CREATE_STREAM_WITH_INSTANCE_ID_SUCCESS = 'CREATE_STREAM_WITH_INSTANCE_ID_SUCCESS',
  CREATE_STREAM_WITH_INSTANCE_ID_FAILURE = 'CREATE_STREAM_WITH_INSTANCE_ID_FAILURE',

  DELETE_STREAM_REQUEST = 'DELETES_STREAM_REQUEST',
  DELETE_STREAM_SUCCESS = 'DELETE_STREAM_SUCCESS',
  DELETE_STREAM_FAILURE = 'DELETE_STREAM_FAILURE',

  GET_INSTANCE_ID_REQUEST = 'GET_INSTANCE_ID_REQUEST',
  GET_INSTANCE_ID_SUCCESS = 'GET_INSTANCE_ID_SUCCESS',
  GET_INSTANCE_ID_FAILURE = 'GET_INSTANCE_ID_FAILURE',
  PUT_INSTANCE_ID_TO_STORE = 'PUT_INSTANCE_ID_TO_STORE',

  // canvasSettingStream
  PUT_BACKGROUND_IMAGE_TO_STORE = 'PUT_BACKGROUND_IMAGE_TO_STORE',
  PUT_BACKGROUND_VIDEO_TO_STORE = 'PUT_BACKGROUND_VIDEO_TO_STORE',
  REMOVE_BACKGROUND_TO_STORE = 'REMOVE_BACKGROUND_TO_STORE',
  PUT_FRONT_IMAGE_TO_STORE = 'PUT_FRONT_IMAGE_TO_STORE',
  PUT_FRONT_VIDEO_TO_STORE = 'PUT_FRONT_VIDEO_TO_STORE',
  REMOVE_FRONT_TO_STORE = 'REMOVE_FRONT_TO_STORE',
  PUT_LOGOTYPE_IMAGE_TO_STORE = 'PUT_LOGOTYPE_IMAGE_TO_STORE',
  REMOVE_LOGOTYPE_IMAGE_TO_STORE = 'REMOVE_LOGOTYPE_IMAGE_TO_STORE',
  ADD_LOGOTYPE_ITEM_TO_STORE = 'ADD_LOGOTYPE_ITEM_TO_STORE',
  REMOVE_LOGOTYPE_ITEM_TO_STORE = 'REMOVE_LOGOTYPE_ITEM_TO_STORE',
  PUT_INPUTMASKS_COLLACTION_TO_STORE = 'PUT_INPUTMASKS_COLLACTION_TO_STORE',
  PUT_INPUTMASK_IMAGE_TO_STORE = 'PUT_INPUTMASK_IMAGE_TO_STORE',
  PUT_INPUT_STREAM_COORDINATES_TO_STORE = 'PUT_INPUT_STREAM_COORDINATES_TO_STORE',
  PUT_LOGOTYPE_COORDINATES_TO_STORE = 'PUT_LOGOTYPE_COORDINATES_TO_STORE',
  PUT_MASK_COORDINATES_TO_STORE = 'PUT_MASK_COORDINATES_TO_STORE',

  // Camera
  GET_CAMERA_LIST_REQUEST = 'GET_CAMERA_LIST_REQUEST',
  GET_CAMERA_LIST_SUCCESS = 'GET_CAMERA_LIST_SUCCESS',
  GET_CAMERA_LIST_FAILURE = 'GET_CAMERA_LIST_FAILURE',
  PUT_CAMERA_LIST_TO_STORE = 'PUT_CAMERA_LIST_TO_STORE',

  // Starter
  DELETE_INSTANCE_REQUEST = 'DELETE_INSTANCE_REQUEST',
  DELETE_INSTANCE_SUCCESS = 'DELETE_INSTANCE_SUCCESS',
  DELETE_INSTANCE_FAILURE = 'DELETE_INSTANCE_FAILURE',

  REBOOT_INSTANCE_REQUEST = 'REBOOT_INSTANCE_REQUEST',
  REBOOT_INSTANCE_SUCCESS = 'REBOOT_INSTANCE_SUCCESS',
  REBOOT_INSTANCE_FAILURE = 'REBOOT_INSTANCE_FAILURE',

  START_INSTANCE_REQUEST = 'START_INSTANCE_REQUEST',
  START_INSTANCE_SUCCESS = 'START_INSTANCE_SUCCESS',
  START_INSTANCE_FAILURE = 'START_INSTANCE_FAILURE',

  STOP_INSTANCE_REQUEST = 'STOP_INSTANCE_REQUEST',
  STOP_INSTANCE_SUCCESS = 'STOP_INSTANCE_SUCCESS',
  STOP_INSTANCE_FAILURE = 'STOP_INSTANCE_FAILURE',

  // Receiver
  TOGGLE_RECEIVER = 'TOGGLE_RECEIVER',
  GET_RECEIVER_STATUS = 'GET_RECEIVER_STATUS',
  PUT_RECEIVER_STATUS_TO_STORE = 'PUT_RECEIVER_STATUS_TO_STORE',
}
