import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import { clone } from 'ramda';

import {
  putStreamToStoreAction,
  clearStreamToStoreAction,
  putBackgroundToStoreAction,
  putBackgroundVideoToStoreAction,
  removeBackgroundToStoreAction,
  putFrontImageToStoreAction,
  putFrontVideoToStoreAction,
  removeFrontImageToStoreAction,
  putLogotypeImageToStoreAction,
  addLogotypeItemToStoreAction,
  removeLogotypeItemToStoreAction,
  removeLogotypeImageToStoreAction,
  putInputMaskImageToStoreAction,
  putDefaultInputStreamToStoreAction,
  putSettingsStreamToStoreAction,
  putInputStreamCoordinatesToStoreAction,
  putLogotypeCoordinatesToStoreAction,
  putMaskCoordinatesToStoreAction,
  putDefaultCanvasToStoreAction,
  putStreamFormValueToStoreAction,
  putInputStreamCameraIdToStoreAction,
  putInstanceIdToStoreAction,
} from 'core/actions';
import { TStream } from 'types';

const defaultState: TStream = {
  isActive: false,
  streamStatus: 'Not ready',
  streamName: '',
  aspectRatio: '16:9',
  inputStreams: [
    {
      cameraId: '',
      topLeftX: 0,
      topLeftY: 0,
      angle: 0,
      height: 360,
      width: 640,
      cssStyleString: '',
    },
  ],
  isChromakey: true,
  outputIp: '',
  canvas: {
    backgroundImage: null,
    backgroundImageUrl: '',
    backgroundVideo: null,
    backgroundVideoUrl: '',
    frontgroundImage: null,
    frontgroundImageUrl: '',
    frontgroundVideo: null,
    frontgroundVideoUrl: '',
    logotypes: [],
    masks: [],
  },
  isAudioEnabled: false,
  audioUrl: '',
  instanceId: '',
  stream_key: '',
  ingest_ip: '',
};

const actions = {
  putStreamToStoreAction,
  clearStreamToStoreAction,
  putBackgroundToStoreAction,
  putBackgroundVideoToStoreAction,
  removeBackgroundToStoreAction,
  putFrontImageToStoreAction,
  putFrontVideoToStoreAction,
  removeFrontImageToStoreAction,
  putLogotypeImageToStoreAction,
  addLogotypeItemToStoreAction,
  removeLogotypeItemToStoreAction,
  removeLogotypeImageToStoreAction,
  putInputMaskImageToStoreAction,
  putDefaultInputStreamToStoreAction,
  putDefaultCanvasToStoreAction,
  putSettingsStreamToStoreAction,
  putInputStreamCoordinatesToStoreAction,
  putLogotypeCoordinatesToStoreAction,
  putMaskCoordinatesToStoreAction,
  putStreamFormValueToStoreAction,
  putInstanceIdToStoreAction,
  putInputStreamCameraIdToStoreAction,
};

export const streamReducer = createReducer<TStream, ActionType<typeof actions>>(defaultState)
  .handleAction(putStreamToStoreAction, (state, { payload: { stream } }) => ({
    ...state,
    ...stream,
  }))
  .handleAction(clearStreamToStoreAction, () => {
    return {
      ...defaultState,
    };
  })
  .handleAction(putBackgroundToStoreAction, (state, { payload: backgroundFile }) => ({
    ...state,
    canvas: {
      ...state.canvas,
      backgroundImage: backgroundFile,
    },
  }))
  .handleAction(putBackgroundVideoToStoreAction, (state, { payload: backgroundFile }) => ({
    ...state,
    canvas: {
      ...state.canvas,
      backgroundVideo: backgroundFile,
    },
  }))
  .handleAction(removeBackgroundToStoreAction, (state) => ({
    ...state,
    canvas: {
      ...state.canvas,
      backgroundImage: null,
      backgroundImageUrl: '',
      backgroundVideo: null,
      backgroundVideoUrl: '',
    },
  }))
  .handleAction(putFrontImageToStoreAction, (state, { payload: frontgroundFile }) => ({
    ...state,
    canvas: {
      ...state.canvas,
      frontgroundImage: frontgroundFile,
    },
  }))
  .handleAction(putFrontVideoToStoreAction, (state, { payload: frontgroundFile }) => ({
    ...state,
    canvas: {
      ...state.canvas,
      frontgroundVideo: frontgroundFile,
    },
  }))
  .handleAction(removeFrontImageToStoreAction, (state) => ({
    ...state,
    canvas: {
      ...state.canvas,
      frontgroundImage: null,
      frontgroundImageUrl: '',
      frontgroundVideo: null,
      frontgroundVideoUrl: '',
    },
  }))
  .handleAction(putLogotypeImageToStoreAction, (state, { payload: image }) => {
    const logotypes = state.canvas.logotypes.map((item) => ({
      ...item,
      file: image,
    }));

    return {
      ...state,
      canvas: {
        ...state.canvas,
        logotypes,
      },
    };
  })
  .handleAction(addLogotypeItemToStoreAction, (state, { payload: item }) =>
    update(state, { canvas: { logotypes: { $push: [item] } } }),
  )
  .handleAction(removeLogotypeItemToStoreAction, (state, { payload: index }) => {
    return update(state, { canvas: { logotypes: { $splice: [[index, 1]] } } });
  })
  .handleAction(putInputMaskImageToStoreAction, (state, { payload: { image, index } }) => {
    return update(state, { canvas: { masks: { [index]: { file: { $set: image } } } } });
  })
  .handleAction(putDefaultInputStreamToStoreAction, (state, { payload: defaultInputStream }) =>
    update(state, { inputStreams: { $push: [defaultInputStream] } }),
  )
  .handleAction(putDefaultCanvasToStoreAction, (state, { payload: defaultCanvas }) => {
    const newState = { ...state };
    const newInputsStreams = newState.inputStreams.map((item) => {
      item.cssStyleString = '';
      return item;
    });

    newState.canvas = { ...defaultCanvas.canvas };
    newState.inputStreams = [...newInputsStreams];

    return newState;
  })
  .handleAction(putStreamFormValueToStoreAction, (state, { payload: { name, value } }) => ({ ...state, [name]: value }))
  .handleAction(putInputStreamCameraIdToStoreAction, (state, { payload: { index, cameraId } }) => {
    const newState = clone(state);

    newState.inputStreams[index].cameraId = cameraId;

    return {
      ...newState,
    };
  })
  .handleAction(putSettingsStreamToStoreAction, (state, { payload: formData }) => ({
    ...state,
    inputStreams: formData.inputStreams,
    canvas: {
      ...state.canvas,
      masks: formData.masks,
    },
  }))
  .handleAction(
    putInputStreamCoordinatesToStoreAction,
    (state, { payload: { blockIndex, topLeftX, topLeftY, angle, height, width, cssStyleString } }) => {
      const newState = { ...state };

      newState.inputStreams[blockIndex] = {
        ...newState.inputStreams[blockIndex],
        topLeftX,
        topLeftY,
        angle,
        height,
        width,
        cssStyleString,
      };

      return newState;
    },
  )
  .handleAction(
    putLogotypeCoordinatesToStoreAction,
    (state, { payload: { blockIndex, topLeftX, topLeftY, angle, height, width, cssStyleString } }) => {
      const newState = { ...state };

      newState.canvas.logotypes[blockIndex] = {
        ...newState.canvas.logotypes[blockIndex],
        topLeftX,
        topLeftY,
        angle,
        height,
        width,
        cssStyleString,
      };

      return newState;
    },
  )
  .handleAction(
    putMaskCoordinatesToStoreAction,
    (state, { payload: { blockIndex, topLeftX, topLeftY, angle, height, width, cssStyleString } }) => {
      const newState = { ...state };

      newState.canvas.masks[blockIndex] = {
        ...newState.canvas.masks[blockIndex],
        topLeftX,
        topLeftY,
        angle,
        height,
        width,
        cssStyleString,
      };

      return newState;
    },
  )
  .handleAction(putInstanceIdToStoreAction, (state, { payload: instanceId }) => {
    return update(state, { instanceId: { $set: instanceId } });
  });
