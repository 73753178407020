import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { LoginForm, PageLayout } from 'components/organisms';
import { logInRequestAction } from 'core/actions';
import { useHandleServerError } from 'hooks';

export const Login = () => {
  const { authServerError, resetServerError } = useHandleServerError();

  const dispatch = useDispatch();
  const onSubmitForm = ({ email, password }: { email: string; password: string }) => {
    dispatch(logInRequestAction({ email, password }));
  };

  const onChangeDecorator = () => {
    resetServerError();
  };

  return (
    <PageLayout isHeaderVisible isMenuVisible={false} isAuthPage>
      <LoginFormContainer>
        <LoginForm
          onSubmitForm={onSubmitForm}
          onChangeDecorator={onChangeDecorator}
          serverValidationError={authServerError}
        />
      </LoginFormContainer>
    </PageLayout>
  );
};

const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.loginPageBgColor};
`;
