import React from 'react';
import { Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { Button, Select } from 'atoms';
import { FormSection } from 'molecules';
import { TCamera, TInputStream } from 'types';
import { putInputStreamCameraIdToStoreAction } from 'core';

interface IProps {
  inputValues: string[];
  inputStreamList: Array<TInputStream>;
  camerasList?: Array<TCamera>;
  errors: string[];
  addInputStreamHandler?(): void;
}

export const InputStreamSettingsBlock = ({
  inputStreamList = [],
  camerasList = [],
  addInputStreamHandler,
  inputValues,
  errors,
}: IProps) => {
  const dispatch = useDispatch();

  const inputStreamSelectOptions = camerasList.map((item) => ({
    id: String(item.id),
    value: String(item.id),
    caption: item.name,
  }));

  const changeHandler = (index: number, value: string) => {
    dispatch(
      putInputStreamCameraIdToStoreAction({
        index,
        cameraId: value,
      }),
    );
  };

  return (
    <FormSection title="Input Stream Settings">
      {inputStreamList.map((_, index) => (
        // Temp. There will be uniq "id" property in feature
        // eslint-disable-next-line react/no-array-index-key
        <InputStreamItem key={index}>
          <Row gutter={[32, 0]}>
            <Col xs={24} md={12} xl={8}>
              <Select
                label={`Input Stream #${index + 1} address`}
                onChange={(__, value) => changeHandler(index, value)}
                optionItems={inputStreamSelectOptions}
                inputName={`inputStream${index + 1}`}
                value={inputValues[index]}
                isShowDefaultOption
                error={errors[index]}
              />
            </Col>
            {inputStreamList?.length <= 1 ? (
              <Col xs={24} md={12} xl={8}>
                <Button type="default" icon={<PlusOutlined />} onClick={addInputStreamHandler}>
                  Add Input Stream #2
                </Button>
              </Col>
            ) : null}
          </Row>
        </InputStreamItem>
      ))}
    </FormSection>
  );
};

const InputStreamItem = styled.div`
  &:last-child {
    margin: 0;
  }
`;
