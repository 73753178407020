import { createGlobalStyle } from 'styled-components';

export interface ITheme {
  theme: {
    [key: string]: string;
  };
}

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  } 
`;
