import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
}

export const LoginFormLayout = ({ children }: IProps) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  background: #3e4049;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.24), 0px 25px 35px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 40px 32px 84px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: 488px;
`;
