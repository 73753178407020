import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';

import { TCamera } from 'types';
import { getCameraListToStoreAction } from 'core/actions';

interface ICameraListState {
  camerasList: TCamera[];
}

const defaultState: ICameraListState = {
  camerasList: [],
};

const actions = {
  getCameraListToStoreAction,
};

export const cameraReducer = createReducer<ICameraListState, ActionType<typeof actions>>(
  defaultState,
).handleAction(getCameraListToStoreAction, (state, { payload: camerasList }) =>
  update(state, { camerasList: { $set: [...camerasList] } }),
);
