import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

import { Button } from 'atoms';

interface IProps {
  title?: string;
  isModalVisible?: boolean;
  handleOk?(): void;
  handleCancel?(): void;
  children?: ReactNode;
}

export const ConfirmationModal = ({
  title = 'Are you sure?',
  isModalVisible = false,
  handleOk,
  handleCancel,
  children = '',
  ...props
}: IProps) => (
  <ConfirmationModalContainer
    title={title}
    visible={isModalVisible}
    onOk={handleOk}
    onCancel={handleCancel}
    footer={[]}
    {...props}
    centered
  >
    <Content>{children}</Content>
    <p>
      <AnswerButton onClick={handleOk}>Yes</AnswerButton>
      <AnswerButton onClick={handleCancel} type="default">
        No
      </AnswerButton>
    </p>
  </ConfirmationModalContainer>
);

const ConfirmationModalContainer = styled(Modal)`
  & .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      border: none;
      border-radius: 4px 4px 0 0;
    }

    .ant-modal-footer {
      border: none;
      padding: 0;
    }
  }
`;

const Content = styled.div`
  margin: 0 0 30px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

const AnswerButton = styled(Button)`
  margin: 0 16px 0 0;
  padding: 4px 33px;
`;
